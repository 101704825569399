import { Text, Image, View } from "@react-pdf/renderer";
import { imageSrc } from "../constant";
import { checkIsChineseChar } from "helper/helper";

interface TitleProps {
  image?: string;
  name?: string | undefined;
  styles?: any;
}

const Title = ({ image, name, styles }: TitleProps) => (
  <View
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 1.5 * 8,
      paddingVertical: 0.75 * 8,
      backgroundColor: "#F9F6F1",
      borderRadius: 6,
      gap: 8,
      ...styles,
    }}
  >
    <Text
      style={{
        fontFamily: checkIsChineseChar(name) ? "KaiTi" : "FuturaMediumBT",
        lineHeight: 1,
        fontSize: 20,
        paddingVertical: 0.75 * 8,
      }}
    >
      {name}
    </Text>
    {image && (
      <Image
        style={{
          width: 32,
          height: 32,
          marginRight: 12,
          objectFit: "contain",
        }}
        src={imageSrc(image)}
      />
    )}
  </View>
);

export default Title;
