import { Box, Typography } from "@mui/material";

import Background from "images/end-of-project-report/last-page-bg.png";
import Image from "components/Image/Image";

import { PDFInfoType, editableStyle, styles } from "./constant";

const LastPage = ({
  scale,
  PDFInfo,
  setPDFInfo,
}: {
  scale: number;
  PDFInfo: PDFInfoType;
  setPDFInfo: React.Dispatch<React.SetStateAction<PDFInfoType>>;
}) => {
  const handleChange = (key: string, value: string | null) =>
    setPDFInfo((prev) => ({ ...prev, [key]: value ? value : "" }));

  const strip = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <Box sx={styles.body}>
      <Image src={Background} alt="" />

      <Typography
        sx={{
          position: "absolute",
          top: `${96 * scale}px`,
          left: `${400 * scale}px`,
          right: `${400 * scale}px`,
          fontSize: 40 * scale,
          fontWeight: 500,
          lineHeight: 1.25,
          textAlign: "center",
        }}
      >
        {PDFInfo.congratsTitle}
      </Typography>

      <Typography
        sx={{
          ...editableStyle,
          position: "absolute",
          top: `${154 * scale}px`,
          left: `${400 * scale}px`,
          right: `${400 * scale}px`,
          textAlign: "center",
          fontSize: 24 * scale,
        }}
        contentEditable
        onBlur={(e) =>
          handleChange("congratsText", e.currentTarget.textContent)
        }
        suppressContentEditableWarning={true}
      >
        {strip(PDFInfo.congratsText)}
      </Typography>

      <Box
        sx={{
          ...styles.main,
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "flex-start",
          flex: 1,
          mt: `${280 * scale}px`,
          mb: `${50 * scale}px`,
          mx: `${50 * scale}px`,
          overflow: "hidden",
        }}
      >
        {PDFInfo.congratsImages.map((image, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: `${220 * scale}px`,
              borderRadius: `${8 * scale}px`,
              m: `${10 * scale}px`,
              backgroundColor: "#fff",
              overflow: "hidden",
            }}
          >
            <Image
              style={{
                width: "100%",
                height: `${100 * scale}px`,
                objectFit: "cover",
                backgroundColor: "#fbfbfb",
              }}
              src={image}
              alt=""
            />

            <Typography
              sx={{
                py: `${8 * scale}px`,
                fontSize: `${18 * scale}px`,
                lineHeight: 1,
                textAlign: "center",
              }}
            >
              Lesson {index + 1}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LastPage;
