import { useEffect, useState } from "react";
import { Page, Image, View } from "@react-pdf/renderer";
import { pageSize, styles } from "./constant";
import { checkIsChineseChar } from "helper/helper";
import { PDFInfoType } from "../Preview/constant";
import Background from "images/children-portfolio-v2/concluding-remark.png";
import ConcludingRemarksPageEnglish from "./ConcludingRemarksPageEnglish";
import ConcludingRemarksPageChinese from "./ConcludingRemarksPageChinese";
const ConcludingRemarkPage = ({ PDFInfo }: { PDFInfo: PDFInfoType }) => {
  // chinese full page limit: length 395,  6 lines
  // english full page limit: length 1900, 7 lines
  // chinese half (all in 1 page) limit: length 245, 4 lines
  // english half (all in 1 page) limit: length ,

  const [multipleLanguages, setMultipleLanguages] = useState<boolean>(false);
  const englishParagraphs = [];
  const chineseParagraphs = [];

  const filteredParagraphs = PDFInfo.concludingRemarkText
    .split(/\r\n|\n/)
    .filter((paragraph) => paragraph.trim() !== "");

  for (let i = 0; i < filteredParagraphs.length; i++) {
    if (checkIsChineseChar(filteredParagraphs[i])) {
      chineseParagraphs.push(filteredParagraphs[i]);
    } else {
      englishParagraphs.push(filteredParagraphs[i]);
    }
  }

  useEffect(() => {
    if (englishParagraphs.length > 0 && chineseParagraphs.length > 0) {
      setMultipleLanguages(true);
    }
  }, [englishParagraphs, chineseParagraphs]);
  // have both languages, see if need 1 page each
  if (englishParagraphs.length > 0 && chineseParagraphs.length > 0) {
    // one page only
    if (
      englishParagraphs.reduce(
        (totalLength: any, str: any) => totalLength + str.length,
        0
      ) <= 1100 &&
      englishParagraphs.length <= 3 &&
      chineseParagraphs.reduce(
        (totalLength: any, str: any) => totalLength + str.length,
        0
      ) <= 310 &&
      chineseParagraphs.length <= 3
    ) {
      return (
        <Page size={pageSize} style={styles.body}>
          <View style={styles.main} wrap={false}>
            <Image src={Background} style={styles.background} />

            {/* {englishParagraphs.length > 0 &&
              !checkIsChineseChar(filteredParagraphs[0]) && (
                <ConcludingRemarksPageEnglish
                  multipleLanguages={multipleLanguages}
                  paragraphs={englishParagraphs}
                  englishFirst={checkIsChineseChar(filteredParagraphs[0])}
                />
              )} */}
            {chineseParagraphs.length > 0 && (
              <ConcludingRemarksPageChinese
                multipleLanguages={multipleLanguages}
                paragraphs={chineseParagraphs}
                englishFirst={checkIsChineseChar(filteredParagraphs[0])}
              />
            )}
            {englishParagraphs.length > 0 &&
              checkIsChineseChar(filteredParagraphs[0]) && (
                <ConcludingRemarksPageEnglish
                  multipleLanguages={multipleLanguages}
                  paragraphs={englishParagraphs}
                  englishFirst={checkIsChineseChar(filteredParagraphs[0])}
                />
              )}
          </View>
        </Page>
      );
    } else {
      // both languages, both need multiple pages
      return (
        <>
          {englishParagraphs.length > 0 &&
            !checkIsChineseChar(filteredParagraphs[0]) && (
              <ConcludingRemarksPageEnglish
                multipleLanguages={multipleLanguages}
                paragraphs={englishParagraphs}
                englishFirst={checkIsChineseChar(filteredParagraphs[0])}
              />
            )}
          {chineseParagraphs.length > 0 && (
            <ConcludingRemarksPageChinese
              multipleLanguages={multipleLanguages}
              paragraphs={chineseParagraphs}
              englishFirst={checkIsChineseChar(filteredParagraphs[0])}
            />
          )}
          {englishParagraphs.length > 0 &&
            checkIsChineseChar(filteredParagraphs[0]) && (
              <ConcludingRemarksPageEnglish
                multipleLanguages={multipleLanguages}
                paragraphs={englishParagraphs}
                englishFirst={checkIsChineseChar(filteredParagraphs[0])}
              />
            )}
        </>
      );
    }
  }
  // SINGLE LANGUAGE BLOCKS

  // only english
  else if (englishParagraphs.length > 0 && chineseParagraphs.length == 0) {
    // check english length, if too long, split into m
    return (
      <>
        {englishParagraphs.length > 0 && (
          <ConcludingRemarksPageEnglish
            multipleLanguages={multipleLanguages}
            paragraphs={englishParagraphs}
            englishFirst={checkIsChineseChar(filteredParagraphs[0])}
          />
        )}
      </>
    );
  }
  // only chinese
  else {
    return (
      <>
        {chineseParagraphs.length > 0 && (
          <ConcludingRemarksPageChinese
            multipleLanguages={multipleLanguages}
            paragraphs={chineseParagraphs}
            englishFirst={checkIsChineseChar(filteredParagraphs[0])}
          />
        )}
      </>
    );
  }
};

export default ConcludingRemarkPage;
