import React, { useState, useRef, useEffect } from "react";
import { Typography, Box, Button, TextField, IconButton } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Document, Page, pdfjs } from "react-pdf";
import SvgIcon from "@mui/material/SvgIcon";
import FlipPage from "react-flip-page";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface FlipBookPDFComponentProps {
  file: File | string | null;
  dimensions: { width: number; height: number };
  scale: number;
}

const BUTTON_WIDTH = 0; // Width of the button
const MARGIN = 12; // Margin on either side of the flip book

const FlipBookPDFComponent: React.FC<FlipBookPDFComponentProps> = ({ file, dimensions, scale }) => {
  const [pageSizes, setPageSizes] = useState<Array<{ width: number; height: number }>>([]);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const flipPageRef = useRef<any>(null);
  const [inputPage, setInputPage] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [pageScale, setPageScale] = useState<number>(scale);

  // This hook updates the input field whenever the current page changes
  useEffect(() => {
    setInputPage(currentPage.toString());
  }, [currentPage]);
  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPageSizes(new Array(numPages).fill({ width: dimensions.width, height: dimensions.height }));
    setNumPages(numPages);
    setCurrentPage(1); // Reset to first page when a new document is loaded
  };

  const onLoadSuccessV2 = ({ numPages }: { numPages: number }) => {
    setPageSizes(new Array(numPages).fill({ width: 600, height: undefined }));
    setNumPages(numPages);
    setCurrentPage(1);
  };

  const onRenderSuccess = (index: number) => (page: any) => {
    const scaledHeight = (100 / page.originalWidth) * page.originalHeight;
    setPageSizes((prev) => {
      const newSizes = [...prev];
      newSizes[index] = { width: 150, height: scaledHeight };
      return newSizes;
    });
  };

  const onRenderSuccessFixed = (index: number) => (page: any) => {
    // Get viewport at a scale based on the width you want (600px in this case)
    const scale = 600 / page.getViewport({ scale: 1 }).width;
    const viewport = page.getViewport({ scale });

    setPageSizes((prev) => {
      const updatedSizes = [...prev];
      updatedSizes[index] = { width: 600, height: viewport.height }; // Use the actual rendered height
      return updatedSizes;
    });
  };

  const handlePrevPage = () => {
    const newPage = Math.max(1, currentPage - 1);
    setCurrentPage(newPage);
    flipPageRef.current.gotoPreviousPage();
  };

  const handleNextPage = () => {
    const newPage = Math.min(numPages, currentPage + 1);
    setCurrentPage(newPage);
    flipPageRef.current.gotoNextPage();
  };

  const onPageChange = (pageIndex: number) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleInputPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageNumber = parseInt(event.target.value, 10);
    if (!isNaN(newPageNumber) && newPageNumber >= 1 && newPageNumber <= numPages) {
      setCurrentPage(newPageNumber);
      flipPageRef.current.gotoPage(newPageNumber - 1);
    }
    setInputPage(event.target.value);
  };

  const navigateToPage = (page: number) => {
    if (flipPageRef.current) {
      flipPageRef.current.gotoPage(page - 1);
    }
    setCurrentPage(page); // Update current page state
  };

  const totalWidth = dimensions.width + BUTTON_WIDTH * 2 + MARGIN * 2;
  console.log(dimensions.width, dimensions.height);
  console.log(pageSizes);

  const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      const element = document.getElementById("pdfContainer"); // Ensure this ID is assigned to the PDF container
      if (element?.requestFullscreen) {
        element.requestFullscreen();
        setIsFullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isNowFullscreen = Boolean(document.fullscreenElement);
      setIsFullscreen(isNowFullscreen);
    };

    // Listen for fullscreen changes
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      // Cleanup listener
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const bookWidth = dimensions.width - BUTTON_WIDTH * 2 - MARGIN * 2; // Adjust the width for the flip book

  return (
    <Box
      id="pdfContainer"
      sx={{
        // border: "2px solid black",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: isFullscreen ? "center" : "none",
        alignContent: isFullscreen ? "center" : "none",
        padding: 1,
        backgroundColor: isFullscreen ? "white" : "none",
        gap: 1,
        transform: `scale(${scale})`,
      }}
    >
      {/* Thumbnail sidebar */}
      <Box
        sx={{
          overflowY: "scroll", // Always show vertical scrollbar
          height: 550, // Adjust height as needed
          borderRight: "1x solid lightgrey",
          width: 150,
          "&::-webkit-scrollbar": {
            width: "10px", // Adjust width for the scrollbar
          },
          // border: "2px solid black",
        }}
      >
        <Document file={file} onLoadSuccess={onLoadSuccess} options={{ cMapUrl: "cmaps/", cMapPacked: true }}>
          {pageSizes.map((size, index) => (
            <Box
              key={index}
              onClick={() => navigateToPage(index + 1)}
              sx={{ width: 115, mb: 2, boxShadow: 3 }}
            >
              <Page
                pageNumber={index + 1}
                width={115} // Fixed width for all thumbnails
                onRenderSuccess={onRenderSuccess(index)}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Box>
          ))}
        </Document>
      </Box>

      {/* PDF viewer and navigation controls */}

      {/* PDF viewer */}
      <Box sx={{ width: "100%", display: "flex", height: 550, flexDirection: "column" }}>
        {" "}
        <Box
          sx={{
            // Adjust height as needed
            // borderRight: "0.5px solid lightgrey",
            width: "100%",
            m: 1,
            // border: "2px solid black",
            display: "flex",
            flexDirection: "row",
            // justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            gap: 1,
          }}
        >
          {" "}
          {/* Left button */}
          <Button
            onClick={handlePrevPage}
            variant="contained"
            sx={{
              justifySelf: "start",
              marginRight: 1,
              width: BUTTON_WIDTH,
              height: 22,
              backgroundColor: "transparent", // this will make the button's background transparent
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)", // optional: change hover color
              },
            }}
          >
            <SvgIcon>
              <path
                d="M17.8852 3.77L16.1152 2L6.11523 12L16.1152 22L17.8852 20.23L9.65523 12L17.8852 3.77Z"
                fill="black"
              />
            </SvgIcon>
          </Button>
          {/* Flip book viewer */}
          <Box
            sx={{
              width: isFullscreen ? "100%" : 700, // Set a specific width
              height: isFullscreen ? 537 : 393, // Set a specific height to contain the pages
              // display: "flex",
              overflowY: "hidden",
              justifyContent: "center",
              alignItems: "center",
              // alignContent: "center",
              // border: "1px solid lightgrey",
            }}
          >
            <Document
              file={file}
              onLoadSuccess={onLoadSuccess}
              options={{ cMapUrl: "cmaps/", cMapPacked: true }}
            >
              <FlipPage
                orientation="horizontal"
                flipOnTouch={true}
                showSwipeHint={true}
                width={"100%"} // Match the width of the container
                ref={flipPageRef}
                onPageChange={onPageChange}
              >
                {pageSizes.map((size, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: isFullscreen ? "100%" : 700,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    {" "}
                    {/* Enforce div size matching FlipPage */}
                    <Page
                      pageNumber={index + 1}
                      width={isFullscreen ? 900 : 700}
                      height={isFullscreen ? 637 : size.height}
                      onRenderSuccess={onRenderSuccess(index)}
                    />
                  </Box>
                ))}
              </FlipPage>
            </Document>
          </Box>
          {/* Right button */}
          <Button
            onClick={handleNextPage}
            variant="contained"
            sx={{
              marginLeft: 1,
              justifySelf: "end",
              width: BUTTON_WIDTH,
              height: 22,
              backgroundColor: "transparent", // this will make the button's background transparent
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)", // optional: change hover color
              },
            }}
          >
            <SvgIcon>
              <path
                d="M6.11523 20.23L7.88523 22L17.8852 12L7.88523 2L6.11523 3.77L14.3452 12L6.11523 20.23Z"
                fill="black"
              />
            </SvgIcon>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
          }}
        >
          <Typography sx={{}}>Page</Typography>
          <TextField
            size="small"
            type="number"
            value={inputPage}
            onChange={handleInputPageChange}
            InputProps={{
              inputProps: {
                min: 1,
                max: numPages,
              },
            }}
            sx={{ width: "auto", margin: "3px" }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              color: "#323031",
              mr: 1,
            }}
          >
            / {numPages}
          </Typography>
          <IconButton onClick={handleFullscreenToggle} sx={{ color: "inherit" }}>
            <FullscreenIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(FlipBookPDFComponent);
