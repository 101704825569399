import { Page, Text, View, Image, Font } from "@react-pdf/renderer";

import { images } from "../LessonSelectionAssets/DevelopmentalGoalImages";
import { checkIsChineseChar } from "helper/helper";

import { API_Project_Module } from "types/project";

import ttb_logo from "images/ttb-logo.png";
import watermark from "images/ttb-watermark-pdf.png";
import fall_back_img from "images/logo.png";

import { removeHtmlTagsAndStyles } from "./utils";
import { styles } from "./styles";
import LessonHeader from "./LessonHeader";

interface DevelopmentalGoalsPageProps {
  projModule: API_Project_Module;
  currentProject: any;
  projectCards: any[];
}

const DevelopmentalGoalsPage = ({
  projModule,
  currentProject,
  projectCards,
}: DevelopmentalGoalsPageProps) => {
  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);

  };

  Font.registerHyphenationCallback(hyphenationCallback);
  return <Page style={styles.page} wrap>
    <View style={styles.watermarkContainer} fixed>
      {watermark && <Image style={styles.watermarkImage} src={watermark} />}
    </View>

    {ttb_logo && <Image src={ttb_logo} style={styles.logoContainer} />}
    <LessonHeader currentProject={currentProject} projModule={projModule} />

    <View style={styles.regularSection}>
      <Text style={styles.activityHeaderText}>Overview</Text>
    </View>

    {currentProject.description && (
      <View>
        <Text style={{ ...styles.regularSectionText, ...checkIsChineseChar(currentProject.description) ? styles.chineseText : styles.englishTextSection }}>{removeHtmlTagsAndStyles(currentProject.description)}</Text>
      </View>
    )}

    <View style={styles.regularSection}>
      <Text style={styles.miniHeaderText}>Developmental Goal</Text>
      {projectCards.map((card: any, index: number) => {
        // Derive image source from card name
        let imageSrc = !card.name ? "" : card.name.split(" ").join("").replace(/&/g, "And").replace(/-/g, "");

        // Special case handling for "STEAM(Science,Technology,Engineering,Art,Math)"
        if (imageSrc === "STEAM(Science,Technology,Engineering,Art,Math)") {
          imageSrc = "STEAM";
        }

        // Determine the image source for the card
        const cardImage = images[imageSrc] || fall_back_img; // Use fallback image if imageSrc not found

        return (
          <View key={index}>
            <View
              style={{
                backgroundColor: "#EBF8F8",
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "4px 8px",
                marginBottom: "5px",
              }}
            >
              {/* Check if cardImage exists before rendering */}
              {cardImage && (
                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#C4E4DF",
                    overflow: "hidden",
                    marginRight: "3px",
                  }}
                >
                  {/* Render the card image */}
                  <Image src={cardImage} style={{ height: "80%", width: "80%" }} />
                </View>
              )}
              <Text
                style={{
                  fontFamily: "FuturaPT600",
                  fontSize: "12px",
                  color: "#5C9391",
                }}
              >
                {card.name}
              </Text>
            </View>
            {card.subFundamentals &&
              card.subFundamentals.map((subFundamental: any, index: number) => {
                return (
                  <View key={`sub-fundamental-${index}`}>
                    <Text
                      style={{
                        marginTop: "10px",
                        fontSize: "12px",
                        color: "#5C9391",
                        fontFamily: checkIsChineseChar(subFundamental.name) ? "KaiTi" : "FuturaPT600",
                        margin: "10px 0px",
                      }}
                    >
                      {subFundamental.name}
                    </Text>
                    {subFundamental.objectives &&
                      subFundamental.objectives.map((objective: any, index: number) => {
                        return (
                          <Text
                            key={`sub-fundamental-objective-${index}`}
                            style={{
                              fontSize: "10px",
                              color: "black",
                              fontFamily: checkIsChineseChar(objective) ? "KaiTi" : "FuturaPT500",
                              marginBottom: "8px",
                            }}
                          >
                            • {objective}
                          </Text>
                        );
                      })}
                  </View>
                );
              })}
            {card.objectives &&
              card.objectives.map((objective: any, index: number) => {
                return (
                  <Text
                    key={`card-objective-${index}`}
                    style={{
                      fontSize: "10px",
                      color: "black",
                      fontFamily: checkIsChineseChar(objective) ? "KaiTi" : "FuturaPT500",
                      marginBottom: "8px",
                    }}
                  >
                    • {objective}
                  </Text>
                );
              })}
          </View>
        );
      })}
    </View>
  </Page>
}

export default DevelopmentalGoalsPage;
