import CloseIcon from "@mui/icons-material/Close";

import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
  Switch,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { API_Fundamental } from "types/project";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  createChildAchievedMilestoneTracker,
  getChildAchievedMilestoneTracker,
  getChildPortfolioObservation,
  updateChildAchievedMilestoneTracker,
} from "api/portfolio-api";
import { useParams } from "react-router-dom";
import { API_Child } from "types/portfolio";
import { useEffect, useState } from "react";
import useStore from "store/store";
import { translation } from "constants/translation";

import AddIcon from "@mui/icons-material/Add";
import SinglePortfolioObservationComponentForEdit from "./SinglePortfolioObservationComponentForEdit";
import CreateObservationModal from "./CreateObservationModal";
import useSnack from "hooks/useSnack";

const EditObservationModal = ({
  open,
  handleClose,
  developmentalGoal,
}: {
  open: boolean;
  handleClose: () => void;
  developmentalGoal: API_Fundamental;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const openSnack = useSnack();
  const { childID, h3TabSlug } = useParams();
  const {
    currLanguage,
    isRefreshPortfolioObservation,
    setIsRefreshLatestMilestone,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    isRefreshPortfolioObservation: state.isRefreshPortfolioObservation,
    setIsRefreshLatestMilestone: state.setIsRefreshLatestMilestone,
  }));

  const [childPortfolioObservationList, setChildPortfolioObservationList] =
    useState<API_Child.PortfolioObservation[]>([]);
  const [childAchievedMilestoneTracker, setChildAchievedMilestoneTracker] =
    useState<API_Child.AchievedMilestoneTracker[]>([]);
  // CONTINUE HERE TO MAKE IT STATUS TOGGLEABLE
  // Then able to edit all the observation inside + toggle them around
  // images not sure wanna allow them to change or not.
  const populateChildAchievedMilestone = async () => {
    const res = await getChildAchievedMilestoneTracker(
      currLanguage,
      Number(childID),
      developmentalGoal.id
    );
    if (typeof res !== "string") {
      setChildAchievedMilestoneTracker(res);
    }
  };

  const populateChildPortfolioObservation = async () => {
    console.log(developmentalGoal)
    const res = await getChildPortfolioObservation({
      language: currLanguage,
      childId: Number(childID),
      developmentalGoalTag: h3TabSlug!,
      developmentalGoalId: developmentalGoal.id,
      isShowAll: true,
    });

    if (typeof res !== "string") {
      console.log(res)
      setChildPortfolioObservationList(res);
    } else {
      setChildPortfolioObservationList([]);
    }
  };

  useEffect(() => {
    isRefreshPortfolioObservation && populateChildPortfolioObservation();
  }, [isRefreshPortfolioObservation]);

  useEffect(() => {
    if (open && childID) {
      populateChildAchievedMilestone();
      populateChildPortfolioObservation();
    }
  }, [childID, open]);

  const handleMilestoneTrackerChange = async (
    tracker: API_Child.AchievedMilestoneTracker | null,
    milestoneId: number
  ) => {
    if (tracker) {
      const updatedTracker: API_Child.AchievedMilestoneTracker = JSON.parse(
        JSON.stringify(tracker)
      );
      updatedTracker.status = !updatedTracker.status;
      const res = await updateChildAchievedMilestoneTracker(updatedTracker);
      if (typeof res !== "string") {
        const trackerCopy = JSON.parse(
          JSON.stringify(childAchievedMilestoneTracker)
        );
        const index = childAchievedMilestoneTracker.findIndex((tracker_) => {
          return tracker_.id === res.id;
        });
        trackerCopy[index].status = res.status;
        setChildAchievedMilestoneTracker(trackerCopy);
        if (res.status) {
          openSnack("Attained!", true);
        } else {
          openSnack("Un-attained!", true);
        }
      }
    } else {
      const res = await createChildAchievedMilestoneTracker(
        Number(childID),
        milestoneId,
        null,
      );
      if (typeof res !== "string") {
        setChildAchievedMilestoneTracker((prev) => [...prev, res]);
      }
    }

    setIsRefreshLatestMilestone(true);
  };

  //Create observation modal
  const [isCreateObservationModalOpen, setIsCreateObservationModalOpen] =
    useState<boolean>(false);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState<number>(-1);
  const handleCreateObservationModalClose = () => {
    setIsCreateObservationModalOpen(false);
  };
  return (
    <>
      <CreateObservationModal
        open={isCreateObservationModalOpen}
        handleClose={handleCreateObservationModalClose}
        milestoneId={selectedMilestoneId}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              // border: "2px solid #000",
              boxShadow: "5px 5px 20px var(--primary-main)",
              borderRadius: "10px",
              height: "90vh",
              width: "80vw",
              overflow: "overlay",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                // color: 'txt.secondary',
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  py: 2,
                  bgcolor: "#D1D5DB",
                  borderRadius: "10px",
                  position: "sticky",
                  height: "100%",
                  top: 0,
                  zIndex: 3,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 500,
                  }}
                >
                  {developmentalGoal.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  py: 1,
                  px: 4,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {developmentalGoal.milestones.map((milestone) => {
                  const tracker = childAchievedMilestoneTracker.find(
                    (tracker) => {
                      return tracker.milestone === milestone.id;
                    }
                  );
                  const observations = childPortfolioObservationList.filter(
                    (observation) => observation.milestone.id === milestone.id
                  );

                  return (
                    <Box key={`milestone-${milestone.name}`}>
                      <Accordion
                        className={"Edited-Accordion"}
                        sx={{
                          backgroundColor: "#d0e3e2bd",
                          // backgroundColor: "pink",
                          "&.Edited-Accordion": {
                            // borderRadius: "10px",
                            border: "2px solid var(--primary-main)",
                            boxShadow: "none",
                          },
                        }}
                        onChange={() => { }}
                        disableGutters={true}
                        onClick={() => {
                          console.log(observations)
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            borderBottom: "2px solid var(--primary-main)",
                            "& > .MuiAccordionSummary-content": {
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            },
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ fontSize: { xs: 12, sm: 20 } }}
                          >
                            {milestone.name}
                          </Typography>
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedMilestoneId(Number(milestone.id));
                                setIsCreateObservationModalOpen(true);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                            <Box
                              sx={{ maxWidth: 150 }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={tracker ? tracker.status : false}
                                    onChange={() => {
                                      handleMilestoneTrackerChange(
                                        tracker ? tracker : null,
                                        Number(milestone.id)
                                      );
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                                label={
                                  isXs
                                    ? ""
                                    : tracker && tracker.status
                                      ? translation.attained
                                      : translation.notAttained
                                }
                                sx={{ marginRight: 0 }}
                              />
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            backgroundColor: "white",
                            position: "relative",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                            }}
                          >
                            {observations.length > 0 ? (
                              observations.map((observation) => {
                                return (
                                  <SinglePortfolioObservationComponentForEdit
                                    key={`observation-${observation.id}`}
                                    childPortfolioObservation={observation}
                                  />
                                );
                              })
                            ) : (
                              <>{translation.noObservations}</>
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

// const AddObservationButton = ({
//   milestoneId,
//   setSelectedMilestoneId,
//   setIsCreateObservationModalOpen,
// }: {
//   milestoneId: number;
//   setSelectedMilestoneId: React.Dispatch<React.SetStateAction<number>>;
//   setIsCreateObservationModalOpen: React.Dispatch<
//     React.SetStateAction<boolean>
//   >;
// }) => {
//   return (
//     <Box
//       sx={{
//         mt: 1,
//         flex: 1,
//         minHeight: 250,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         padding: "16px",
//         borderRadius: "10px",
//         color: "#444",
//         border: "1px dashed",
//         borderColor: "#444",
//         transition: "all 100ms ease-in-out",
//         cursor: "pointer",
//         "&:hover": {
//           color: "primary.main",
//           borderColor: "primary.main",
//           backgroundColor: "rgba(209, 228, 227, 0.37)",
//           transition: "all 100ms ease-in-out",
//         },
//       }}
//       onClick={() => {
//         setSelectedMilestoneId(milestoneId);
//         setIsCreateObservationModalOpen(true);
//       }}
//     >
//       <Box sx={{ display: "flex", gap: 1 }}>
//         <AddIcon />
//         <Typography>{translation.addObservation}</Typography>
//       </Box>
//     </Box>
//   );
// };

export default EditObservationModal;
