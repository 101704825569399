import axios from "api/axiosConfig";
import { AxiosError } from "axios";

export const fetchLearningStories = async (
  groupId: number,
  childID: number
) => {
  try {
    const res = await axios.get(
      `/en/lms/learning-story/group/${groupId}/child/${childID}/list`
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};
export const fetchLearningStoriesWithTimeFrame = async (
  groupId: number,
  childID: number,
  timeFrame: string
) => {
  try {
    const res = await axios.get(
      `/en/lms/learning-story/group/${groupId}/child/${childID}/list/filter?time_frame=${timeFrame}`
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

interface LearningStoryData {
  storyId?: number;
  child_id: number;
  group_id: number;
  image?: File | string | undefined;
  image_url?: string | undefined;
  story: string;
  date: string;
  accessToken: string | null;
  fundamental_id?: string;
  mileStone_id?: string;
}

export const createlearningStoryAPI = async (data: LearningStoryData) => {
  try {
    const res = await axios.post(`/en/lms/learning-story/create`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${data.accessToken}`,
      },
    });
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateLearningStoryAPI = async (data: LearningStoryData) => {
  try {
    const res = await axios.patch(
      `/en/lms/learning-story/${data.storyId}/update`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.accessToken}`,
        },
      }
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteLearningStoryAPI = async (storyId: number) => {
  try {
    const res = await axios.delete(`/en/lms/learning-story/${storyId}/delete`);
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};
