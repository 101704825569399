import background from "images/children-portfolio-v2/LittleLearnerBackground.png";
import learningTitleImage from "images/children-portfolio-v2/learningTitleImage.png";
import Page from "../Page";
import { Typography, Box } from "@mui/material";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import VerticalFrame from 'images/children-portfolio-v2/littleLearnerFrameVertical.png';
import Image from "components/Image/Image";

const PageContent = ({
  scale,
  learningStories,
}: {
  scale: number;
  learningStories: {
    fundamental: string;
    milestone: string;
    image: string;
    story: string;
  }[];
}) => {
  return (
    <Page>
      <img
        src={background}
        alt="background"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <Box
        sx={{
          position: "absolute",
          top: `${50 * scale}px`,
          left: 0,
          width: "100%",
          paddingRight: `${20 * scale}px`,
          paddingLeft: `${40 * scale}px`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Title Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            gap: 1,
          }}
        >
          <Box>
            <img
              src={learningTitleImage}
              alt="Learning Title"
              style={{ height: "40px", width:'40px', marginBottom:'20px' }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "TheOnlyException",
                fontSize: "32px",
                fontWeight: 400,
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              Learning Stories
            </Typography>
          </Box>
        </Box>

        {/* Story Sections */}
        {learningStories.map((story, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              padding: "20px",
              alignItems: "center",
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
            }}
          >
            <Box
              sx={{
                height: index % 2 === 0 ? `${250 * scale}px` : `${230 * scale}px`,
                width: index % 2 === 0 ? `${300 * scale}px` : `${300 * scale}px`,
                position: "relative",
              }}
            >
              <Image
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 5,
                  objectFit: "contain",
                }}
                src={index % 2 === 0 ? Frame : VerticalFrame}
                alt="frame"
              />
              <Image
                style={{
                  position: "absolute",
                  top: index % 2 === 0 ? "30px" : "22px",
                  left: index % 2 === 0 ? "26px" : "60px",
                  width: index % 2 === 0 ? '80%' : '55%',
                  height: index % 2 === 0 ? "58%" : '82%',
                  zIndex: 1,
                  objectFit: "cover",
                }}
                src={story.image}
                alt=""
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: `${250 * scale}px`,
              }}
            >
              <Box sx={{ backgroundColor: "#F9F6F1", p: "24px" }}>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Futura PT",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "22px",
                    letterSpacing: "1.4px",
                  }}
                >
                  {story.fundamental}
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Futura PT",
                    fontSize: `${12 * scale}px`,
                    fontStyle: "normal",
                    fontWeight: 300,
                    lineHeight: `${20 * scale}px` /* 166.667% */,
                    letterSpacing: `${1.2 * scale}px`,
                  }}
                >
                  {story.milestone}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                  fontFamily: "Futura PT",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "20px" /* 166.667% */,
                  letterSpacing: "1.2px",
                  marginTop: "10px",
                }}
              >
                {story.story}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Page>
  );
};

export default PageContent;
