import * as React from "react";
import background from "images/children-portfolio-v2/LittleLearnerBackground.png";
import { Box, Typography } from "@mui/material";
import { PDFInfoType } from "../constant";
import Page from "../Page";
import ImageStructureForThree from "./ImageStructureForThree";
import ImageStructureForFour from "./ImageStructureForFour";
import ImageStructure from "./ImageStructure";
import ImageStructureForOne from "./ImageStructureForOne";
import LittleProjectsHeaderImage from "images/children-portfolio-v2/LittleProjectsHeaderImage.png";
import LittleLearnersHeaderImage from "images/children-portfolio-v2/LittleLearnersHeaderImage.png";
import LittleAcademicHeaderImage from "images/children-portfolio-v2/LittleAcademicHeaderImage.png";
const ModulePages = ({
  scale,
  PDFinfo,
}: {
  scale: number;
  PDFinfo: PDFInfoType;
}) => {
  interface CategoryImages {
    "Little Projects": string;
    "Little Learners": string;
    "Little Academic": string;
  }
  const images: CategoryImages = {
    "Little Projects": LittleProjectsHeaderImage,
    "Little Learners": LittleLearnersHeaderImage,
    "Little Academic": LittleAcademicHeaderImage,
  };
  return (
    <>
      {PDFinfo.categories.map((module, index) => (
        <Page key={index}>
          <img
            src={background}
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Box
            sx={{
              position: "absolute",
              top: `${50 * scale}px`,
              left: 0,
              width: "100%",
              paddingRight: `${20 * scale}px`,
              paddingLeft: `${40 * scale}px`,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                m: "24px",
                gap: 2,
              }}
            >
              <Box>
                <img
                  src={images[module.category_title as keyof typeof images]}
                  alt=""
                  style={{ height: "32px", marginBottom: 10 }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "TheOnlyException",
                    fontSize: "32px",
                    fontWeight: 400,
                    fontStyle: "normal",
                    lineHeight: "normal",
                  }}
                >
                  {module.category_title}
                </Typography>
              </Box>
            </Box>
            <Box>
              {module.projects.length > 4 ? (
                <ImageStructureForFour
                  projects={module.projects.slice(0, 4)}
                  scale={scale}
                />
              ) : module.projects.length === 1 ? (
                <ImageStructureForOne
                  projects={module.projects}
                  scale={scale}
                />
              ) : module.projects.length === 2 ? (
                <ImageStructure projects={module.projects} scale={scale} />
              ) : module.projects.length === 3 ? (
                <ImageStructureForThree
                  projects={module.projects}
                  scale={scale}
                />
              ) : null}
            </Box>
          </Box>
        </Page>
      ))}
    </>
  );
};

export default ModulePages;
