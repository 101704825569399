import * as React from "react";
import { View, Text, Image as PdfImage, StyleSheet } from "@react-pdf/renderer";
import Frame from "images/children-portfolio-v2/learningStoryFrame.png";
import { checkIsChineseChar } from "helper/helper";

interface Project {
  title: string;
  id: number;
  category: string;
  image_url: string;
}

type ImageStructureProps = {
  projects: Project[];
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: 10,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    marginBottom: 20,
  },
  projectContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    marginBottom: 10,
    marginHorizontal: 10,
  },
  horizontalFrameContainer: {
    marginTop: 40,
    position: "relative",
    width: 250,
    height: 220,
  },
  frameImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  projectImage: {
    position: "absolute",
    top: "8.45%",
    left: "10.55%",
    width: "79.2%",
    height: "62.9%",
    objectFit: "cover",
  },
  captionContainer: {
    width: 230,
    height: 50,
    backgroundColor: "#F9F6F1",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
    justifyContent: "center",
    marginTop: -30,
    marginRight: 10,
  },
  categoryText: {
    fontWeight: 400,
    color: "#000",
    fontSize: 14,
    letterSpacing: 1.4,
  },
  titleText: {
    fontWeight: 300,
    color: "#000",
    fontSize: 12,
    letterSpacing: 1.2,
  },
});

const ImageStructureForFour: React.FC<ImageStructureProps> = ({ projects }) => {
  return (
    <View style={styles.container}>
      <View style={styles.rowContainer}>
        <View style={styles.projectContainer}>
          <View style={styles.horizontalFrameContainer}>
            <PdfImage
              style={styles.projectImage}
              src={{
                uri: projects[0].image_url,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
            />
            <PdfImage style={styles.frameImage} src={Frame} />
          </View>
          <View style={styles.captionContainer}>
            <Text
              style={[
                styles.categoryText,
                {
                  fontFamily: checkIsChineseChar(projects[0].category)
                    ? "KaiTi"
                    : "FuturaBkBT",
                },
              ]}
            >
              {projects[0].category}
            </Text>
            <Text
              style={[
                styles.titleText,
                {
                  fontFamily: checkIsChineseChar(projects[0].title)
                    ? "KaiTi"
                    : "FuturaBkBT",
                },
              ]}
            >
              {projects[0].title}
            </Text>
          </View>
        </View>
        <View style={styles.projectContainer}>
          <View style={styles.horizontalFrameContainer}>
            <PdfImage
              style={styles.projectImage}
              src={{
                uri: projects[1].image_url,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
            />
            <PdfImage style={styles.frameImage} src={Frame} />
          </View>
          <View style={styles.captionContainer}>
            <Text
              style={[
                styles.categoryText,
                {
                  fontFamily: checkIsChineseChar(projects[1].category)
                    ? "KaiTi"
                    : "FuturaBkBT",
                },
              ]}
            >
              {projects[1].category}
            </Text>
            <Text
              style={[
                styles.titleText,
                {
                  fontFamily: checkIsChineseChar(projects[1].title)
                    ? "KaiTi"
                    : "FuturaBkBT",
                },
              ]}
            >
              {projects[1].title}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.rowContainer}>
        <View style={styles.projectContainer}>
          <View style={styles.horizontalFrameContainer}>
            <PdfImage
              style={styles.projectImage}
              src={{
                uri: projects[2].image_url,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
            />
            <PdfImage style={styles.frameImage} src={Frame} />
          </View>
          <View style={styles.captionContainer}>
            <Text
              style={[
                styles.categoryText,
                {
                  fontFamily: checkIsChineseChar(projects[2].category)
                    ? "KaiTi"
                    : "FuturaBkBT",
                },
              ]}
            >
              {projects[2].category}
            </Text>
            <Text
              style={[
                styles.titleText,
                {
                  fontFamily: checkIsChineseChar(projects[2].title)
                    ? "KaiTi"
                    : "FuturaBkBT",
                },
              ]}
            >
              {projects[2].title}
            </Text>
          </View>
        </View>
        <View style={styles.projectContainer}>
          <View style={styles.horizontalFrameContainer}>
            <PdfImage
              style={styles.projectImage}
              src={{
                uri: projects[3].image_url,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
            />
            <PdfImage style={styles.frameImage} src={Frame} />
          </View>
          <View style={styles.captionContainer}>
            <Text
              style={[
                styles.categoryText,
                {
                  fontFamily: checkIsChineseChar(projects[3].category)
                    ? "KaiTi"
                    : "FuturaBkBT",
                },
              ]}
            >
              {projects[3].category}
            </Text>
            <Text
              style={[
                styles.titleText,
                {
                  fontFamily: checkIsChineseChar(projects[3].title)
                    ? "KaiTi"
                    : "FuturaBkBT",
                },
              ]}
            >
              {projects[3].title}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ImageStructureForFour;
