import React from "react";
import { TextField } from "@mui/material";
import Image from "../../Image/Image";
import { PDFInfoType, editableStyle } from "./constant";
import Background from "images/children-portfolio-v2/concluding-remark.png";
import Page from "./Page";

const ConcludingRemarkPage = ({
  scale,
  PDFInfo,
  setPDFInfo,
}: {
  scale: number;
  PDFInfo: PDFInfoType;
  setPDFInfo: React.Dispatch<React.SetStateAction<PDFInfoType>>;
}) => {
  const handleChange = (key: string, value: string | null) => {
    setPDFInfo((prev) => ({ ...prev, [key]: value ? value : "" }));
  };

  return (
    <Page>
      <Image src={Background} alt="" />

      <TextField
        sx={{
          ...editableStyle,
          position: "absolute",
          top: `${140 * scale}px`,
          left: `${85 * scale}px`,
          right: `${50 * scale}px`,
          height: "100%",
          maxHeight: `${400 * scale}px`,
          "& textarea": {
            fontSize: 16 * scale,
            fontFamily: "FuturaLtBT",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "left",
            height: "100% !important",
            maxHeight: `${400 * scale}px`,
            overflow: "hidden",
            padding: 0,
          },
        }}
        placeholder="Write the remark here..."
        variant="standard"
        InputProps={{
          disableUnderline: true,
          sx: {
            padding: 0,
            height: "100%",
            maxHeight: `${400 * scale}px`,
          },
        }}
        value={PDFInfo.concludingRemarkText}
        onChange={(e) => {
          handleChange("concludingRemarkText", e.target.value);
        }}
        multiline
        rows={17}
      />
    </Page>
  );
};

export default ConcludingRemarkPage;
