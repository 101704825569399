import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

import { API_Teacher_Lesson, API_Teacher_Text_Activity } from "types/teacher";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { TeacherLessonActivityScreenDoneButton } from "../TeacherPage/TeacherPageSideMenuScreens/TeacherLessonActivityScreenButtons";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { IconButton, Typography } from "@mui/material";
import { iconButtonStyle } from "../TeacherMCQActivityScreen/TeacherMcqActivityScreen";
import FlipBookPDFComponent from "../TeacherPage/TeacherEdit/TeacherFormComponents/FlipBookPDFComponent";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TeacherTextActivityScreen = ({
  teacherCurrentActivity,
  teacherCurrentLesson,
}: {
  teacherCurrentActivity: API_Teacher_Text_Activity;
  teacherCurrentLesson: API_Teacher_Lesson;
}) => {
  const [currTextActivityComponentIndex, setCurrTextActivityComponentIndex] = useState<number>(0);

  const contentScreenRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (contentScreenRef.current) {
      // console.log("contentScreenRef scroll:");
      contentScreenRef.current.scrollTo(0, 0);
    }
  }, [currTextActivityComponentIndex]);

  const [flipDimensions, setFlipDimensions] = useState({ width: 500, height: 500 }); // Default dimensions

  useEffect(() => {
    // Make sure the flip_book array exists and has at least one item before attempting to access its properties
    if (
      teacherCurrentActivity?.flip_book &&
      teacherCurrentActivity?.flip_book?.length > 0 &&
      teacherCurrentActivity.flip_book[0].file
    ) {
      const fileURL = teacherCurrentActivity.flip_book[0].file;
      pdfjs
        .getDocument(fileURL)
        .promise.then((pdfDoc) => {
          return pdfDoc.getPage(1);
        })
        .then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          setFlipDimensions({ width: viewport.width, height: viewport.height });
        })
        .catch((error) => {
          console.error("Error loading PDF document:", error);
        });
    }
  }, [teacherCurrentActivity.flip_book]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        // transform: "scale(0.4)",
        // border: "1px solid black",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          gap: 0,
          flex: 1,
        }}
      >
        {teacherCurrentActivity.flip_book && teacherCurrentActivity.flip_book.length === 0 && (
          <IconButton
            sx={{ padding: 0 }}
            disableFocusRipple
            disableRipple
            disabled={currTextActivityComponentIndex === 0}
            aria-label="prev-text-activity-component"
            onClick={() => {
              setCurrTextActivityComponentIndex((prev) => prev - 1);
            }}
          >
            <WestIcon />
          </IconButton>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            flex: 1,
            overflowY: "auto",
          }}
        >
          <Typography sx={{ fontSize: 20, fontWeight: 500, textAlign: "center" }}>
            {teacherCurrentActivity.text_activity_components &&
            teacherCurrentActivity.text_activity_components.length > 0
              ? teacherCurrentActivity.text_activity_components[currTextActivityComponentIndex].name
              : ""}
          </Typography>

          <Box
            ref={contentScreenRef}
            className="ck-content"
            color="txt.dark2"
            sx={{
              width: "100%",
              height: "100%",
              flex: "1 1 0",
              overflowY: "auto",
            }}
            dangerouslySetInnerHTML={{
              __html:
                teacherCurrentActivity.text_activity_components &&
                teacherCurrentActivity.text_activity_components.length > 0
                  ? teacherCurrentActivity.text_activity_components[currTextActivityComponentIndex]
                      .description
                  : "",
            }}
          />

          {teacherCurrentActivity.flip_book && teacherCurrentActivity.flip_book.length > 0 && (
            <FlipBookPDFComponent
              file={teacherCurrentActivity.flip_book[0].file}
              dimensions={{ width: 500, height: flipDimensions.height }}
              scale={1}
            />
          )}
        </Box>

        {teacherCurrentActivity.flip_book && teacherCurrentActivity.flip_book.length === 0 && (
          <IconButton
            disableFocusRipple
            disableRipple
            sx={{
              ...iconButtonStyle,
              display: currTextActivityComponentIndex === -2 ? "none" : "flex",
              padding: 0,
            }}
            disabled={
              teacherCurrentActivity.text_activity_components &&
              teacherCurrentActivity.text_activity_components.length > 0
                ? currTextActivityComponentIndex ===
                  teacherCurrentActivity.text_activity_components.length - 1
                : true
            }
            aria-label="next-text-activity-component"
            onClick={() => {
              setCurrTextActivityComponentIndex((prev) => prev + 1);
            }}
          >
            <EastIcon />
          </IconButton>
        )}
      </Box>

      {currTextActivityComponentIndex === teacherCurrentActivity.text_activity_components!.length - 1 && (
        <TeacherLessonActivityScreenDoneButton activityId={teacherCurrentActivity.id} />
      )}
    </Box>
  );
};

export default TeacherTextActivityScreen;
