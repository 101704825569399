import { Box, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import {
  API_Teacher_Lesson,
  API_Teacher_Mcq_Activity,
  API_Mcq_Option,
  API_Teacher_Mcq_Modal_Info,
} from "types/teacher";
import DoneIcon from "@mui/icons-material/Done";

import useStore from "store/store";
import { translation } from "constants/translation";
import Button from "components/Button/Button";
import useSnack from "hooks/useSnack";

import { TeacherLessonActivityScreenDoneButton } from "../TeacherPage/TeacherPageSideMenuScreens/TeacherLessonActivityScreenButtons";
import CongrazModal from "../TeacherPage/TeacherPageComponents/CongrazModal";
import TryAgainPage from "../TeacherLessonActivityTypeScreen/TeacherActivitySharedComponents/TryAgainPage";
import { updateLearnerActivityProgressFunctionCall } from "../TeacherPage/TeacherPageHelper";
import McqQuestion from "./McqQuestion";
import ScreenStatus from "./ScreenStatus";

export const iconButtonStyle = {
  color: "primary.main",
};

const TeacherMcqActivityScreen = ({
  teacherCurrentActivity,
  teacherCurrentLesson,
}: {
  teacherCurrentActivity: API_Teacher_Mcq_Activity;
  teacherCurrentLesson: API_Teacher_Lesson;
}) => {
  const openSnack = useSnack();
  const [mcqModalInfo, setMcqModalInfo] = useState<API_Teacher_Mcq_Modal_Info>({
    open: false,
    correctCount: -1,
    totalCount: -1,
  });
  const { lessonSlug } = useParams();
  const {
    currLanguage,
    profileDetails,
    learnerLessonProgressList,
    setLearnerLessonProgressList,
    setDisplayObtainedCertificateModalInfo,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    learnerLessonProgressList: state.learnerLessonProgressList,
    setLearnerLessonProgressList: state.setLearnerLessonProgressList,
    setDisplayObtainedCertificateModalInfo:
      state.setDisplayObtainedCertificateModalInfo,
  }));

  const [currMcqQuestionIndex, setCurrMcqQuestionIndex] = useState<number>(
    teacherCurrentActivity.instruction !== "" ? -1 : 0
  );

  const [selectedOptions, setSelectedOptions] = useState<API_Mcq_Option[]>([]);
  const [correctCount, setCorrectCount] = useState<number>(-1);
  const totalQuestion = teacherCurrentActivity.mcq_questions!.length;

  const currLessonIndex = learnerLessonProgressList.findIndex(
    (learnerLessonProgress) => learnerLessonProgress.lesson_slug === lessonSlug
  );

  const isActivityDone = learnerLessonProgressList[currLessonIndex]
    ? learnerLessonProgressList[currLessonIndex].activity_done_list.includes(
        teacherCurrentActivity.id
      )
    : false;

  const handleMcqSubmit = async () => {
    const correctAnswerCount = selectedOptions.filter(
      (option) => option.is_correct
    ).length;
    setCorrectCount(correctAnswerCount);
    if (correctAnswerCount === totalQuestion) {
      openSnack(
        translation.mcqAnsweredAllCorrect ||
          "Well done! You have answered all the questions correctly!",
        true
      );
      if (!isActivityDone) {
        await updateLearnerActivityProgressFunctionCall({
          profileDetails: profileDetails,
          currLanguage: currLanguage,
          teacherCurrentActivityId: teacherCurrentActivity.id,
          accessToken: localStorage.getItem("access")!,
          learnerLessonProgressList,
          currLessonIndex,
          setLearnerLessonProgressList,
          setDisplayObtainedCertificateModalInfo,
        });
      }
      setMcqModalInfo((prev) => ({
        correctCount: correctAnswerCount,
        totalCount: totalQuestion,
        open: true,
      }));
    } else {
      setSelectedOptions([]);
      setCurrMcqQuestionIndex(-2);
      openSnack(
        String(
          translation.formatString(
            translation.mcqPleaseRetry || "Please Retry",
            {
              wrongAnswerCount: totalQuestion - correctAnswerCount,
            }
          )
        ),
        false
      );
    }
  };

  console.log(teacherCurrentActivity.instruction);

  return (
    <>
      <CongrazModal
        mcqModalInfo={mcqModalInfo}
        setMcqModalInfo={setMcqModalInfo}
      />
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            gap: 1,
            flex: 1,
          }}
        >
          <Box
            sx={{
              p: 2,
              bgcolor: "background.paper",
              borderRadius: "10px",
              overflowX: "hidden",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {currMcqQuestionIndex === -2 ? (
              <TryAgainPage
                correctCount={correctCount}
                totalCount={totalQuestion}
                setCurrMcqQuestionIndex={setCurrMcqQuestionIndex}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  gap: 3,
                  flex: 1,
                }}
              >
                <ScreenStatus
                  isActivityDone={isActivityDone}
                  correctCount={correctCount}
                  totalCount={totalQuestion}
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {/* Displaying the instruction with HTML formatting */}
                  <Typography
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: teacherCurrentActivity.instruction,
                    }}
                  />
                  {teacherCurrentActivity?.mcq_questions?.map(
                    (mcqQuestion, mcqQuestionIndex) => (
                      <McqQuestion
                        key={McqQuestion.name}
                        isActivityDone={isActivityDone}
                        mcqQuestion={mcqQuestion}
                        currMcqQuestionIndex={mcqQuestionIndex}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                      />
                    )
                  )}
                </Box>
                {!isActivityDone && (
                  <Button
                    disabled={selectedOptions.length !== totalQuestion}
                    buttonText={translation.submit || "Submit"}
                    arrow={false}
                    onClick={handleMcqSubmit}
                  />
                )}
              </Box>
            )}
            {isActivityDone && (
              <Box>
                <TeacherLessonActivityScreenDoneButton
                  isDisplayButton={false}
                  activityId={teacherCurrentActivity.id}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TeacherMcqActivityScreen;
