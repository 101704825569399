import React, { useEffect, useState } from "react";
import useStore from "store/store";
import {
  Button,
  Box,
  Checkbox,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  Chip,
} from "@mui/material";
import {
  H3_TABS,
  H3_TAB_TYPE,
  HANDS_TEXT,
  HEAD_TEXT,
  HEART_TEXT,
  ALL_DEV_SLUGS,
} from "containers/PortfolioPage/portfolioPageConstants";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { getAllFundamentals } from "api/fundamental-api";
import { API_Fundamental, Query_Data } from "types/project";

import { useLocation, useNavigate, useParams, Outlet } from "react-router-dom";
import Image from "components/Image/Image";
import { getChildPortfolioObservation } from "api/portfolio-api";

import HeartImg from "images/heart.png";
import HeadImg from "images/head.png";
import HandImg from "images/hand.png";

import {
  CHILDREN_PORTFOLIO_PAGE,
  CURLANG,
  MY_CHILDREN_PORTFOLIO,
} from "constants/url";
import { tabs } from "../constants";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import { translation } from "constants/translation";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CompactViewModal from "./DevelopmentalGoalsScreenComponents/CompactViewModal";
import EditObservationModal from "./DevelopmentalGoalsScreenComponents/EditObservationModal";
import { initialDevelopmentalGoal } from "containers/DevelopmentalGoalPage/EditDevelopmentalGoalPage";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";

const DevelopmentalGoalsScreen = ({
  isParentView = false,
}: {
  isParentView?: boolean;
}) => {
  const location = useLocation();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const { groupSlug, childID, h3TabSlug } = useParams();
  const navigate = useNavigate();
  const {
    currLanguage,
    isRefreshLatestMilestone,
    setIsRefreshLatestMilestone,
    isRefreshPortfolioObservation,
    setIsRefreshPortfolioObservation,
    childPortfolioObservationList,
    setChildPortfolioObservationList,
    isCompactView,
    setIsCompactView,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    isRefreshLatestMilestone: state.isRefreshLatestMilestone,
    setIsRefreshLatestMilestone: state.setIsRefreshLatestMilestone,
    isRefreshPortfolioObservation: state.isRefreshPortfolioObservation,
    setIsRefreshPortfolioObservation: state.setIsRefreshPortfolioObservation,
    childPortfolioObservationList: state.childPortfolioObservationList,
    setChildPortfolioObservationList: state.setChildPortfolioObservationList,
    isCompactView: state.isCompactView,
    setIsCompactView: state.setIsCompactView,
  }));

  useEffect(
    () => setIsCompactView(isParentView),
    [isParentView, setIsCompactView]
  );

  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });

  const [relatedDevelopmentalGoals, setRelatedDevelopmentalGoals] = useState<
    API_Fundamental[]
  >([]);

  const populateFundamentals = async () => {
    setDevelopmentalGoalsLoading(true);
    const res = await getAllFundamentals(
      currLanguage,
      h3TabSlug!,
      Number(childID)
    );
    res.sort((a, b) => a.priority - b.priority);

    if (typeof res !== "string") {
      setRelatedDevelopmentalGoals(res);
    } else {
      setRelatedDevelopmentalGoals([]);
    }
    setIsRefreshLatestMilestone(false);
    setDevelopmentalGoalsLoading(false);
  };

  useEffect(() => {
    h3TabSlug && populateFundamentals();
  }, [h3TabSlug, currLanguage]);

  useEffect(() => {
    isRefreshLatestMilestone && populateFundamentals();
  }, [isRefreshLatestMilestone]);

  const [tabSelected, setTabSelected] = useState<H3_TAB_TYPE>(
    h3TabSlug ? (h3TabSlug as H3_TAB_TYPE) : HEART_TEXT
  );

  const populateChildPortfolioObservation = async () => {
    const res = await getChildPortfolioObservation({
      language: currLanguage,
      childId: Number(childID),
      developmentalGoalTag: h3TabSlug!,
      queryData: queryData,
    });

    if (typeof res !== "string") {
      setChildPortfolioObservationList(res);
    } else {
      setChildPortfolioObservationList([]);
    }
    setIsRefreshPortfolioObservation(false);
  };

  useEffect(() => {
    populateChildPortfolioObservation();
  }, [queryData.startDate, queryData.endDate]);

  useEffect(() => {
    isRefreshPortfolioObservation && populateChildPortfolioObservation();
  }, [isRefreshPortfolioObservation]);

  useEffect(() => {
    h3TabSlug && populateChildPortfolioObservation();
    h3TabSlug && setTabSelected(h3TabSlug as H3_TAB_TYPE);
  }, [h3TabSlug]);

  // Parent View Modal
  const [isParentViewModalOpen, setIsParentViewModalOpen] =
    useState<boolean>(false);
  const handleParentViewModalClose = () => {
    setIsParentViewModalOpen(false);
  };

  // Edit Dev Goal Modal
  const [selectedDevelopmentalGoal, setSelectedDevelopmentalGoal] =
    useState<API_Fundamental>(initialDevelopmentalGoal);
  const [isEditDevGoalModalOpen, setIsEditDevGoalModalOpen] =
    useState<boolean>(false);
  const handleDevGoalModalClose = () => {
    setIsEditDevGoalModalOpen(false);
  };
  const [developmentalGoalsLoading, setDevelopmentalGoalsLoading] =
    useState<boolean>(false);

  const slugify = (text: string) => {
    return (
      text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        // .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, "")
    ); // Trim - from end of text
  };

  const lastSegment =
    decodeURIComponent(location.pathname).split("/").pop() ?? "";
  if (ALL_DEV_SLUGS.includes(lastSegment)) {
    return <Outlet />;
  } else {
    return (
      <>
        <CompactViewModal
          open={isParentViewModalOpen}
          handleClose={handleParentViewModalClose}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
            position: "relative",
          }}
        >
          {H3_TABS.map((h3_tab) => {
            return (
              <Box
                key={`selection-h3_tab-${h3_tab}`}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                  padding: isLg ? 2 : isMd ? 1 : 1,
                  flex: 0.75,
                  "&:hover": {
                    backgroundColor: `#C4E4DF`,
                  },
                  gap: 1,
                  borderRadius: isLg ? "10px" : "25px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  backgroundColor: `${
                    tabSelected === h3_tab ? "#C4E4DF" : "white"
                  }`,
                  cursor: "pointer",
                  border: "1px solid #91C6C5",
                  zIndex: h3_tab == "heart" ? 10 : h3_tab == "head" ? 5 : 2,
                  marginLeft: h3_tab == "heart" ? 0 : isLg ? "-5px" : "-8px",
                }}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  !developmentalGoalsLoading &&
                    navigate(
                      `${CURLANG(currLanguage)}/${
                        isParentView
                          ? `${MY_CHILDREN_PORTFOLIO}/${childID}`
                          : CHILDREN_PORTFOLIO_PAGE(groupSlug!, childID!)
                      }/${tabs[1]}/${h3_tab}`
                    );
                }}
              >
                <Image
                  style={{
                    maxHeight: "25px",
                    width: "26px",
                    maxWidth: "100px",
                    // marginRight:
                    //   h3_tab === HEART_TEXT
                    //     ? "-15px"
                    //     : h3_tab === HEAD_TEXT
                    //     ? "-13px"
                    //     : "-20px",
                  }}
                  src={
                    h3_tab === HEART_TEXT
                      ? HeartImg
                      : h3_tab === HEAD_TEXT
                      ? HeadImg
                      : h3_tab === HANDS_TEXT
                      ? HandImg
                      : ""
                  }
                  alt={translation.developmentalGoals}
                />
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    display: "flex",
                    fontSize: isLg ? "16px" : isMd ? "14px" : "12px",
                    fontWeight: isLg ? "750" : "450",
                  }}
                >
                  {h3_tab}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
            mb: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              width: "100%",
              mb: 2,
            }}
          >
            <StartAtDateField
              queryData={queryData}
              setQueryData={setQueryData}
            />
            <EndAtDateField queryData={queryData} setQueryData={setQueryData} />
            {!isParentView && !isXs && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  ml: { xs: 0, sm: "auto" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    px: 1,
                    border: "1px solid var(--primary-main)",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsCompactView(!isCompactView);
                  }}
                >
                  <Checkbox size={"small"} checked={isCompactView} />
                  <Typography>{translation.parentView}</Typography>
                </Box>
                <IconButton
                  onClick={() => {
                    setIsParentViewModalOpen(true);
                  }}
                >
                  <HelpOutlineIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        {relatedDevelopmentalGoals.length > 0 && developmentalGoalsLoading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIndicator />
          </Box>
        ) : (
          <Box
            sx={{
              // display: "flex",
              // flexDirection: "row",
              // flexWrap: "wrap", // Allow items to wrap onto multiple lines
              // justifyContent: "flex-start", // Align items to the start
              // add gap here
              display: "grid",
              gridTemplateColumns: isLg
                ? "1fr 1fr 1fr"
                : isMd
                ? "1fr 1fr"
                : "1fr",
              // gridTemplateColumns: "1fr",
              width: "100%",
              gap: 3,

              // backgroundColor: 'purple',
            }}
          >
            <EditObservationModal
              open={isEditDevGoalModalOpen}
              handleClose={handleDevGoalModalClose}
              developmentalGoal={selectedDevelopmentalGoal!}
            />

            {relatedDevelopmentalGoals.map((developmentalGoal) => {
              const allObservations = childPortfolioObservationList.filter(
                (portfolioObservation) => {
                  return (
                    portfolioObservation.developmental_goal ==
                    developmentalGoal.id
                  );
                }
              );

              return (
                <Card
                  key={`developmentalGoal-${developmentalGoal.name}`}
                  sx={{
                    flexShrink: 1,
                    flexBasis: "calc(33.3333%)",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    {allObservations.length > 0 ? (
                      <Image
                        style={{
                          height: "208px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        src={allObservations[0].image}
                        alt={allObservations[0].caption}
                      />
                    ) : (
                      <Box
                        sx={{
                          backgroundColor: "#D8F2EE",
                          height: "208px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <AddAPhotoIcon
                          sx={{
                            color: "white",
                            backgroundColor: "#D8F2EE",
                            height: "100px",
                            width: "100px",
                          }}
                        />
                      </Box>
                    )}

                    <Box sx={{ backgroundColor: "#EBF8F8", display: "flex" }}>
                      <Box sx={{ display: "flex", padding: "12px", gap: 1 }}>
                        <Image
                          style={{
                            height: "24px",
                            // width: 40,
                            width: "24px",
                          }}
                          src={developmentalGoal.image}
                          alt={developmentalGoal.name}
                        />
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          {developmentalGoal.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        px: 2,
                        pt: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "450",
                          color: "#91C6C5",
                        }}
                      >
                        Current Milestone
                      </Typography>

                      <Chip
                        size="small"
                        label={`${developmentalGoal.learning_moment_documented || 0
                          } learning ${developmentalGoal.learning_moment_documented == 1
                            ? "moment"
                            : "moments"
                          } documented`}

                        // color="primary"
                        sx={{ cursor: "pointer", px: "8px", py: "4px" }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "white",
                      padding: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    {/* age image and description */}
                    {typeof developmentalGoal.latest_achieved_milestone !==
                      "number" &&
                    developmentalGoal.latest_achieved_milestone ? (
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Box
                          sx={{
                            width: "32px",
                            height: "32px",
                            overflow: "hidden",
                            // border: "2px solid var(--primary-main)",

                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#D8F2EE",
                          }}
                        >
                          <Image
                            style={{ width: "20px", height: "20px" }}
                            src={
                              developmentalGoal.latest_achieved_milestone.age ==
                              "Sprouts"
                                ? SproutsIcon
                                : developmentalGoal.latest_achieved_milestone
                                    .age == "Buds"
                                ? BudsIcon
                                : BlossomsIcon
                            }
                            alt="Level"
                          />
                        </Box>
                        <Typography sx={{ flexGrow: 1 }}>
                          {developmentalGoal.latest_achieved_milestone.name}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography>No milestones recorded!</Typography>
                    )}
                    <Button
                      sx={{
                        backgroundColor: "#91C6C5",
                        width: "100%",
                        "&:hover": { backgroundColor: "#D8F2EE" },
                      }}
                      onClick={() => {
                        console.log(slugify(developmentalGoal.name));
                        navigate(
                          `${CURLANG(currLanguage)}/${
                            isParentView
                              ? `${MY_CHILDREN_PORTFOLIO}/${childID}`
                              : CHILDREN_PORTFOLIO_PAGE(groupSlug!, childID!)
                          }/${tabs[1]}/${h3TabSlug}/${slugify(
                            developmentalGoal.name
                          )}`
                        );
                      }}
                    >
                      <Typography
                        sx={{ color: "white", textTransform: "none" }}
                      >
                        See All Milestones
                      </Typography>
                    </Button>
                  </Box>
                </Card>
              );
            })}
          </Box>
        )}
      </>
    );
  }
};

export default DevelopmentalGoalsScreen;
