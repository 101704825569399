import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import Link from "@mui/material/Link";
import useStore from "store/store";

import { Breadcrumbs, Typography } from "@mui/material";
import {
  CURLANG,
  TEACHER_LESSON_ACTIVITY_PAGE,
  TEACHER_MODULE_PAGE,
  TEACHER_TRAINING_ACADEMY,
} from "constants/url";

const LessonActivityBreadcrumbs = () => {
  const { moduleSlug } = useParams();
  const {
    currLanguage,
    teacherCurrentActivity: { name: activityName },
    teacherCurrentLesson: {
      activities: lessonActivities,
      name: lessonName,
      slug: lessonSlug,
    },
    teacherCurrentModule: { name: moduleName },
  } = useStore((state) => state);

  const [lessonFirstActivity, modulePage] = useMemo(() => {
    const activitySlug = lessonActivities[0]?.slug ?? "";
    return [
      `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
        moduleSlug!,
        lessonSlug,
        activitySlug
      )}`,
      `${CURLANG(currLanguage)}/${TEACHER_MODULE_PAGE(moduleSlug!)}`,
    ];
  }, [currLanguage, lessonActivities, lessonSlug, moduleSlug]);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{
        ml: 5,
        justifyContent: "center",
        ".MuiBreadcrumbs-li": {
          marginLeft: "0 !important",
        },
      }}
    >
      <Link
        color="inherit"
        href={`${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`}
        sx={{
          ml: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HomeIcon sx={{ width: "20px", height: "20px" }} />
      </Link>
      <Link underline="hover" color="inherit" href={modulePage}>
        {moduleName}
      </Link>
      <Link underline="hover" color="inherit" href={lessonFirstActivity}>
        {lessonName}
      </Link>
      <Typography>{activityName}</Typography>
    </Breadcrumbs>
  );
};

export default LessonActivityBreadcrumbs;
