import React, { useEffect, useState, useRef } from "react";
import { GalleryPhotoVideo } from "types/gallery";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Backdrop,
  Box,
  Fade,
  Modal,
  IconButton,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Button as MuiButton,
} from "@mui/material";
import { useParams } from "react-router-dom";

import useDialog from "hooks/useDialog";
import useSnack from "hooks/useSnack";
import WarningDialog from "components/Dialog/WarningDialog";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { GENERAL_DATE_FORMAT } from "constants/constantInGeneral";

import { getLearningMomentImages, tagProfilesToImage } from "api/gallery-api";

import useStore from "store/store";

import TagIcon from "@mui/icons-material/Tag";
import CropIcon from "@mui/icons-material/Crop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhotoVideoList from "components/PhotoVideoGalleryComponents/PhotoVideoGalleryModalV2/PhotoVideoList";
import PhotoAndVideoListHeader from "components/PhotoVideoGalleryComponents/PhotoVideoGalleryModalV2/PhotoAndVideoListHeader";
import { API_Profile_Short } from "types/profile";
import ImageVideoBoxV2 from "components/PhotoVideoGalleryComponents/ImageVideoBoxV2";
import { translation } from "constants/translation";
import {
  deletePortfolioObservation,
  updatePortfolioObservation,
} from "api/portfolio-api";
import DevelopmentalGoalsScreenEditRow from "./DevelopmentalGoalsScreenEditRow";


const DevelopmentalGoalObservationModal = ({
  open,
  setOpen,
  setPermissionModal,
  photoVideoGalleryList,
  galleryIndex,
  setGalleryIndex,
  currPhotoVideoIndex,
  setCurrPhotoVideoIndex,
  milestone,
  initializeGallery,
  profileDict,
  selectedProfiles,
  setSelectedProfiles,
  allObservations,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPermissionModal: React.Dispatch<React.SetStateAction<boolean>>;
  photoVideoGalleryList: GalleryPhotoVideo[];
  galleryIndex: number;
  setGalleryIndex: React.Dispatch<React.SetStateAction<number>>;
  currPhotoVideoIndex: number;
  setCurrPhotoVideoIndex: React.Dispatch<React.SetStateAction<number>>;
  milestone?: any;
  initializeGallery: () => Promise<void>;
  profileDict: {
    [id: string]: API_Profile_Short;
  };
  selectedProfiles: string[][][];
  setSelectedProfiles: React.Dispatch<React.SetStateAction<string[][][]>>;
  allObservations: any;
}) => {
  const today = new Date().toLocaleDateString("en-ca");
  const { setIsRefreshPortfolioObservation } = useStore((state) => ({
    setIsRefreshPortfolioObservation: state.setIsRefreshPortfolioObservation,
  }));
  const { childID } = useParams();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const openSnack = useSnack();

  const handleVisibilityChange = async () => {
    const res = await updatePortfolioObservation({
      id: selectedPhotoComplete?.id,
      image: selectedPhotoComplete?.image,
      is_published: !selectedPhotoComplete?.is_published,
    });
    if (typeof res !== "string") {
      setSelectedPhotoComplete({
        ...selectedPhotoComplete,
        is_published: res.is_published,
      });
      openSnack(`${res.is_published ? "Published!" : "Un-published!"}`, true);
      setIsRefreshPortfolioObservation(true);
    }
  };

  const [selectedPhotoVideoIDs, setSelectedPhotoVideoIDs] = useState<string[]>(
    []
  );
  const [editCaption, setEditCaption] = useState<boolean>(false);
  const [captionField, setCaptionField] = useState<string | undefined>("");
  const [editedPhoto, setEditedPhoto] = useState<File | undefined>(); // final output of edited photo
  const [edit, setEdit] = useState<boolean>(false);
  const [photoId, setPhotoId] = useState<string>(""); //  raw photo id to pass
  const [photoName, setPhotoName] = useState<string>(""); // raw photo name to pass
  const [photoSource, setPhotoSource] = useState<string>(""); // raw source to pass
  const [selectedPhoto, setSelectedPhoto] = useState<any>({});
  const [selectedPhotoComplete, setSelectedPhotoComplete] = useState<any>(null);

  useEffect(() => {
    if (open && currPhotoVideoIndex > -1) {
      handleEditImage();
      let selectedItem = allObservations.find(
        (item: any) =>
          photoVideoGalleryList[galleryIndex].photo_video_list[
            currPhotoVideoIndex
          ]?.src === item.image
      );
      setSelectedPhotoComplete(selectedItem);
      setSelectedPhoto((prev: any) => ({
        ...prev,
        id: selectedItem.id,
        image: selectedItem.image,
        caption: selectedItem.caption,
        is_published: selectedItem.is_published,
        completed_at: selectedItem.completed_at ?? selectedItem.created_at,
      }));
    }
  }, [currPhotoVideoIndex, open]);

  const handleClose = () => {
    setEdit(false);
    setOpen(false);
    setSelectedPhotoVideoIDs([]);
    setEditCaption(false);
    setCaptionField("");
    setCurrPhotoVideoIndex(-1);
    setSelectedPhoto({});
    setSelectedPhotoComplete(null);
  };

  const deleteObservation = async () => {
    const res = deletePortfolioObservation(selectedPhotoComplete?.id);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      handleClose();
      openSnack(translation.deleteSuccess, true);
      setIsRefreshPortfolioObservation(true);
      photoVideoGalleryList[galleryIndex].photo_video_list.splice(
        currPhotoVideoIndex,
        1
      );
    }
  };

  const untagChild = async () => {
    const res = await getLearningMomentImages(
      Number(childID),
      Number(selectedPhotoComplete.learning_moment),
      localStorage.getItem("access")
    );
    if (typeof res != "string") {
      const matchingItem = (res as any).find(
        (item: any) => item.image === selectedPhotoComplete.image
      );
      const untaggedProfileIDs = matchingItem.profile
        .filter((profile: any) => profile.id !== Number(childID))
        .map((profile: any) => profile.id.toString());
      const untagRes = await tagProfilesToImage(
        String(matchingItem.id),
        untaggedProfileIDs
      );
      if (untagRes === "success") {
        openSnack("Untagged Child!", true);
      } else {
        openSnack("Something went wrong!", false);
      }
      handleClose();
      initializeGallery();
      setIsRefreshPortfolioObservation(true);
      photoVideoGalleryList[galleryIndex].photo_video_list.splice(
        currPhotoVideoIndex,
        1
      );
    }
  };

  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState<number>(1);
  const [flipHor, setFlipHor] = useState<boolean>(false);
  const [flipVer, setFlipVer] = useState<boolean>(false);
  const [rotate, setRotate] = useState<number>(0);
  const [aspect, setAspect] = useState<number | undefined>();

  const resetCrop = (width: number, height: number) => {
    setCrop(centerAspectCrop(width, height, width / height));
    setCompletedCrop({
      x: 0,
      y: 0,
      width,
      height,
      unit: "px",
    });
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    resetCrop(width, height);
  };

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) =>
    centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );

  const handleEditImage = () => {
    const selectedPhoto =
      photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex];
    const photoSource = selectedPhoto?.src;
    const filePath = new URL(photoSource).pathname.slice(1);
    const fileExtension = filePath.split(".").pop();
    const fileName = filePath.split("_")[0] + "." + fileExtension;

    fetch(photoSource, {
      headers: { "Cache-Control": "no-cache" },
    })
      .then((res) => res.blob())
      .then((imageBlob) => setPhotoSource(URL.createObjectURL(imageBlob)))

    setPhotoId(selectedPhoto.key!.toString().split("-")[1]);
    setPhotoName(fileName);
  };

  const handleDelete = async () => {
    if (selectedPhotoComplete?.learning_moment) {
      untagChild();
    } else {
      deleteObservation();
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: selectedPhotoComplete
      ? selectedPhotoComplete?.learning_moment
        ? `Untag child from learning moment?`
        : `Delete observation with caption ${selectedPhotoComplete?.caption}?`
      : "Nothing",
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{
          m: "24px",
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: "800px",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: "5px 5px 20px var(--primary-main)",
              p: 4,
              borderRadius: "10px",
              overflow: "auto",
              maxHeight: "100%",
              backgroundColor: "white",
            }}
            onClick={(e) => e.stopPropagation()} // Prevent click propagation
          >
            {currPhotoVideoIndex > -1 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      pl: "16px",
                      gap: 1,
                    }}
                    onClick={() => {
                      setCurrPhotoVideoIndex(-1);
                    }}
                  >
                    {!edit && (
                      <>
                        <ArrowBackIcon sx={{ fontSize: "24px" }} />
                        <Typography
                          sx={{ fontSize: "24px", fontWeight: "600" }}
                        >
                          Edit{" "}
                          {selectedPhotoComplete?.learning_moment !== null
                            ? "Learning Moment"
                            : "Observation"}
                        </Typography>
                      </>
                    )}
                    {edit && (
                      <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                        Edit Image
                      </Typography>
                    )}
                  </Box>
                  {!edit && (
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={selectedPhotoComplete?.is_published}
                            onChange={() => {
                              handleVisibilityChange();
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={"Visible to Parents"}
                        sx={{ marginRight: 0 }}
                      />
                    </Box>
                  )}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {edit ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: "12px",
                        mb: "24px",
                        backgroundColor: "#FBFBFB",
                        height: "400px",
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(pixelCrop) => setCompletedCrop(pixelCrop)}
                        aspect={aspect}
                        ruleOfThirds={true}
                      >
                        <img
                          ref={imgRef}
                          src={photoSource}
                          alt={photoName}
                          style={{
                            transform: `scaleX(${
                              flipHor ? -scale : scale
                            }) scaleY(${
                              flipVer ? -scale : scale
                            }) rotate(${rotate}deg)`,
                            objectFit: "contain",
                            maxHeight: "400px",
                          }}
                          onLoad={onImageLoad}
                          crossOrigin="anonymous"
                        />
                      </ReactCrop>
                    </Box>
                  ) : (
                    <ImageVideoBoxV2
                      open={open}
                      photoVideoGalleryList={photoVideoGalleryList}
                      galleryIndex={galleryIndex}
                      currPhotoVideoIndex={currPhotoVideoIndex}
                      profileDict={profileDict}
                      selectedProfiles={selectedProfiles}
                      editedPhoto={editedPhoto}
                      photoId={photoId}
                      setSelectedProfiles={setSelectedProfiles}
                      setPhotoName={setPhotoName}
                      setPhotoSource={setPhotoSource}
                      setEditedPhoto={setEditedPhoto}
                      setPhotoId={setPhotoId}
                      initializeGallery={initializeGallery}
                      milestone={milestone}
                      edit={edit}
                      setEdit={setEdit}
                    />
                  )}

                  {!edit && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Tooltip title={translation.edit_image}>
                        <IconButton
                          onClick={() => {
                            setEdit(true);
                          }}
                          size="small"
                        >
                          <CropIcon
                            sx={{
                              p: 0.25,
                              color: "#8C8C8C",
                              stroke: "currentcolor",
                              strokeWidth: "0.25px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={`Untag`}>
                        <IconButton
                          onClick={handleOpenDialog}
                          size="small"
                        >
                          {selectedPhotoComplete?.learning_moment !== null ? (
                            <TagIcon
                              sx={{
                                color: "#8C8C8C",
                              }}
                            />
                          ) : (
                            <DeleteIcon
                              sx={{
                                color: "#8C8C8C",
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                {!edit && (
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <TextField
                      value={selectedPhoto?.caption}
                      label="Caption"
                      onChange={(e: any) => {
                        e.preventDefault();
                        setSelectedPhoto((prev: any) => ({
                          ...prev,
                          caption: e.target.value,
                        }));
                      }}
                      sx={{ width: "100%" }}
                    />

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        maxDate={moment(today)}
                        label={"Date"}
                        inputFormat={GENERAL_DATE_FORMAT}
                        value={
                          open
                            ? selectedPhoto?.completed_at
                            : moment(today)
                                .format(GENERAL_DATE_FORMAT)
                                .toString()
                        }
                        onChange={(newValue: moment.Moment | null) => {
                          if (newValue !== null) {
                            setSelectedPhoto((prev: any) => ({
                              ...prev,
                              completed_at: newValue
                                .format(GENERAL_DATE_FORMAT)
                                .toString(),
                            }));
                          } else {
                            setSelectedPhoto((prev: any) => ({
                              ...prev,
                              completed_at: moment(today)
                                .format(GENERAL_DATE_FORMAT)
                                .toString(),
                            }));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ flex: 1, mt: 1 }}
                            fullWidth
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    >
                      <MuiButton
                        sx={{
                          flex: 1,
                          textTransform: "none",
                          backgroundColor: "white",
                          border: "1px solid #5C9391",
                          borderRadius: "4px",
                        }}
                        variant="outlined"
                        onClick={() => {
                          setOpen(false);
                          setPermissionModal(true);
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#5C9391",
                            fontSize: "16px",
                            fontWeight: "450",
                          }}
                        >
                          Create Learning Story!
                        </Typography>
                      </MuiButton>
                      <MuiButton
                        disabled={
                          selectedPhoto?.caption ===
                            selectedPhotoComplete?.caption &&
                          (selectedPhoto?.completed_at ===
                            selectedPhotoComplete?.completed_at ||
                            selectedPhoto?.completed_at ===
                              selectedPhotoComplete?.created_at)
                        }
                        sx={{
                          flex: 1,
                          textTransform: "none",
                          borderRadius: "4px",
                          backgroundColor: "#91C6C5",
                        }}
                        variant="outlined"
                        onClick={async () => {
                          const res = await updatePortfolioObservation({
                            id: selectedPhoto?.id,
                            caption: selectedPhoto?.caption,
                            completed_at: selectedPhoto?.completed_at.slice(
                              0,
                              10
                            ),
                          });
                          if (typeof res != "string") {
                            if (
                              selectedPhotoComplete?.learning_moment !== null
                            ) {
                              openSnack("Learning Moment Updated!", true);
                            } else {
                              openSnack("Observation Updated!", true);
                            }
                          } else {
                            openSnack("Error Updating Observation!", false);
                          }
                          setIsRefreshPortfolioObservation(true);
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "16px",
                            fontWeight: "450",
                          }}
                        >
                          {selectedPhotoComplete?.learning_moment !== null
                            ? "Update Learning Moment"
                            : "Update Observation"}
                        </Typography>
                      </MuiButton>
                    </Box>
                  </Box>
                )}
                {edit && (
                  <DevelopmentalGoalsScreenEditRow
                    imgRef={imgRef}
                    setCrop={setCrop}
                    completedCrop={completedCrop}
                    setCompletedCrop={setCompletedCrop}
                    setPhotoSource={setPhotoSource}
                    photoName={photoName}
                    setFlipHor={setFlipHor}
                    setFlipVer={setFlipVer}
                    handleClose={handleClose}
                    scale={scale}
                    rotate={rotate}
                    flipHor={flipHor}
                    flipVer={flipVer}
                    selectedPhoto={selectedPhoto}
                    initializeGallery={initializeGallery}
                    setEdit={setEdit}
                  />
                )}
              </>
            )}
            <PhotoAndVideoListHeader
              lessonName={currPhotoVideoIndex === -1 ? milestone.name : ""}
              selectedPhotoVideoIDs={selectedPhotoVideoIDs}
              handleClose={handleClose}
              setSelectedPhotoVideoIDs={setSelectedPhotoVideoIDs}
              initializeGallery={initializeGallery}
            />
            <PhotoVideoList
              fullFrame={currPhotoVideoIndex === -1}
              photoVideoGalleryList={photoVideoGalleryList}
              galleryIndex={galleryIndex}
              setCurrPhotoVideoIndex={setCurrPhotoVideoIndex}
              setEditCaption={setEditCaption}
              isLearningMomentPhotoAlbum={true}
              selectedPhotoVideoIDs={selectedPhotoVideoIDs}
              setSelectedPhotoVideoIDs={setSelectedPhotoVideoIDs}
              milestone={milestone}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default DevelopmentalGoalObservationModal;
