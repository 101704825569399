import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { checkIsChineseChar } from "helper/helper";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import VerticalFrame from "images/children-portfolio-v2/littleLearnerFrameVertical.png";
import { textAlign } from "@mui/system";

const templateStyle = StyleSheet.create({
  StorySection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: 36,
    alignItems: "center",
    gap: 36 ,
  },
  ImageFrame: {
    height: 212,
    position: "relative",
    marginTop:20,
  },
  StoryTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent:'flex-start',
    width: 265,
  },
  titleContainer: {
    backgroundColor: "#F9F6F1",
    padding: 24,
  },
  Title: {
    color: "#000",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: 1.4,
    fontStyle: "normal",
  },
  Subtitle: {
    color: "#000",
    textAlign: "center",
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.2,
    letterSpacing: 1.2,
    fontStyle: "normal",
  },
  StoryContainer: {
    padding: 10,
    textAlign:'center',
  },
  Story: {
    color: "#000",
    textAlign: "center",
    fontSize: 10,
    lineHeight: 1.2,
    letterSpacing: '0.8px',
    fontWeight: 300,
    fontStyle: "normal",
  },
});

const PageContent = ({
  learningStories,
}: {
  learningStories: {
    fundamental: string;
    milestone: string;
    image: string;
    story: string;
  }[];
}) => {
  const getFontFamily = (text: string) => {
    return checkIsChineseChar(text) ? "KaiTi" : "FuturaBkBT";
  };

  return (
    <>
      {learningStories.map((story, index) => (
        <View
          key={index}
          style={{
            ...templateStyle.StorySection,
            flexDirection: index % 2 === 0 ? "row" : "row-reverse",
          }}
        >
          <View
            style={{
              ...templateStyle.ImageFrame,
              width: index % 2 === 0 ? "225px" : "167.674px",
              height: index % 2 === 0 ? "180px" : "212px",
            }}
          >
            <Image
              src={{uri:story.image,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
              style={{
                width: index % 2 === 0 ? "80%" : "74%",
                height: index % 2 === 0 ? "64%" : "82%",
                left: index % 2 === 0 ? "23px" : "22px",
                top: index % 2 === 0 ? "15px" : "20px",
              }}
            />
            <Image
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src={index % 2 === 0 ? Frame : VerticalFrame}
            />
          </View>
          <View style={templateStyle.StoryTextContainer}>
            <View style={templateStyle.titleContainer}>
              <Text
                style={{
                  ...templateStyle.Title,
                  fontFamily: getFontFamily(story.fundamental),
                }}
              >
                {story.fundamental}
              </Text>
              <Text
                style={{
                  ...templateStyle.Subtitle,
                  fontFamily:'FuturaLtBT',
                }}
              >
                {story.milestone}
              </Text>
            </View>
            <View style={templateStyle.StoryContainer}>
            <Text
              style={{
                ...templateStyle.Story,
                fontFamily: checkIsChineseChar(story.story)? 'KaiTi' : 'FuturaLtBT',
                fontWeight:300,
              }}
            >
              {story.story}
            </Text>
            </View>
          </View>
        </View>
      ))}
    </>
  );
};

export default PageContent;
