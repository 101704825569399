import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  Tooltip,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { translation } from "constants/translation";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";
import Image from "components/Image/Image";
import Logo from "images/logo-2.png";
import {
  API_Flip_Card,
  API_Image,
  API_Mcq_Question,
  API_Teacher_Text_Activity_Component,
} from "types/teacher";
import {
  JOB_STATUS_DONE_LIST,
  RESOURCE_TYPE_LIST,
} from "../../teacherPageConstants";
import { UpperCaseWordSpacing } from "helper/helper";
import useDialog from "hooks/useDialog";
import {
  deleteFlipCardActivityText,
  deleteMcqQuestion,
  // generateVideoTranscriptViaObjectId,
  generateVideoTranscriptViaUrl,
  // generateVideoTranscriptViaVideoFile,
  getTranscribeJobStatus,
} from "api/teacher-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import WarningDialog from "components/Dialog/WarningDialog";
import { McqQuestionSortableList } from "../TeacherForms/ActivityForms/McqQuestionDnd/McqQuestionSortableList";
import { McqQuestionFieldForDnd } from "./ActivityFormComponents/McqActivityFormComponents";
import { TextActivityComponentSortableList } from "../TeacherForms/ActivityForms/TextActivityComponentDnd/TextActivityComponentSortableList";
import { TextActivityComponentFieldForDnd } from "./ActivityFormComponents/TextActivityFormComponents";
import CustomButton from "components/Button/Button";
import { environment } from 'environment';

export const ActivityTypeField = ({
  activity,
  setActivity,
  isDisabled = false,
}: {
  activity: any;
  setActivity: any;
  isDisabled?: boolean;
}) => (
  <FormControl fullWidth required disabled={isDisabled}>
    <InputLabel id="demo-simple-select-label">
      {translation.activityType}
    </InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={activity.resourcetype}
      label={translation.programme}
      onChange={(event: SelectChangeEvent) => {
        setActivity((prev: any) => ({
          ...prev,
          resourcetype: event.target.value,
        }));
      }}
    >
      {RESOURCE_TYPE_LIST.map((resourcetype) => (
        <MenuItem key={`activity-${resourcetype}`} value={resourcetype}>
          {UpperCaseWordSpacing(resourcetype)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export const NameField = ({
  label = translation.activity_name,
  activity,
  setActivity,
  onChange,
}: {
  label?: string;
  activity: any;
  setActivity: any;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={label}
    value={activity.name}
    onChange={(event) => {
      setActivity((prev: any) => ({ ...prev, name: event.target.value }));
      onChange && onChange(event.target.value);
    }}
    required
  />
);

export const DescriptionFieldNormal = ({
  label = translation.description,
  activity,
  setActivity,
  onChange,
}: {
  label?: string;
  activity: any;
  setActivity: any;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={label}
    value={activity.description}
    onChange={(event) => {
      setActivity((prev: any) => ({
        ...prev,
        description: event.target.value,
      }));
      onChange && onChange(event.target.value);
    }}
    // required
  />
);

export const DescriptionField = ({
  label = translation.description,
  activity,
  setActivity,
  onChange,
}: {
  label?: string;
  activity: any;
  setActivity: any;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>
      {translation.activityDescription}
      {/* <Typography component="span" sx={{ color: "red", ml: "2px" }}>
        *
      </Typography> */}
    </Typography>

    <RichCKEditor
      data={activity.description}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setActivity((prev: any) => ({ ...prev, description: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

export const ActivityInstructionField = ({
  activity,
  setActivity,
  onChange,
}: {
  activity: any;
  setActivity: any;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>{translation.instructions}</Typography>

    <RichCKEditor
      data={activity.instruction}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setActivity((prev: any) => ({ ...prev, instruction: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

export const PublishField = ({
  activity,
  setActivity,
  onChange,
}: {
  activity: any;
  setActivity: any;
  onChange?: (input: boolean) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>{translation.publish}</Typography>

    {activity.is_published ? (
      <CheckBoxIcon
        sx={{ cursor: "pointer", color: "txt.secondary" }}
        onClick={() => {
          setActivity((prev: any) => ({
            ...prev,
            is_published: !prev.is_published,
          }));

          onChange && onChange(false);
        }}
      />
    ) : (
      <CheckBoxOutlineBlankIcon
        sx={{ cursor: "pointer", color: "txt.secondary" }}
        onClick={() => {
          setActivity((prev: any) => ({
            ...prev,
            is_published: !prev.is_published,
          }));

          onChange && onChange(true);
        }}
      />
    )}
  </Box>
);

export const SubtitleWithAddIcon = ({
  subtitle,
  fontSize,
  handleAdd,
}: {
  subtitle: string;
  fontSize?: string;
  handleAdd: () => void;
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Typography sx={{ fontSize }}>{subtitle}</Typography>

      <AddIcon
        sx={{
          backgroundColor: "primary.light",
          borderRadius: "4px",
          p: "2px",
          cursor: "pointer",
          color: "txt.secondary",
          transition: "all 100ms linear",
          "&:hover": {
            backgroundColor: "primary.main",
            transition: "all 100ms linear",
          },
        }}
        onClick={handleAdd}
      />
    </Box>
  );
};

// --- Text Field

// --- MCQ
export const TextActivityComponentField = ({
  activityId = -1,
  textActivityComponents,
  setTextActivityComponents,
}: {
  activityId?: number;
  textActivityComponents: API_Teacher_Text_Activity_Component[];
  setTextActivityComponents: React.Dispatch<
    React.SetStateAction<API_Teacher_Text_Activity_Component[]>
  >;
}) => {
  const handleAdd = () => {
    const updatedTextActivityComponents: API_Teacher_Text_Activity_Component[] =
      [
        ...textActivityComponents,
        {
          id: `unassigned-id-question-${textActivityComponents.length}`,
          text_activity: activityId,
          name: "",
          description: "",
          weight: textActivityComponents.length + 1,
        },
      ];
    setTextActivityComponents(updatedTextActivityComponents);
  };

  return (
    <>
      <Typography sx={{ textAlign: "left", mb: 1 }}>
        {translation.textActivityComponents}*
      </Typography>
      <Box
        sx={{
          border: "2px solid #98C4C5",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <TextActivityComponentSortableList
          items={textActivityComponents}
          setItems={setTextActivityComponents}
          renderItem={(item) => {
            return (
              <TextActivityComponentSortableList.Item id={item.id}>
                <TextActivityComponentFieldForDnd
                  item={item}
                  textActivityComponents={textActivityComponents}
                  setTextActivityComponents={setTextActivityComponents}
                />
                <TextActivityComponentSortableList.DragHandle />
              </TextActivityComponentSortableList.Item>
            );
          }}
        />
        <SubtitleWithAddIcon
          subtitle={
            translation.addTextActivityComponent ||
            "Add Text Activity Component"
          }
          handleAdd={handleAdd}
        />
      </Box>
    </>
  );
};

// export const ImageListField = ({
//   currentTextActivityComponent,
//   textActivityComponents,
//   setTextActivityComponents,
//   multiple = false,
// }: {
//   // imageFiles: File[];
//   // setImageFiles: React.Dispatch<React.SetStateAction<File[]>>;
//   currentTextActivityComponent: API_Teacher_Text_Activity_Component;
//   textActivityComponents: API_Teacher_Text_Activity_Component[];
//   setTextActivityComponents: React.Dispatch<
//     React.SetStateAction<API_Teacher_Text_Activity_Component[]>
//   >;
//   multiple?: boolean;
// }) => {
//   console.log("currentTextActivityComponent: ", currentTextActivityComponent);
//   return (
//     <Box>
//       <Typography sx={{ mb: "8px" }}>{translation.readingImages}</Typography>

//       <input
//         accept="image/*"
//         type="file"
//         id={`image-files-button-${currentTextActivityComponent.id}`}
//         multiple={multiple}
//         hidden
//         onChange={(event) => {
//           const newImages: File[] = Array.prototype.slice.call(
//             event.target.files
//           );
//           const currTextActivityComponentIndex =
//             textActivityComponents.findIndex(
//               (textActivityComponent) =>
//                 textActivityComponent.id === currentTextActivityComponent.id
//             );
//           const updatedTextActivityComponents: API_Teacher_Text_Activity_Component[] =
//             JSON.parse(JSON.stringify(textActivityComponents));
//           console.log(
//             "updatedTextActivityComponents: ",
//             updatedTextActivityComponents
//           );
//           updatedTextActivityComponents[
//             currTextActivityComponentIndex
//           ].new_images = [
//             ...currentTextActivityComponent.new_images,
//             ...newImages,
//           ];
//           setTextActivityComponents(updatedTextActivityComponents);
//         }}
//       />

//       <label htmlFor={`image-files-button-${currentTextActivityComponent.id}`}>
//         <IconButton
//           component="span"
//           sx={{
//             borderRadius: "8px",
//             backgroundColor: "primary.light",
//             "&:hover": {
//               backgroundColor: "primary.main",
//             },
//           }}
//         >
//           <ImageIcon sx={{ color: "txt.secondary" }} />
//         </IconButton>
//       </label>

//       <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: "16px" }}>
//         {currentTextActivityComponent.images &&
//           currentTextActivityComponent.images.map(
//             (image: API_Image, index: number) => (
//               <Fragment
//                 key={`currentTextActivityComponent-image-${image.image}`}
//               >
//                 <IndividualImage
//                   textActivityComponents={textActivityComponents}
//                   setTextActivityComponents={setTextActivityComponents}
//                   image={image}
//                 />
//               </Fragment>
//             )
//           )}

//         {currentTextActivityComponent.new_images.map((file, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               gap: 0.5,
//             }}
//           >
//             <a
//               href={file ? URL.createObjectURL(file) : ""}
//               target="_blank"
//               rel="noreferrer"
//             >
//               {file.name}
//             </a>

//             <ClearIcon
//               sx={{
//                 color: "txt.secondary",
//                 cursor: "pointer",
//                 backgroundColor: "primary.light",
//                 borderRadius: "50%",
//                 p: "3px",
//                 transition: "all 100ms linear",
//                 "&:hover": {
//                   p: "4px",
//                   backgroundColor: "primary.main",
//                   transition: "all 100ms linear",
//                 },
//               }}
//               onClick={() => {
//                 // setImageFiles((prev) => {
//                 //   const temp = [...prev];
//                 //   temp.splice(index, 1);
//                 //   return temp;
//                 // });
//                 const currTextActivityComponentIndex =
//                   textActivityComponents.findIndex(
//                     (textActivityComponent) =>
//                       textActivityComponent.id ===
//                       currentTextActivityComponent.id
//                   );
//                 const updatedTextActivityComponents: API_Teacher_Text_Activity_Component[] =
//                   JSON.parse(JSON.stringify(textActivityComponents));
//                 updatedTextActivityComponents[
//                   currTextActivityComponentIndex
//                 ].new_images = [
//                   ...currentTextActivityComponent.new_images.splice(index, 1),
//                 ];
//                 setTextActivityComponents(updatedTextActivityComponents);
//               }}
//             />
//           </Box>
//         ))}
//       </Box>
//     </Box>
//   );
// };

export const IndividualImage = ({
  textActivityComponents,
  setTextActivityComponents,
  image,
}: {
  textActivityComponents: API_Teacher_Text_Activity_Component[];
  setTextActivityComponents: React.Dispatch<
    React.SetStateAction<API_Teacher_Text_Activity_Component[]>
  >;
  image: API_Image;
}) => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    // const res = await deleteTextActivityImage(image.id);
    // if (typeof res === "string") {
    //   openSnack(res, false);
    // } else {
    //   const filteredImage = activity.images.filter(
    //     (image_: API_Image) => image_.id !== image.id
    //   );
    //   setActivity((prev: any) => ({ ...prev, images: filteredImage }));
    //   openSnack(translation.deletedImage, true);
    // }
    return;
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! "${image.name}" will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Image?",
    warningContext: warningContext,
    handleDelete,
  };
  return (
    <>
      <WarningDialog {...warningBody} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
        }}
      >
        <a href={image.image} target="_blank" rel="noreferrer">
          {image.name}
        </a>

        <ClearIcon
          sx={{
            color: "txt.secondary",
            cursor: "pointer",
            backgroundColor: "primary.light",
            borderRadius: "50%",
            p: "3px",
            transition: "all 100ms linear",
            "&:hover": {
              p: "4px",
              backgroundColor: "primary.main",
              transition: "all 100ms linear",
            },
          }}
          onClick={handleOpenDialog}
        />
      </Box>
    </>
  );
};
// Text Field ---

// Video - Thumbnail ---
export const ImageField = ({
  isRequired = false,
  fieldName = translation.image,
  image,
  setImage,
}: {
  isRequired?: boolean;
  fieldName?: string;
  image: File | string;
  setImage: React.Dispatch<React.SetStateAction<File | string>>;
}) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
    <Typography>
      {fieldName} {isRequired ? "*" : ""}
    </Typography>

    <input
      type="file"
      accept="image/*"
      id="image-button-image"
      hidden
      onChange={(event) => {
        const newImage: File = event.target.files![0];
        setImage(newImage);
      }}
    />

    <label htmlFor="image-button-image">
      <IconButton
        component="span"
        sx={{
          borderRadius: "8px",
          backgroundColor: "primary.light",
          "&:hover": {
            backgroundColor: "primary.main",
          },
        }}
      >
        <ImageIcon sx={{ color: "txt.secondary" }} />
      </IconButton>
    </label>
    {image && (
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 100,
            height: 100,
            borderRadius: "10px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Image
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt={Logo}
          />
          <ClearIcon
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              color: "txt.secondary",
              cursor: "pointer",
              backgroundColor: "primary.light",
              borderRadius: "50%",
              p: "3px",
              transition: "all 100ms linear",
              "&:hover": {
                p: "4px",
                backgroundColor: "primary.main",
                transition: "all 100ms linear",
              },
            }}
            onClick={() => {
              setImage("");
            }}
          />
        </Box>
      </Box>
    )}
  </Box>
);

export const FileField = ({
  file,
  setFile,
  language,
}: {
  file: File | string;
  setFile: React.Dispatch<React.SetStateAction<File | string>>;
  language?: string;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ mb: "8px" }}>{translation.video}</Typography>
        <Typography sx={{ color: "red" }}>*</Typography>
      </Box>

      <input
        type="file"
        accept="video/*"
        id="video-button-files"
        hidden
        onChange={(event) => {
          const newFile: File = event.target.files![0];
          setFile(newFile);
        }}
      />

      <label htmlFor="video-button-files">
        <IconButton
          component="span"
          sx={{
            borderRadius: "8px",
            backgroundColor: "primary.light",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
        >
          <VideoFileIcon sx={{ color: "txt.secondary" }} />
        </IconButton>
      </label>

      {file !== "" && (
        <>
          <Box sx={{ width: 400, height: 250, position: "relative" }}>
            <video
              controls
              controlsList="nodownload"
              width="100%"
              id={typeof file === "string" ? file : file.name}
              height="100%"
              preload="metadata"
              style={{
                objectFit: "cover",
                borderRadius: "10px",
              }}
            >
              <source
                src={
                  typeof file === "string" ? file : URL.createObjectURL(file)
                }
                type="video/mp4"
              />
            </video>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: "16px" }}>
              <ClearIcon
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  color: "txt.secondary",
                  cursor: "pointer",
                  backgroundColor: "primary.light",
                  borderRadius: "50%",
                  p: "3px",
                  transition: "all 100ms linear",
                  "&:hover": {
                    p: "4px",
                    backgroundColor: "primary.main",
                    transition: "all 100ms linear",
                  },
                }}
                onClick={() => {
                  setFile("");
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

// NEXT TODO: REMOVE THE MODEL FROM THE CURRENT SET UP
export const TranscriptField = ({
  video,
  activity,
  setActivity,
  onChange,
}: {
  video: File | string;
  activity: any;
  setActivity: any;
  onChange?: (input: string) => void;
}) => {
  const transcribeJobId = "transcribe_job_id";
  const transcribeJobActivityId = "transcribe_job_activity_id";
  const openSnack = useSnack();

  const [isClick, setIsClick] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string | null>(
    localStorage.getItem(transcribeJobId)
  );
  const [jobStatus, setJobStatus] = useState<string | null>(null);

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  // This is where you poll the task status
  useEffect(() => {
    if (!jobId) {
      return;
    }

    const interval = setInterval(async () => {
      const res = await getTranscribeJobStatus(jobId);

      // If job is done, clear the interval
      if (typeof res !== "string") {
        console.log(`Transcribe status now: ${res.status}`);
        setJobStatus(res.status);
        if (JOB_STATUS_DONE_LIST.includes(res.status)) {
          clearInterval(interval);
          localStorage.removeItem(transcribeJobId);
          localStorage.removeItem(transcribeJobActivityId);
          setJobId(null);
          setJobStatus(null);
        }
      }
    }, 10000); // Check every 5 seconds

    return () => clearInterval(interval);
  }, [jobId]);

  // const handleCheckJobStatus = async () => {
  //   const res = await getTranscribeJobStatus(jobId!);
  //   console.log("RES STATUS: ", res);
  // };

  // Activity don't have video, it's a separate field. pass in another parameters that is the video to test further
  const handleGenerateTranscript = async () => {
    setIsClick(true);
    if (video === "") {
      openSnack("No video file to generate transcripts!", false);
      setIsClick(false);
      return;
    }
    let populateTranscriptRes;
    // All three have roughly same speed
    if (typeof video === "string" && video !== "") {
      // populateTranscriptRes = await generateVideoTranscriptViaObjectId(
      //   currLanguage,
      //   activity.id
      // );
      populateTranscriptRes = await generateVideoTranscriptViaUrl(
        currLanguage,
        video,
        `${environment.backendUrl}/${currLanguage}/teacher/activity/${activity.id}/update`,
        localStorage.getItem("access")!
      );
      // console.log("populateTranscriptRes: ", populateTranscriptRes);
      if (typeof populateTranscriptRes !== "string") {
        const jobIdTemp = populateTranscriptRes.job_id
          ? populateTranscriptRes.job_id
          : null;
        if (jobIdTemp) {
          setJobId(jobIdTemp);
          localStorage.setItem(transcribeJobId, jobIdTemp);
          localStorage.setItem(transcribeJobActivityId, activity.id.toString());
        }
      }
    }
    // else if (typeof video === "object") {
    //   populateTranscriptRes = await generateVideoTranscriptViaVideoFile(
    //     currLanguage,
    //     video
    //   );
    // }
    setIsClick(false);
    // setActivity({ ...activity, transcript: populateTranscriptRes });
  };
  const isJobDone = JOB_STATUS_DONE_LIST.includes(jobStatus!);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Typography sx={{ mb: "8px" }}>
          {translation.videoTranscript}
        </Typography>
        {activity.id && (
          <Tooltip
            title={
              typeof video === "object" || video === ""
                ? "Upload and update the video before transcribing!"
                : (!!jobId &&
                    !isJobDone &&
                    localStorage.getItem(transcribeJobActivityId) ===
                      activity.id.toString()) ||
                  isClick
                ? "The existing transcription will be overwritten in the backend once the process is done. You can close the window/continue updating the content."
                : ""
            }
          >
            <span>
              <CustomButton
                buttonText={
                  !!jobId && !isJobDone
                    ? `${
                        localStorage.getItem(transcribeJobActivityId) ===
                        activity.id.toString()
                          ? "Transcribe is in progress, please wait for a few mins...  "
                          : "Transcribing another video..."
                      }`
                    : "Auto Populate Video Transcript"
                }
                arrow={false}
                onClick={handleGenerateTranscript}
                isLoading={
                  (!!jobId &&
                    !isJobDone &&
                    localStorage.getItem(transcribeJobActivityId) ===
                      activity.id.toString()) ||
                  isClick
                }
                disabled={
                  (!!jobId && !isJobDone) ||
                  typeof video === "object" ||
                  video === "" ||
                  isClick
                }
                style={{
                  opacity:
                    (!!jobId && !isJobDone) ||
                    typeof video === "object" ||
                    video === ""
                      ? 0.5
                      : 1,
                }}
              />
            </span>
          </Tooltip>
        )}

        {/* <CustomButton
          buttonText={"Check Job Status"}
          arrow={false}
          onClick={handleCheckJobStatus}
        /> */}
      </Box>

      <RichCKEditor
        data={activity.transcript}
        onChange={(event: any, editor: any) => {
          const data: string = editor.getData();

          setActivity((prev: any) => ({ ...prev, transcript: data }));
          onChange && onChange(data);
        }}
      />
    </Box>
  );
};

// --- Video - Thumbnail

// --- Flipcards
export const FlipCardField = ({
  activityId = -1,
  flipCards,
  setFlipCards,
}: {
  activityId?: number;
  flipCards: API_Flip_Card[];
  setFlipCards: React.Dispatch<React.SetStateAction<API_Flip_Card[]>>;
}) => {
  const handleAdd = () => {
    const updatedFlipCards: API_Flip_Card[] = [
      ...flipCards,
      {
        id: `unassigned-id-${flipCards.length}`,
        flip_card_activity: activityId,
        front_text: "",
        back_text: "",
      },
    ];
    setFlipCards(updatedFlipCards);
    // onChange && onChange();
  };

  return (
    <>
      <Typography sx={{ textAlign: "left", mb: 1 }}>
        {translation.flipCards} *
      </Typography>
      <Box
        sx={{
          border: "2px solid #98C4C5",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {flipCards.map((flipCard, index) => {
          return (
            <Fragment key={`flip-card-${index}`}>
              <IndividualFlipCard
                index={index}
                flipCards={flipCards}
                setFlipCards={setFlipCards}
                flipCard={flipCard}
              />
            </Fragment>
          );
        })}
        <SubtitleWithAddIcon
          subtitle={translation.addFlipCard || "Add Flip Card"}
          handleAdd={handleAdd}
        />
      </Box>
    </>
  );
};

export const IndividualFlipCard = ({
  index,
  flipCards,
  setFlipCards,
  flipCard,
}: {
  index: number;
  flipCards: API_Flip_Card[];
  setFlipCards: React.Dispatch<React.SetStateAction<API_Flip_Card[]>>;
  flipCard: API_Flip_Card;
}) => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteFlipCardActivityText(Number(flipCard.id));
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      const filteredFlipCards = flipCards.filter(
        (flipCard_) => flipCard.id !== flipCard_.id
      );
      setFlipCards(filteredFlipCards);
      openSnack(translation.flipCardRemoved || "Flip Card Removed", true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! "${translation.formatString(
          translation.flipCardNo || "Flip Card {number}",
          { number: index + 1 }
        )}" will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Flip Card?",
    warningContext: warningContext,
    handleDelete,
  };
  return (
    <>
      <WarningDialog {...warningBody} />
      <Typography sx={{ textAlign: "left", mt: index === 0 ? 0 : 1, mb: 1 }}>
        {translation.formatString(
          translation.flipCardNo || "Flip Card {number}",
          {
            number: index + 1,
          }
        )}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <TextField
            fullWidth
            label={translation.flipCardFrontText}
            value={flipCard.front_text}
            onChange={(event) => {
              const flipCardsCopy: API_Flip_Card[] = JSON.parse(
                JSON.stringify(flipCards)
              );
              flipCardsCopy[index].front_text = event.target.value;
              setFlipCards(flipCardsCopy);
            }}
            required
          />
          <TextField
            fullWidth
            label={translation.flipCardBackText}
            value={flipCard.back_text}
            onChange={(event) => {
              const flipCardsCopy: API_Flip_Card[] = JSON.parse(
                JSON.stringify(flipCards)
              );
              flipCardsCopy[index].back_text = event.target.value;
              setFlipCards(flipCardsCopy);
            }}
            required
          />
        </Box>
        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (
              typeof flipCard.id === "string" &&
              flipCard.id.includes("unassigned")
            ) {
              const filteredFlipCards = flipCards.filter(
                (flipCard_) => flipCard.id !== flipCard_.id
              );
              setFlipCards(filteredFlipCards);
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Box>
    </>
  );
};
// Flipcards ---

// --- MCQ
export const McqField = ({
  activityId = -1,
  mcqQuestions,
  setMcqQuestions,
}: {
  activityId?: number;
  mcqQuestions: API_Mcq_Question[];
  setMcqQuestions: React.Dispatch<React.SetStateAction<API_Mcq_Question[]>>;
}) => {
  const handleAdd = () => {
    const updatedMcqQuestions: API_Mcq_Question[] = [
      ...mcqQuestions,
      {
        id: `unassigned-id-question-${mcqQuestions.length}`,
        mcq_activity: activityId,
        name: "",
        weight: mcqQuestions.length + 1,
        mcq_options: [],
      },
    ];
    setMcqQuestions(updatedMcqQuestions);
  };

  return (
    <>
      <Typography sx={{ textAlign: "left", mb: 1 }}>
        {translation.mcqQuestions} *
      </Typography>
      <Box
        sx={{
          border: "2px solid #98C4C5",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <McqQuestionSortableList
          items={mcqQuestions}
          setItems={setMcqQuestions}
          renderItem={(item) => {
            return (
              <McqQuestionSortableList.Item id={item.id}>
                <McqQuestionFieldForDnd
                  item={item}
                  mcqQuestions={mcqQuestions}
                  setMcqQuestions={setMcqQuestions}
                />
                <McqQuestionSortableList.DragHandle />
              </McqQuestionSortableList.Item>
            );
          }}
        />

        {/* {mcqQuestions.map((mcqQuestion, index) => {
          return (
            <Fragment key={`mcq-question-${index}`}>
              <IndividualMcqQuestion
                index={index}
                mcqQuestions={mcqQuestions}
                setMcqQuestions={setMcqQuestions}
                mcqQuestion={mcqQuestion}
              />
            </Fragment>
          );
        })} */}
        <SubtitleWithAddIcon
          subtitle={translation.addMcqQuestion || "Add MCQ Question"}
          handleAdd={handleAdd}
        />
      </Box>
    </>
  );
};

export const IndividualMcqQuestion = ({
  index,
  mcqQuestions,
  setMcqQuestions,
  mcqQuestion,
}: {
  index: number;
  mcqQuestions: API_Mcq_Question[];
  setMcqQuestions: React.Dispatch<React.SetStateAction<API_Mcq_Question[]>>;
  mcqQuestion: API_Mcq_Question;
}) => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteMcqQuestion(Number(mcqQuestion.id));
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      const filteredMcqQuestion = mcqQuestions.filter(
        (mcqQuestion_) => mcqQuestion.id !== mcqQuestion_.id
      );
      setMcqQuestions(filteredMcqQuestion);
      openSnack(translation.mcqQuestionRemoved || "MCQ Question Removed", true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! "${translation.formatString(
          translation.mcqQuestionNo || "MCQ Question {number}",
          { number: index + 1 }
        )}" will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete MCQ Question?",
    warningContext: warningContext,
    handleDelete,
  };
  return (
    <>
      <WarningDialog {...warningBody} />
      <Typography sx={{ textAlign: "left", mt: index === 0 ? 0 : 1, mb: 1 }}>
        {translation.formatString(
          translation.mcqQuestionNo || "MCQ Question {number}",
          {
            number: index + 1,
          }
        )}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <TextField
            fullWidth
            label={translation.activity_name}
            value={mcqQuestion.name}
            onChange={(event) => {
              const mcqQuestionsCopy: API_Mcq_Question[] = JSON.parse(
                JSON.stringify(mcqQuestions)
              );
              mcqQuestionsCopy[index].name = event.target.value;
              setMcqQuestions(mcqQuestionsCopy);
            }}
            required
          />
        </Box>
        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (
              typeof mcqQuestion.id === "string" &&
              mcqQuestion.id.includes("unassigned")
            ) {
              const filteredMcqQuestions = mcqQuestions.filter(
                (mcqQuestion_) => mcqQuestion.id !== mcqQuestion_.id
              );
              setMcqQuestions(filteredMcqQuestions);
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Box>
    </>
  );
};
// MCQ ---
