import { styled } from "@mui/system";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "24px",
});

export const AddLearningStoryButton = styled(Button)({
  borderRadius: "4px",
  backgroundColor: "var(--Cyan-500, #91C6C5)",
  padding: "8px 22px",
});

export const AddIconStyled = styled(AddIcon)({
  color: "#FFF",
  width: "20px",
  height: "20px",
});

export const ButtonText = styled(Typography)({
  color: "var(--Neutral-50, #FFF)",
  fontFamily: "Futura PT",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 450,
  lineHeight: "24px",
});

export const Date = styled(Typography)({
  position: "absolute",
  top: 8,
  left: 8,
  color: "var(--Neutral-900, #323031)",
  fontFamily: "Futura PT",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "15px",
  textAlign: "center",
  borderRadius: "6px",
  backgroundColor: "var(--Color, #FFF)",
  padding: "5px 10px",
});

export const Story = styled(Typography)({
  color: "var(--Neutral-900, #323031)",
  fontFamily: "Futura PT",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22px",
});

export const DateHeading = styled(Typography)({
  color: "var(--Neutral-900, #323031)",
  fontFamily: "Futura PT",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px",
});
