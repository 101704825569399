import React from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { IconButton, Link } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import useStore from "store/store";
import Rating from "components/Rating";
import { isProfileInTTAModuleEditableRoleList } from "helper/helper";
import Logo from "images/logo-2.png";
import {
  CURLANG,
  EDIT_TEACHER_MODULE_PAGE,
  TEACHER_LESSON_ACTIVITY_PAGE,
  TEACHER_MODULE_PAGE,
} from "constants/url";
import { translation } from "constants/translation";
import {
  API_Teacher_Module,
  API_Teacher_Module_Learner_Progress,
} from "types/teacher";

import AddToFavsButton from "./AddToFavs";
import LinearProgressWithLabel from "./LinearProgress";
import { MODULE_CARD_WIDTH } from "../../teacherPageConstants";

type Props = {
  module: API_Teacher_Module;
  progress: API_Teacher_Module_Learner_Progress;
  tag?: "New" | "Popular";
};

const TeacherDetailCard = ({ module, progress, tag }: Props) => {
  const navigate = useNavigate();
  const { currLanguage, profileDetails } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
  }));

  const navigateToModule = (module: API_Teacher_Module) => {
    if (module.slug && module.lessons[0] && module.lessons[0].activities[0]) {
      return `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
        module.slug,
        module.lessons[0].slug,
        module.lessons[0].activities[0].slug
      )}`;
    }

    if (module.slug) {
      return `${CURLANG(currLanguage)}/${TEACHER_MODULE_PAGE(module.slug)}`;
    }

    return null;
  };

  const handleStartLearning = () => {
    const path = navigateToModule(module);
    if (path) {
      navigate(path);
    }
  };

  return (
    <Card
      onClick={progress?.is_enrolled ? handleStartLearning : undefined}
      sx={{
        minWidth: MODULE_CARD_WIDTH,
        maxWidth: MODULE_CARD_WIDTH,
        borderRadius: "8px",
        backgroundColor: "#F2F2F2",
        cursor: "pointer",
        transition: "200ms all ease-in-out",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "12px",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
          height: "180px",
          backgroundColor: "primary.light",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${module.image ? module.image : Logo})`,
        }}
      >
        {!!tag && (
          <Typography
            sx={{
              backgroundColor: tag === "New" ? "#FCD1CA" : "#ECEA5C",
              color: "#323031",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 8px",
              borderRadius: "4px",
              m: 0,
            }}
          >
            {tag}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "0 12px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: 1.3,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {module.name}
          </Typography>
          {isProfileInTTAModuleEditableRoleList(profileDetails) && (
            <IconButton
              sx={{
                padding: "0 4px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `${CURLANG(currLanguage)}/${EDIT_TEACHER_MODULE_PAGE(
                    module.slug
                  )}`
                );
              }}
            >
              <EditIcon
                sx={{
                  cursor: "pointer",
                  color: "txt.secondary",
                  transition: "all 100ms ease-in-out",
                  "&:hover": {
                    color: "primary.main",
                    transition: "all 100ms ease-in-out",
                  },
                }}
              />
            </IconButton>
          )}
        </Box>
        {!progress?.is_enrolled && <Rating rating={module.avg_rating} />}
        <Typography
          sx={{
            color: "txt.dark2",
            fontSize: "1rem",
            width: "100%",
            height: "71px",
            maxHeight: 71,
            overflowY: "hidden",
            textOverflow: "ellipsis",
            lineHeight: 1.5,
          }}
        >
          {module.description && (
            <div dangerouslySetInnerHTML={{ __html: module.description }} />
          )}
        </Typography>
        {progress?.is_enrolled && (
          <LinearProgressWithLabel
            sx={{ width: "inherit", height: 8 }}
            variant="determinate"
            value={progress ? progress.progress_percentage : 0}
            minutesLeft={progress ? progress.no_of_minutes_left : 0}
          />
        )}

        {!progress?.is_enrolled && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                backgroundColor: "#91C6C5",
                borderRadius: "4px",
              }}
            >
              <Link
                component="button"
                variant="body2"
                onClick={handleStartLearning}
                sx={{
                  height: "36px",
                  textAlign: "left",
                  color: "#FFFFFF",
                  fontWeight: "450",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
              >
                {translation.startLearning}
              </Link>
            </Box>
            <AddToFavsButton module={module} profile={profileDetails.id} />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default TeacherDetailCard;
