import { useEffect, useState } from "react";

import useStore from "store/store";

import { getGalleryByProject } from "api/gallery-api";
import { Image } from "react-grid-gallery";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { GalleryImage } from "components/ProjectSummaryScreen/ProjectSummaryScreen";
import Preview from "components/ProjectReportPDF/Preview/Preview";

import { Box } from "@mui/material";

const ProjectReportScreen = () => {
  const [gallery, setGallery] = useState<GalleryImage[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const { projID, lessons, currGroup, currLanguage } = useStore((state) => ({
    projID: state.currentProject.id,
    lessons: state.currentLessons.lessons,
    currGroup: state.currGroup,
    currLanguage: state.currLanguage,
  }));

  useEffect(() => {
    if (projID === -1 || currGroup.id === -1) return;

    const initialiseGallery = async () => {
      setIsLoaded(false);

      const data = await getGalleryByProject(
        currLanguage,
        projID.toString(),
        localStorage.getItem("access"),
        localStorage.getItem("curr_group_id")
      );

      if (typeof data !== "string") {
        const newImagesList: GalleryImage[] = [];
        let index = 0;
        const lessonList: string[] = [];
        let lessonName = "";

        data
          .filter((d) =>
            d.lesson === undefined || d.lesson === "" || d.activity === null
              ? false
              : true
          )
          .forEach((d) => {
            lessonName = d.lesson!;
            index = lessonList.indexOf(lessonName);

            if (index === -1) {
              lessonList.push(lessonName);
              newImagesList.push({ lesson: lessonName, images: [] });
              index = newImagesList.length - 1;
            }

            d.images.forEach((img) => {
              const imageData: Image = {
                key: img.id,
                src: img.image,
                width: img.width,
                height: img.height,
                alt: img.caption,
              };

              newImagesList[index].images.unshift(imageData);
            });
          });

        setGallery(newImagesList);
        setIsLoaded(true);
      }
    };

    initialiseGallery();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projID, currLanguage, currGroup]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        py: "30px",
        position: "relative",
      }}
    >
      {isLoaded && lessons.length !== 0 ? (
        <Preview gallery={gallery} />
      ) : (
        <LoadingIndicator />
      )}
    </Box>
  );
};

export default ProjectReportScreen;
