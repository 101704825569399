import { Text, View, Image, Font } from "@react-pdf/renderer";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";

import { images } from "../LessonSelectionAssets/DevelopmentalGoalImages";
import { checkIsChineseChar } from "helper/helper";

import { API_Project_Module } from "types/project";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";

import time from "../LessonSelectionAssets/time.png";

import { removeHtmlTagsAndStyles } from "./utils";
import { styles } from "./styles";

interface PdfComponentProps {
  projModule: API_Project_Module;
  currentProject: any;
}

const LessonHeader = ({ projModule, currentProject }: PdfComponentProps) => {
  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  };

  Font.registerHyphenationCallback(hyphenationCallback);


  let moduleImage = "";
  moduleImage = projModule.name.split(" ").join("");

  let ageIcon: string | undefined = BlossomsIcon;
  let ageText = "(4 years - 5 years)";
  if (currentProject.age === "Buds") {
    ageIcon = BudsIcon;
    ageText = "( 3 years - 4 years )";
  } else if (currentProject.age === "Sprouts") {
    ageIcon = SproutsIcon;
    ageText = "( 18 months - 2 years )";
  }

  if (currentProject.assessment) {
    ageIcon = undefined;
    ageText = "All";
  }

  return (
    <>
      {currentProject.module && (
        <View style={styles.regularSection}>
          {currentProject.name && (
            <Text style={{ ...styles.titleSectionText, ...(checkIsChineseChar(currentProject.name[0]) ? styles.chineseText : styles.englishTextTitle) }}>
              {removeHtmlTagsAndStyles(currentProject.name)}
            </Text>
          )}
        </View>
      )}
      <View style={styles.moduleContainer}>
        <View style={styles.moduleBox}>
          <Image
            src={
              moduleImage && images[moduleImage] ? (
                images[moduleImage]
              ) : (
                <AccessibilityNewIcon />
              )
            }
            style={{ height: 11, marginRight: 2 }}
          />

          <View style={styles.moduleText}>
            <Text style={{ ...(checkIsChineseChar(currentProject.module.name[0]) ? styles.chineseText : styles.englishTextSection) }}>
              {removeHtmlTagsAndStyles(currentProject.module.name)}
            </Text>
          </View>
        </View>
        <View style={styles.moduleContainer}>
          <Image src={time} style={{ width: 10, height: 10, marginRight: 1 }} />
          <Text style={styles.moduleText}>
            {currentProject.duration}{" "}
            {currentProject.assessment ? "assessment plan" : "lesson"}s
          </Text>
        </View>
        <View
          style={{
            marginLeft: "5px",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: "10px", marginRight: "5px" }}>|</Text>
          {ageIcon && (
            <Image
              src={ageIcon}
              style={{ width: 8, height: 10, marginRight: 1 }}
            />
          )}
          <Text style={{ fontSize: "10px" }}> {currentProject.age}</Text>
          <Text style={{ fontSize: "10px" }}> {ageText}</Text>
        </View>
      </View>
    </>
  );
};

export default LessonHeader;
