import { AxiosError } from "axios";
import {
  API_Create_Project_Fundamental,
  API_Project_Fundamental_Legacy,
  API_Update_Objective_Response,
  API_Update_Project_Fundamental,
  API_Fundamental,
  API_Fundamental_Create,
  API_Milestone,
  API_Milestone_File,
  API_Fundamental_SHORT,
} from "types/project";
import axios from "api/axiosConfig";

//Fundamental
export const getAllFundamentals = async (
  language: string,
  developmentalGoalTag?: string,
  childId?: number
) => {
  let path = language + "/tms/all-fundamental-full-detail/list/v2";
  if (developmentalGoalTag && childId) {
    path += `?developmental_goal_tag=${developmentalGoalTag}&child_id=${childId}`;
  } else if (childId) {
    path += `?child_id=${childId}`;
  }
  const res = await axios.get(path);
  const data: API_Fundamental[] = await res.data;
  return data;
};

export const getAllFundamentalsShort = async (
  language: string,
) => {
  const path = language + "/tms/all-fundamental/list";

  const res = await axios.get(path);
  const data: API_Fundamental_SHORT[] = await res.data;
  return data;
};

export const getFundamental = async (
  fundamentalId: number,
  language: string
) => {
  const res = await axios.get(
    language + `/tms/fundamental/${fundamentalId}/get`
  );
  const data: API_Fundamental = await res.data;
  return data;
};

export const createFundamental = async (
  fundamentalData: API_Fundamental_Create,
  language: string
) => {
  try {
    const res = await axios.post(
      language + `/tms/fundamental/create`,
      fundamentalData
    );
    const data: API_Fundamental = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editFundamental = async (
  fundamentalId: number,
  fundamentalData: API_Fundamental_Create,
  language: string
) => {
  try {
    const res = await axios.patch(
      language + `/tms/fundamental/${fundamentalId}/update`,
      fundamentalData
    );
    const data: API_Fundamental = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editFundamentalImage = async (
  fundamentalId: number,
  image: File | string,
  language: string
) => {
  try {
    const res = await axios.patch(
      language + `/tms/fundamental/${fundamentalId}/update`,
      { image: image },
      { headers: { "content-type": "multipart/form-data" } }
    );
    const data: API_Fundamental = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteFundamental = async (
  fundamentalId: number,
  language: string
) => {
  try {
    const res = await axios.delete(
      language + `/tms/fundamental/${fundamentalId}/delete`
    );
    const data: API_Fundamental = res.data;
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const addMilestoneFile = async (
  milestoneId: number,
  files: File[],
  language: string
) => {
  try {
    const res = await axios.post(
      language + `/tms/fundamental/milestone-file/${milestoneId}/add-files`,
      { files: files },
      { headers: { "content-type": "multipart/form-data" } }
    );
    const data: API_Milestone_File[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteMilestone = async (
  milestoneId: number,
  language: string
) => {
  try {
    const res = await axios.delete(
      language + `/tms/fundamental/milestone/${milestoneId}/delete`
    );
    const data: API_Milestone[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteMilestoneFile = async (
  milestoneFileId: number,
  language: string
) => {
  try {
    const res = await axios.delete(
      language + `/tms/fundamental/milestone-file/${milestoneFileId}/delete`
    );
    const data: any = res.data;
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getAllFundamentalsbyProject = async (
  language: string,
  projectID: string
) => {
  const res = await axios.get(language + `/tms/objective/project/${projectID}`);
  const data: API_Project_Fundamental_Legacy[] = await res.data;
  return data;
};

export const editProjectFundamental = async (
  language: string,
  objectiveID: string,
  objectiveData: API_Update_Project_Fundamental
) => {
  try {
    const res = await axios.patch(
      language + `/tms/objective/${objectiveID}/update`,
      objectiveData
    );
    const data: API_Update_Objective_Response = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createObjective = async (
  language: string,
  objectiveData: API_Create_Project_Fundamental
) => {
  try {
    const res = await axios.post(
      language + "/tms/objective/create",
      objectiveData
    );
    const data: API_Update_Objective_Response = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteObjective = async (objectiveID: string) => {
  try {
    await axios.delete(`/en/tms/objective/${objectiveID}/delete`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};
