import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { API_Fundamental } from "types/project";
import { API_Child } from "types/portfolio";
import MileStoneSection from "./MilestoneSection";
import IndividualObservation from "./IndividualObservation";
import Title from "./Title";
import { translation } from "constants/translation";
import { checkIsChineseChar } from "helper/helper";
const templateStyles = StyleSheet.create({
  text: {
    fontWeight: 400,
    lineHeight: 1.25,
    fontSize: 12,
    flexShrink: 0,
    color: "#5C9391",
    marginTop: 12,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: 12,
    alignItems: "flex-start",
    marginBottom: "20px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  observationContainer: {
    marginLeft: "auto",
    flexShrink: 0,
    width: 160,
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  placeholderContainer: {
    width: 120,
    height: 150,
    top: -10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

interface DevelopmentalGoalProps {
  devGoal: API_Fundamental & { next?: boolean };
  previousDevGoal?: API_Fundamental;
  observationList?: API_Child.PortfolioObservation[];
  developmentalGoals: API_Fundamental[];
}

const DevelopmentalGoal = ({
  devGoal,
  previousDevGoal,
  observationList,
  developmentalGoals,
}: DevelopmentalGoalProps) => {
  const showTitle = devGoal && devGoal.id !== previousDevGoal?.id;
  const showCompletedMileStone = devGoal.milestones?.length > 0;
  const showNextMileStone = devGoal?.next_milestone && devGoal.next !== true;

  return (
    <View style={templateStyles.container}>
      <View style={templateStyles.column}>
        <View style={templateStyles.row}>
          <View style={{ flex: 1 }}>
            {showTitle ? (
              <>
                <Title
                  image={devGoal.image}
                  name={devGoal.name}
                  styles={{
                    marginBottom: 6,
                  }}
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {showCompletedMileStone && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Text
                        style={[
                          templateStyles.text,
                          {
                            fontFamily: checkIsChineseChar(
                              translation.milestoneCompleted
                            )
                              ? "KaiTi"
                              : "FuturaMediumBT",
                          },
                        ]}
                      >
                        {translation.milestoneCompleted}
                      </Text>

                      {devGoal.milestones.map((milestone: any) => (
                        <MileStoneSection
                          key={milestone.id}
                          descriptions={milestone.name}
                        />
                      ))}
                    </View>
                  )}
                  {/* {showNextMileStone && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                      }}
                    >
                      <Text
                        style={[
                          templateStyles.text,
                          {
                            fontFamily: checkIsChineseChar(
                              translation.milestoneCompleted
                            )
                              ? "KaiTi"
                              : "FuturaMediumBT",
                          },
                        ]}
                      >
                        {translation.nextMilestone}
                      </Text>
                      <MileStoneSection
                        descriptions={
                          devGoal?.next_milestone &&
                          typeof devGoal?.next_milestone !== "number"
                            ? devGoal?.next_milestone.name
                            : translation.milestoneCompletedCongratulation
                        }
                      />
                    </View>
                  )} */}
                </View>
              </>
            ) : (
              <>
                <Title
                  image={devGoal?.image}
                  name={devGoal?.name}
                  styles={{
                    marginBottom: 6,
                  }}
                />
                {showCompletedMileStone && (
                  <>
                    <Text
                      style={[
                        templateStyles.text,
                        {
                          fontFamily: checkIsChineseChar(
                            translation.milestoneCompleted
                          )
                            ? "KaiTi"
                            : "FuturaMediumBT",
                        },
                      ]}
                    >
                      {translation.milestoneCompleted}
                    </Text>
                    {devGoal.milestones.map((milestone: any) => (
                      <MileStoneSection
                        key={milestone.id}
                        descriptions={milestone.name}
                      />
                    ))}
                  </>
                )}
                {/* {showNextMileStone && (
                  <>
                    <Text style={templateStyles.text}>
                      {translation.nextMilestone}
                    </Text>
                    <MileStoneSection
                      descriptions={
                        devGoal?.next_milestone &&
                        typeof devGoal?.next_milestone !== "number"
                          ? devGoal?.next_milestone.name
                          : translation.milestoneCompletedCongratulation
                      }
                    />
                  </>
                )} */}
              </>
            )}
          </View>
          <View style={templateStyles.observationContainer}>
            {observationList && observationList.length > 0 ? (
              <IndividualObservation observation={observationList[0]} />
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};

export default DevelopmentalGoal;
