import { useState, useEffect } from "react";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import image from 'images/placeholder/alice.jpg';
import {
  Backdrop,
  Button as MuiButton,
  Box,
  Fade,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useDialog from "hooks/useDialog";
import {
  deleteChildProgressReport,
  getSingleChildProgressList,
} from "api/report-api";
import {fetchLearningStories} from "api/learning-stories-api";
import { CHILDREN_PORTFOLIO_PAGE, CURLANG } from "constants/url";

import { slugify } from "helper/slugify";
import { PROGRESS_REPORT } from "containers/ChildrenPortfolioPage/constants";
import { getProjectById, getProjectCategories } from "api/project-api";

import { usePortfolioContext } from "../ChildrenPortfolioPage";
import Preview from "components/ChildrenPortfolioPDF/Preview/Preview";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  PDFInfoType,
  MONTH_FORMAT,
  ImageType,
} from "components/ChildrenPortfolioPDF/Preview/constant";
import { translation } from "constants/translation";

import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useStore from "store/store";
import {
  getAllChildPortfolioObservation,
  getAllChildAchievedMilestoneTracker,
  getChildCompletedProject,
  getChildGroupPortfolioInfo,
  getProfileImages,
} from "api/portfolio-api";
import useSnack from "hooks/useSnack";
import Button, { DeleteButton } from "components/Button/Button";
import DownloadPDFButton from "components/ChildrenPortfolioPDF/DownloadPDFButton";
import { useNavigate, useParams } from "react-router-dom";
import { API_Child } from "types/profile";
import { getAllFundamentals } from "api/fundamental-api";
import { formatDateToYYYYMMDD } from "helper/helper";
import { getCompletedWorkList } from "api/completed-works-api";
import { getFileTypeFromExtention } from "./CompletedWorkComponents/CompletedWorkCard";
import { getScheduleByGroup } from "api/profile-api";
import SaveDraftModal from "./ChildProgressReportComponents/SaveDraftModal";
import ChoiceDialog from "components/Dialog/ChoiceDialog";
import EditOrSaveDraftButton from "./ChildProgressReportComponents/EditOrSaveDraftButton";

const CreateChildrenPortfolioPage = () => {
  const { currLanguage, currGroup } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currGroup: state.currGroup,
  }));
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { childDetails } = usePortfolioContext();
  const { groupSlug, childID, fileId } = useParams();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [totalPages, setTotalPages] = useState<number>(0);

  const [startAt, setStartAt] = useState<Date | null>(
    new Date(childDetails.create_at!)
  );
  const [endAt, setEndAt] = useState<Date | null>(new Date());
  const [draftPDFInfo, setDraftPDFInfo] = useState<any>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(true);
  const [projectCategories, setProjectCategories] = useState<any[]>([]);
  const [saveDraftModal, setSaveDraftModal] = useState<boolean>(false);
  const [saveDraftModalText, setSaveDraftModalText] = useState<string>("");
  const [learningStories, setLearningStories] = useState<any[]>([]);

  const [PDFInfo, setPDFInfo] = useState<PDFInfoType>({
    profileName: translation.formatString(translation.names, {
      name: childDetails.preferred_name,
    }) as string,
    profileImage: childDetails.image,
    introductionText: translation.introductionText,
    startMonth: moment(startAt).format(MONTH_FORMAT),
    endMonth: moment(endAt).format(MONTH_FORMAT),
    completedLittleProjectList: [],
    completedLittleLearnerList: [],
    concludingRemarkText: ``,
    concludingRemarkImage: childDetails.image,
    currentGroup: {
      id: -1,
      name: "",
      image: "",
      created_at: "",
      group_teacher_list: [],
    },
    childImages: [],
    photoGalleryTemplateNumber: 0,
    developmentalGoalList: [],
    observationList: [],
    completedWorkImages: [],
    completedWorkTemplateNumber: 0,
    categories: [],
    childAllAchievedMilestoneTrackers: [],
    learningStories: [],
  });

  const populateProjectCategories = async () => {
    const data: any[] = await getProjectCategories(currLanguage);
    const filteredData = data.filter((cat) => !cat.assessment);
    setProjectCategories(filteredData);
  };

  useEffect(() => {
    if (fileId) {
      const getInfo = async () => {
        const singleWork = await getSingleChildProgressList({
          language: currLanguage,
          progressReportId: Number(fileId),
        });
        const draftPDFInfo = JSON.parse(singleWork.PDFInfo);
        setSaveDraftModalText(singleWork.name);
        setDraftPDFInfo(draftPDFInfo);
        setPDFInfo(draftPDFInfo);
      };
      getInfo();
      populateProfileImages();
    }
  }, []);

  const [childImageList, setChildImageList] = useState<ImageType[]>([]);
  const [completedWorkImageList, setCompletedWorkImageList] = useState<
    ImageType[]
  >([]);

  useEffect(() => {
    populateProjectCategories();
  }, []);

  const populateLearningStories = async () => {
    const data = await fetchLearningStories(Number(currGroup.id), Number(childID));
    const formattedData = data.map((story: any) => ({
      ...story,
      fundamental: story.fundamental?.name || '',
      milestone: story.milestone?.name || '', 
    }));
  
    // Filter the formatted data based on the date range and non-null images
    const filteredData = formattedData.filter((story: any) => {
      const momentDate = moment(story.date);
      const startDate = moment(startAt);
      const endDate = moment(endAt);
      return (
        momentDate.isSameOrAfter(startDate) &&
        momentDate.isSameOrBefore(endDate) &&
        story.image !== null
      );
    });
  
    setLearningStories(filteredData);
  
    // Directly update PDFInfo with the fetched and formatted learning stories
    setPDFInfo((prev) => ({
      ...prev,
      learningStories: filteredData,
    }));
  };
  
  

  const populateCompletedProjectList = async () => {
    if (startAt === null || endAt === null) {
      openSnack("No date is selected!", false);
      return;
    }

    const startYearMonthStr = `${startAt.getFullYear()}-${
      startAt.getMonth() + 1
    }`;
    // the last +1 is because month inclusive. For example if till april means till 30 Apr which = May
    let endYear = endAt.getFullYear();
    let endMonth = endAt.getMonth() + 1; // Months in JavaScript are 0-based so adding 1 to make it human-friendly.

    if (endMonth === 12) {
      endMonth = 1; // set January
      endYear++; // increment year
    }

    const endYearMonthStr = `${endYear}-${endMonth}`;

    const littleProjectRes = await getChildCompletedProject(
      currLanguage,
      childDetails.id,
      groupSlug!,
      startYearMonthStr,
      endYearMonthStr,
      false
    );

    const littleLearnerRes = await getChildCompletedProject(
      currLanguage,
      childDetails.id,
      groupSlug!,
      startYearMonthStr,
      endYearMonthStr,
      true
    );

    const completedWorkRes = await getCompletedWorkList({
      language: currLanguage,
      childId: childDetails.id,
      queryData: {
        query: null,
        startDate:
          startYearMonthStr +
          "-" +
          moment(startAt).startOf("month").format("DD"),
        endDate:
          endYearMonthStr + "-" + moment(endAt).startOf("month").format("DD"),
      },
    });

    const completedWorkImages: ImageType[] =
      typeof completedWorkRes !== "string"
        ? completedWorkRes
            .filter((work) => getFileTypeFromExtention(work.file) === "Image")
            .map((work) => ({
              id: work.id,
              file: work.file,
              caption: work.caption,
            }))
        : [];
    let categories: any = [];
    function addOrUpdateModule(
      categoryTitle: any,
      categoryImage: string,
      project: any
    ) {
      const existingCatIndex = categories.findIndex(
        (m: any) => m.category_title === categoryTitle
      );
      if (existingCatIndex !== -1) {
        const transformedProject = {
          title: project.name,
          project_id: project.id,
          category: project.module.name,
          image_url: project.images[0].file,
        };
        categories[existingCatIndex].projects.push(transformedProject);
      } else {
        const transformedProject = {
          title: project.name,
          project_id: project.id,
          category: project.module.name,
          image_url: project.images[0].file,
        };
        categories.push({
          category_title: categoryTitle,
          category_image: categoryImage,
          projects: [transformedProject],
        });
      }
    }
    const completedProjects = await getScheduleByGroup(
      currLanguage,
      currGroup.id,
      localStorage.getItem("token")
    );

    let completed = completedProjects?.filter((project: any) => {
      if (project.current_lesson === null && project.next_lesson === null) {
        const createdAtMonth = moment(project.created_at).month();
        return (
          createdAtMonth >= moment(startAt).month() &&
          createdAtMonth <= moment(endAt).month()
        );
      }
      return false;
    });
    let projectDetailsIds: any = [];
    completed.forEach((item: any) => {
      projectDetailsIds.push(item.project.id);
    });
    let projectDetails: any = await getProjectById(
      currLanguage,
      projectDetailsIds,
      localStorage.getItem("token")
    );
    projectDetails = projectDetails.data;
    completed.forEach((projectObj: any) => {
      const project = projectDetails.find(
        (item: any) => item.id === projectObj.project.id
      );
      projectCategories.forEach((category: any) => {
        if (project.module.category === category.name) {
          addOrUpdateModule(
            project.module.category,
            project.module.category_image,
            project
          );
        }
      });
    });
    setCompletedWorkImageList(completedWorkImages);

    setPDFInfo((prev) => ({
      ...prev,
      startMonth: moment(startAt).format(MONTH_FORMAT),
      endMonth: moment(endAt).format(MONTH_FORMAT),
      categories: categories,
      completedLittleProjectList: littleProjectRes,
      completedLittleLearnerList: littleLearnerRes,
      completedWorkImages: completedWorkImages,
      completedWorkTemplateNumber:
        completedWorkImages.length <= 1
          ? 1
          : completedWorkImages.length <= 2
          ? 2
          : completedWorkImages.length <= 3
          ? 3
          : completedWorkImages.length <= 4
          ? 4
          : completedWorkImages.length <= 5
          ? 5
          : 6,
      // Other data from API that will be changed when startMonth or endMonth changes
    }));
  };

  const populateGroupInfo = async () => {
    if (!groupSlug) return;

    const groupRes = await getChildGroupPortfolioInfo(currLanguage, groupSlug!);

    if (typeof groupRes !== "string") {
      setPDFInfo((prev) => ({
        ...prev,
        currentGroup: groupRes,
      }));
    }
  };

  const populateProfileImages = async () => {
    const res = await getProfileImages(childDetails.id);

    if (typeof res !== "string") {
      setPDFInfo((prev) => ({
        ...prev,
        childImages: res,
      }));
      setChildImageList(res);
      setPDFInfo((prev) => ({
        ...prev,
        photoGalleryTemplateNumber:
          res.length <= 1
            ? 1
            : res.length <= 2
            ? 2
            : res.length <= 3
            ? 3
            : res.length <= 4
            ? 4
            : res.length <= 5
            ? 5
            : 6,
      }));
    }
  };

  const populateDevelopmentalGoal = async () => {
    let res = await getAllFundamentals(currLanguage, "", childDetails.id);
    if (typeof res !== "string") {
      res = res.sort((a, b) => a.priority - b.priority);
      setPDFInfo((prev) => ({
        ...prev,
        developmentalGoalList: res,
      }));
    }
  };
  const populateAllChildAchievedMilestone = async () => {
    const res = await getAllChildAchievedMilestoneTracker(
      currLanguage,
      Number(childDetails.id)
    );
    if (typeof res !== "string" && Array.isArray(res)) {
      const filteredRes = res.filter((item) => item.status === true);

      setPDFInfo((prev) => ({
        ...prev,
        childAllAchievedMilestoneTrackers: filteredRes,
      }));
    }
  };

  const populateChildPortfolioObservation = async () => {
    const startDate = formatDateToYYYYMMDD(
      startAt ? startAt.toString() : "1970-01-01"
    );
    const endDate = formatDateToYYYYMMDD(
      endAt ? endAt.toString() : new Date().toLocaleString()
    );
    const res = await getAllChildPortfolioObservation({
      language: currLanguage,
      childId: childDetails.id,
      startDate: startDate,
      endDate: endDate,
    });
    if (typeof res !== "string") {
      setPDFInfo((prev) => ({
        ...prev,
        observationList: res,
      }));
    }
  };

  const handleClick = () => {
    if (startAt && endAt) {
      setPDFInfo((prev) => ({
        ...prev,
        startMonth: moment(startAt).format(MONTH_FORMAT),
        endMonth: moment(endAt).format(MONTH_FORMAT),
      }));
    }
    populateCompletedProjectList();
    populateGroupInfo();
    populateLearningStories();
    populateProfileImages();
    populateDevelopmentalGoal();
    populateChildPortfolioObservation();
    populateAllChildAchievedMilestone();
    setIsOpenModal(false);
  };

  const handleDelete = async () => {
    if (fileId) {
      const res = await deleteChildProgressReport(
        Number(fileId),
        localStorage.getItem("access")
      );
      if (typeof res === "string") {
        openSnack("Failed to Delete Report", false);
        handleCloseDialog();
      } else {
        openSnack("Progress Report Deleted", true);
        handleCloseDialog();
      }
    }
    navigate(
      `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO_PAGE(
        groupSlug!,
        childID!
      )}/${slugify(PROGRESS_REPORT)}`
    );
  };
  const handleCloseSaveDraftModal = () => {
    setSaveDraftModal(false);
    setSaveDraftModalText("");
  };

  const handleLeftButton = () => {
    handleCloseDialog();
  };
  const handleRightButton = () => {
    handleDelete();
  };
  const choiceBody = {
    openDialog,
    handleCloseDialog,
    choiceTitle: "Delete Progress Report?",
    choiceContext: "The Action is Irreversible!",
    handleLeftButton,
    handleRightButton,
    leftButtonText: "Cancel",
    rightButtonText: "Delete",
  };
  return (
    <>
      {!fileId && (
        <DatePickerModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          childDetails={childDetails}
          endAt={endAt}
          startAt={startAt}
          setStartAt={setStartAt}
          setEndAt={setEndAt}
          handleClick={handleClick}
        />
      )}
      <SaveDraftModal
        open={saveDraftModal}
        handleClose={handleCloseSaveDraftModal}
        saveDraftModalText={saveDraftModalText}
        setSaveDraftModalText={setSaveDraftModalText}
        PDFInfo={PDFInfo}
      />
      <ChoiceDialog {...choiceBody} />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          gap: 2,
          backgroundColor: "#FBFBFB",
          borderRadius: "16px",
          p: "30px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            height: "100%",
            flex: 1,
          }}
        >
          {PDFInfo ? (
            <Preview
              PDFInfo={PDFInfo}
              setPDFInfo={setPDFInfo}
              childImageList={childImageList}
              completedWorkImageList={completedWorkImageList}
              setTotalPages={setTotalPages}
            />
          ) : (
            <LoadingIndicator />
          )}
        </Box>
        {/* Date pick */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mb: 2,
            maxWidth: 170,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              minDate={moment(childDetails.create_at!)}
              maxDate={moment(endAt!)}
              views={["year", "month"]}
              inputFormat={MONTH_FORMAT}
              label="Start Date"
              value={startAt}
              onChange={(newValue: moment.Moment | null) => {
                if (newValue !== null) {
                  setStartAt(newValue.toDate());
                }
              }}
              openTo="year"
              renderInput={(params) => <TextField {...params} size="small" />}
              disabled
            />
            <DatePicker
              minDate={moment(startAt!)}
              maxDate={moment()}
              views={["year", "month"]}
              inputFormat={MONTH_FORMAT}
              label="End Date"
              value={endAt}
              onChange={(newValue: moment.Moment | null) => {
                if (newValue !== null) {
                  setEndAt(newValue?.toDate());
                }
              }}
              openTo="year"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  disabled
                  // inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              disabled
            />
          </LocalizationProvider>
          <DownloadPDFButton PDFInfo={PDFInfo} isDownload={true} />

          {fileId ? (
            <EditOrSaveDraftButton
              PDFInfo={PDFInfo}
              draftPDFInfo={draftPDFInfo}
              setSaveDraftModal={setSaveDraftModal}
            />
          ) : (
            <MuiButton
              sx={{
                width: "100%",
                gap: 1,
                color: "#5C9391",
                border: "1px solid #5C9391",
              }}
              variant="outlined"
              onClick={() => {
                setSaveDraftModal(true);
              }}
            >
              <SaveOutlinedIcon sx={{ fontSize: "20px" }} />
              <Typography sx={{ textTransform: "none" }}>
                {" "}
                Save Draft
              </Typography>
            </MuiButton>
          )}
          <Box
            sx={{
              width: "100%",
            }}
            onClick={() => {
              handleOpenDialog();
            }}
          >
            {fileId && (
              <DeleteButton
                style={{
                  width: "100%",
                  color: "red",
                  backgroundColor: "transparent",
                  fontSize: "16px",
                  fontWeight: "450",
                }}
                btnText={"Delete"}
                iconLeft={true}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const DatePickerModal = ({
  isOpenModal,
  setIsOpenModal,
  childDetails,
  endAt,
  startAt,
  setStartAt,
  setEndAt,
  handleClick,
}: {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  childDetails: API_Child;
  endAt: Date | null;
  startAt: Date | null;
  setStartAt: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndAt: React.Dispatch<React.SetStateAction<Date | null>>;
  handleClick: () => void;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Modal
      open={isOpenModal}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableAutoFocus={true}
      sx={
        {
          // m: "24px"
        }
      }
    >
      <Fade in={isOpenModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "80%",
              sm: "unset",
            },
            maxWidth: "1000px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isXs ? (
              <Typography
                sx={{
                  mb: 2,
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {translation.selectProgressReportDateRange}
              </Typography>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                }}
              >
                {translation.selectProgressReportDateRange}
              </Typography>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  minDate={moment(childDetails.create_at!)}
                  maxDate={moment(endAt!)}
                  views={["year", "month"]}
                  inputFormat={MONTH_FORMAT}
                  label="Start Date"
                  value={startAt}
                  onChange={(newValue: moment.Moment | null) => {
                    if (newValue !== null) {
                      setStartAt(newValue.toDate());
                    }
                  }}
                  openTo="year"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      // fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                />
                <DatePicker
                  minDate={moment(startAt!)}
                  maxDate={moment()}
                  views={["year", "month"]}
                  inputFormat={MONTH_FORMAT}
                  label="End Date"
                  value={endAt}
                  onChange={(newValue: moment.Moment | null) => {
                    if (newValue !== null) {
                      setEndAt(newValue?.toDate());
                    }
                  }}
                  openTo="year"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      // fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                arrow={false}
                // disabled={startAt >= endAt}
                style={{
                  fontSize: "14px",
                  backgroundColor: "var(--primary-main)",
                  width: "100%",
                  maxWidth: "175px",
                  textAlign: "center",
                }}
                buttonText={translation.submit || "Submit"}
                onClick={handleClick}
              />
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateChildrenPortfolioPage;
