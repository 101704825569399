import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

import useStore from "store/store";
import { translation } from "constants/translation";
import { TITLE } from "constants/title";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";

import "./EditDevelopmentalGoalPage.css";
import DevelopmentalGoalResizableDrawer from "./DevelopmentalGoalResizableDrawer";
import DevelopmentalGoalTreeView from "./DevelopmentalGoalTreeView";

export type TagType = "Head" | "Hands" | "Heart";
export const initialDevelopmentalGoal = {
  id: -1,
  name: "",
  description: "",
  image: "",
  title: "",
  tag: "",
  milestones: [],
  priority: 0,
};

const EditDevelopmentalGoalPage = () => {
  document.title = TITLE;
  const {
    currDevelopmentalGoal,
    setCurrDevelopmentalGoal,
    developmentalGoals,
  } = useStore((state) => state);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleOpen = (
    selectedDevelopmentalGoalId: number,
    parentDevelopmentalGoalId?: number
  ) => {
    if (
      isDrawerOpen &&
      selectedDevelopmentalGoalId === currDevelopmentalGoal.id &&
      (!parentDevelopmentalGoalId ||
        parentDevelopmentalGoalId === currDevelopmentalGoal.main_fundamental)
    ) {
      handleClose();
      return;
    }

    const developmentalGoal = parentDevelopmentalGoalId
      ? developmentalGoals
          .filter(
            (developmentalGoal) =>
              developmentalGoal.id === parentDevelopmentalGoalId
          )[0]
          .sub_fundamentals?.filter(
            (subDevelopmentalGoal) =>
              subDevelopmentalGoal.id === selectedDevelopmentalGoalId
          )[0]
      : developmentalGoals.filter(
          (developmentalGoal) =>
            developmentalGoal.id === selectedDevelopmentalGoalId
        )[0];
    // console.log("developmentalGoal in handleOpen: ", developmentalGoal);
    if (developmentalGoal) {
      setCurrDevelopmentalGoal(developmentalGoal);
    } else {
      setCurrDevelopmentalGoal({
        ...initialDevelopmentalGoal,
        id: selectedDevelopmentalGoalId,
        main_fundamental: parentDevelopmentalGoalId,
      });
    }
    document.body.classList.add("drawer-open");
    setIsDrawerOpen(true);
  };

  const handleClose = () => {
    if (!isDrawerOpen) return;

    document.body.classList.remove("drawer-open");
    setCurrDevelopmentalGoal(initialDevelopmentalGoal); // to make sure the info refresh after update
    setIsDrawerOpen(false);
  };

  // console.log("developmentalGoals: ", developmentalGoals);
  return (
    <Box
      className="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        mx: "60px",
        my: 3,
        gap: 2,
      }}
    >
      <Title handleClose={handleClose} />

      <Box
        sx={{
          backgroundColor: "#FBFBFB",
          borderRadius: "16px",
          flex: 1,
          p: 4,
        }}
      >
        <DevelopmentalGoalTreeView
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
        {/* {isLoading ? (
          <LoadingIndicator />
        ) : (
          <DevelopmentalGoalTreeView
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        )} */}
      </Box>

      <DevelopmentalGoalResizableDrawer
        isDrawerOpen={isDrawerOpen}
        handleClose={handleClose}
      />
    </Box>
  );
};

const Title = ({ handleClose }: { handleClose: () => void }) => (
  <Box
    sx={{
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {/* <BackButton handleClose={handleClose} /> */}

    <Typography
      sx={{
        fontSize: "26px",
        fontWeight: "500",
        textAlign: "center",
      }}
    >
      {translation.editingDevelopmentalGoal}
    </Typography>
  </Box>
);

const BackButton = ({ handleClose }: { handleClose: () => void }) => {
  const navigate = useNavigate();
  const { currLanguage, projSlug } = useStore((state) => ({
    currLanguage: state.currLanguage,
    projSlug: state.currentProject.slug,
  }));

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        display: "flex",
        gap: "6px",
        alignItems: "center",
        cursor: "pointer",
        pl: "16px",
      }}
      onClick={() => {
        handleClose();
        navigate(`${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(projSlug)}`);
      }}
    >
      <ArrowBackIcon sx={{ fontSize: "14px" }} />
      <Typography>{translation.backProject}</Typography>
    </Box>
  );
};

export default EditDevelopmentalGoalPage;
