import React from "react";
import PageContent from "./PageContent";
import { PDFInfoType } from "../constant";

const LearningStoryPages = ({
  scale,
  PDFInfo,
}: {
  scale: number;
  PDFInfo: PDFInfoType;
}) => {
  const chunkedStories = [];
  for (let i = 0; i < PDFInfo.learningStories.length; i += 2) {
    chunkedStories.push(PDFInfo.learningStories.slice(i, i + 2));
  }

  return (
    <>
      {chunkedStories.map((stories, index) => (
        <PageContent
          key={index}
          scale={scale}
          learningStories={stories}
        />
      ))}
    </>
  );
};

export default LearningStoryPages;
