import { useEffect, useState } from "react";
import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/material";
import useStore from "store/store";
import useSnack from "hooks/useSnack";
import theme from "theme";

// Components
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import Popup from "components/Popup/Popup";

// Base
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import LandingPage from "containers/LandingPage/LandingPage";
import MarketPlacePage from "containers/MarketPlacePage/MarketPlacePage";
import AssessmentsPage from "containers/AssessmentsPage/AssessmentsPage";
import ProjectSummaryPage from "containers/ProjectSummaryPage/ProjectSummaryPage";
import BackpackPage from "containers/BackpackPage/BackpackPage";
import LessonActivityPage from "containers/ProjectPage/LessonActivityPage";
import CalendarPage from "containers/CalendarPage/CalendarPage";

// Teacher
import TeacherPage from "containers/teacher/TeacherPage/TeacherPage";
import TeacherModuleSummaryPage from "containers/teacher/TeacherPage/TeacherModuleSummaryPage";
import EditModulePage from "containers/teacher/TeacherPage/TeacherEdit/EditModulePage/EditModulePage";

// pages
import LoginPage from "containers/LoginPage/LoginPage";
import CreateProjectPage from "containers/CreateProjectPage/CreateProjectPage";
import RegisterPage from "containers/RegisterPage/RegisterPage";
import ProfilePage from "containers/ProfilePage/ProfilePage";
import CreateLessonPage from "containers/CreateLessonPage/CreateLessonPage";
import WelcomeBackPage from "containers/WelcomeBackPage/WelcomeBackPage";
import CreateChildPage from "containers/CreateProfilePage/CreateChildPage";
import EnrollChildPage from "containers/EnrollChildPage/EnrollChildPage";
import UpdateChildPage from "containers/UpdateChildPage/UpdateChildPage";
import EditObjectivePage from "containers/EditObjectivePage/EditObjectivePage";
import AccountPage from "containers/AccountPage/AccountPage";
import SettingsPage from "containers/SettingsPage/SettingsPage";

// Questionnaire
import PreDiscoveryQuestionnaireDetail from "containers/QuestionnairePage/PreDiscoveryQuestionnaireDetail";

//Evaluation
import EvaluationQuestionnairePage from "containers/QuestionnairePage/EvaluationQuestionnairePage";
import EditEvaluationQuestionnaire from "containers/QuestionnairePage/EvaluationQuestionnaireEdit/EditEvaluationQuestionnaire";
import EditEvaluationQuestion from "containers/QuestionnairePage/EvaluationQuestionnaireEdit/EditEvaluationQuestion";
import EditEvaluationAnswer from "containers/QuestionnairePage/EvaluationQuestionnaireEdit/EditEvaluationAnswer";

//Site Admin
import AdminPage from "containers/SiteAdminPage/AdminPage";

//Payment
import PaymentPage from "containers/PaymentPage/PaymentPage";

import {
  HOME,
  MARKETPLACE,
  LITTLE_EVALUATOR,
  BACKPACK,
  CALENDAR,
  LOGIN,
  CREATE_PROJECT_PAGE,
  REGISTER,
  PROFILE,
  LEARNING_STORIES,
  SCHOOL_PROFILE,
  CREATE_LESSON_PAGE,
  EDIT_PROJECT_PAGE,
  WELCOME_BACK_PAGE,
  PROJECT_SUMMARY_PAGE,
  CREATE_CHILD_PAGE,
  ENROLL_CHILD_PAGE,
  UPDATE_CHILD_PAGE,
  EDIT_LESSON_PAGE,
  CHANGE_PASSWORD,
  EDIT_PROJECT_OBJECTIVE_PAGE,
  ACCOUNT_PAGE,
  SETTINGS_PAGE,
  EMAIL_PREFERENCES_PAGE,
  EDIT_LESSON_PAGE_2,
  EDIT_ACTIVITY_PAGE,
  CREATE_ACTIVITY_PAGE,
  SCHOOLS,
  CREATE_SCHOOL,
  EDIT_SCHOOL,
  SCHOOL_DETAIL,
  SCHOOL_ACCOUNT_PAGE,
  CREATE_PROJECT_OBJECTIVE_PAGE,
  TEACHER_TRAINING_ACADEMY,
  TEACHER_MODULE_PAGE,
  EDIT_TEACHER_MODULE_PAGE,
  CREATE_TEACHER_MODULE_PAGE,
  //Pre-discovery-questionnaires
  PRE_DISCOVERY_QUESTIONNAIRE,
  //Evaluation questionnaires
  EVALUATION_QUESTIONNAIRES,
  EVALUATION_QUESTIONNAIRE_CREATE,
  EVALUATION_QUESTIONNAIRE_EDIT,
  EVALUATION_QUESTIONNAIRE_QUESTION_CREATE,
  EVALUATION_QUESTIONNAIRE_QUESTION_EDIT,
  EVALUATION_QUESTIONNAIRE_ANSWER_CREATE,
  EVALUATION_QUESTIONNAIRE_ANSWER_EDIT,
  CLASS,
  CREATE_GROUP_PAGE,
  EDIT_GROUP_PAGE,
  CREATE_TEACHER_PAGE,
  CREATE_PARENT_PAGE,
  CREATE_INTEREST,
  CREATE_PRE_DQ_QUESTION,
  CREATE_PRE_DQ_ANSWER,
  CREATE_SCHOOL_ADMIN_PAGE,
  TICKETING_SYSTEM,
  CREATE_CONTENT_EDITOR_PAGE,
  CREATE_TICKET_PAGE,
  CREATE_SITE_ADMIN_PAGE,
  ADMIN_PAGE,
  TIMETABLE_PAGE,
  PROJECT_SCHOOL_MANAGE_PAGE_WITH_SLUG,
  PROJECT_MANAGE_PAGE,
  PAYMENT,
  CHATHOME,
  CHAT,
  PROJECT_SCHOOL_MANAGE_PAGE,
  CHILDREN_PORTFOLIO_PAGE,
  EDIT_DEVELOPMENTAL_GOAL,
  MANAGE_PROHIBITED_WORD,
  COMMUNITY,
  MY_PROGRESS,
  COMMUNITY_KANBAN_BOARD_PAGE,
  CHILDREN_PORTFOLIO,
  TEACHER_PORTFOLIO,
  PROFILE_PAGE,
  PROJECT_SCHOOL_MANAGE_PAGE_WITH_PAGE_MODE_SLUG,
  TEACHER_MODULE_MANAGE_PAGE_WITH_MODE,
  MY_CHILDREN_PORTFOLIO,
  TEACHER_CERTIFICATE_MANAGE_PAGE,
  TEACHER_CERTIFICATE_CREATE_PAGE,
  TEACHER_CERTIFICATE_EDIT_PAGE,
  TTA_PROFILE_PAGE,
  PROFILE_CERTIFICATE_PAGE,
  TTA_PROFILE_CERTIFICATE_PAGE,
  OUR_STORY,
  FORGOTTEN_PASSWORD,
  RESET_PASSWORD,
  DEVELOPMENTAL_GOALS,
  NOTICES,
  CHILDREN,
} from "constants/url";

import Toast from "components/Toast/Toast";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

import { API_Profile } from "types/profile";

import { getAccountProfile, getAllChildProfile } from "api/profile-api";

import { getBackpackProjectList, getFavProjectList } from "api/backpack-api";

import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import CannotAccessPage from "containers/NotFoundPage/CannotAccessPage";
import ChangePasswordPage from "containers/ChangePasswordPage/ChangePasswordPage";
import EditProjectPage from "containers/EditProjectPage/EditProjectPage";
import EditLessonPage from "containers/CreateLessonPage/EditLessonPage";
import EditActivityPage from "containers/CreateLessonPage/EditActivityPage";
import SchoolPage from "containers/SchoolPage/SchoolPage";
import CreateSchoolPage from "containers/SchoolPage/CreateSchoolPage";
import SchoolDetailPage from "containers/SchoolPage/SchoolDetailPage";
import CalendarPageClassMobile from "containers/CalendarPage/CalendarPageClassMobile";

import OverviewScreen from "containers/ProjectSummaryPage/Screens/OverviewScreen";
import TeachingMaterialsScreen from "containers/ProjectSummaryPage/Screens/TeachingMaterialsScreen";
import LessonPlanScreen from "containers/ProjectSummaryPage/Screens/LessonPlanScreen";
import ProjectPhotoAlbumScreen from "containers/ProjectSummaryPage/Screens/ProjectPhotoAlbumScreen";
import CreateObjectivePage from "containers/EditObjectivePage/CreateObjectivePage";
import GroupsPage from "containers/GroupPage/GroupsPage";
import CreateGroupPage from "containers/GroupPage/CreateGroupPage";
import EditGroupPage from "containers/GroupPage/EditGroupPage";
import ChildrenPortfolioPage from "containers/ChildrenPortfolioPage/ChildrenPortfolioPage";
import PortfolioDevelopmentalGoalsScreen from "containers/ChildrenPortfolioPage/Screens/DevelopmentalGoalsScreen";
import PortfolioProgressReportScreen from "containers/ChildrenPortfolioPage/Screens/ProgressReportScreen";
import CreateChildrenPortfolioPage from "containers/ChildrenPortfolioPage/Screens/CreateChildrenPortfolioPage";
import ChildCompletedWorksScreen from "containers/ChildrenPortfolioPage/Screens/ChildCompletedWorksScreen";
import PortfolioPhotoAlbumScreen from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreen";
import ChildPortfolioProjectSummaryScreen from "containers/ChildrenPortfolioPage/Screens/ChildPortfolioProjectSummaryScreen";
// import ChildPortfolioLearningStoriesScreen from "containers/ChildrenPortfolioPage/Screens/ChildPortfolioLearningStoriesScreen";

// Site admin page
import SchoolManagePage from "containers/SiteAdminPage/ManagementPage/SchoolManagePage";
import TeacherManagePage from "containers/SiteAdminPage/ManagementPage/TeacherManagePage";
import ContentEditorManagePage from "containers/SiteAdminPage/ManagementPage/ContentEditorManagePage";
import ParentManagePage from "containers/SiteAdminPage/ManagementPage/ParentManagePage";
import ChildManagePage from "containers/SiteAdminPage/ManagementPage/ChildManagePage";
import CreateProfilePage from "containers/CreateProfilePage/CreateProfilePage";
import CreateInterestPage from "containers/SiteAdminPage/CreatePage/CreateInterestPage";
import CreateQuestionPage from "containers/SiteAdminPage/CreatePage/CreateQuestionPage";
import CreateAnswerPage from "containers/SiteAdminPage/CreatePage/CreateAnswerPage";
import SchoolAdminManagePage from "containers/SiteAdminPage/ManagementPage/SchoolAdminManagePage";

import ProjectSummaryScreen from "components/ProjectSummaryScreen/ProjectSummaryScreen";
import ProjectReportScreen from "containers/ProjectSummaryPage/Screens/ProjectReportScreen";
import LessonActivityScreen from "containers/ProjectPage/LessonScreens/LessonActivityScreen";
import CreateTicketPage from "containers/TicketingSystemPage/CreateTicketPage";
import ViewTicketPage from "containers/TicketingSystemPage/ViewTicketPage";
import AdminOnlyRoute from "components/ProtectedRoute/AdminOnlyRoute";
import SiteAdminOnlyRoute from "components/ProtectedRoute/SiteAdminOnlyRoute";
import SiteAdminManagePage from "containers/SiteAdminPage/ManagementPage/SiteAdminManagePage";
import TimetablePage from "containers/TimetablePage/TimetablePage";
import ManageSchoolProjectPage from "containers/SchoolAccessControlPage/ManageSchoolProjectPage";
import { getGroupCalendarList } from "api/calendar-api";
import { GroupProjectBoxProps, ProjectBoxProps } from "types/calendar";
import { getProjectBoxObjects } from "containers/CalendarPage/constants/helper";
import {
  getPaymentRecord,
  getCustomerInfo,
  updateSubscriptionUsageRecord,
} from "api/payment-api";
import PaymentHistoryComponent from "containers/PaymentPage/PaymentHistoryComponent";
import { initialCustomerInfo } from "containers/PaymentPage/constants/helper";
import PaymentRecordManagePage from "containers/SiteAdminPage/ManagementPage/PaymentRecordManagePage";
import Chat from "containers/ChatPage/Chat";
import { getUnseenChatCount } from "api/chat-api";
import TrehausCurriculumSpecialistManagePage from "containers/SiteAdminPage/ManagementPage/TrehausCurriculumSpecialistManagePage";
import EditorOnlyRoute from "components/ProtectedRoute/EditorOnlyRoute";
import EditDevelopmentalGoalPage from "containers/DevelopmentalGoalPage/EditDevelopmentalGoalPage";
import EditorOnlyRouteWithoutTrehaus from "components/ProtectedRoute/EditorOnlyRouteWithoutTrehaus";
import LearningMomentScreen from "containers/ProjectSummaryPage/Screens/LearningMomentScreen";
import ViewProhibitedWordPage from "containers/ProhibitedWordPage/ViewProhibitedWordPage";
import { API_Fundamental } from "types/project";
import { getAllFundamentals } from "api/fundamental-api";
import CreateModuleForm from "containers/teacher/TeacherPage/TeacherEdit/TeacherForms/CreateModuleForm";
import TeacherLessonActivityScreen from "containers/teacher/TeacherPage/TeacherPageSideMenuScreens/TeacherLessonActivityScreen";
import TeacherProgressPage from "containers/teacher/TeacherPage/TeacherProgressPage/TeacherProgressPage";
import TeacherCommunityPage from "containers/teacher/TeacherPage/TeacherCommunitySharing/TeacherCommunityPage";
import SchoolProfilePage from "containers/SchoolProfilePage/SchoolProfilePage";
import CheckpointScreen from "containers/ProjectPage/LessonScreens/CheckpointScreen/CheckpointScreen";
import TeacherKanbanBoardPage from "containers/teacher/TeacherPage/TeacherCommunitySharing/TeacherKanbanBoardPage";
import TeacherModuleDiscussionScreen from "containers/teacher/TeacherPage/TeacherDiscussion/TeacherModuleDiscussionScreen";
import TeacherLessonDiscussionScreen from "containers/teacher/TeacherPage/TeacherDiscussion/TeacherLessonDiscussionScreen";
import TTAContentEditorManagePage from "containers/SiteAdminPage/ManagementPage/TTAContentEditorManagePage";
import ManageTeacherModulePage from "containers/teacher/TeacherAnalytic/ManageTeacherModulePage";
import ChildrenPortfolioOverviewPage from "containers/ChildrenPortfolioPage/ChildrenPortfolioOverviewPage";
import ClassInfoPage from "containers/ChildrenPortfolioPage/ChildrenPortfolioOverviewPageComponents/ClassInfoPage";
import GroupPage from "containers/GroupPage/GroupPage";
import { slugify } from "helper/slugify";
import {
  COMPLETED_WORKS,
  UNIT_GUIDE,
  WHAT_PEOPLE_LIKE,
} from "containers/ProjectSummaryPage/constant";
import WhatDoPeopleLikeScreen from "containers/ProjectSummaryPage/Screens/WhatDoPeopleLikeScreen";
import ProjectUnitGuide from "containers/ProjectSummaryPage/Screens/ProjectUnitGuide";
import AllPhotosScreen from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/AllPhotosScreen";
import AlbumsScreen from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/AlbumsScreen";
import CompletedWorksScreen from "containers/ProjectSummaryPage/Screens/CompletedWorksScreen";
import ChildProfileScreen from "containers/ChildrenPortfolioPage/Screens/ChildProfileScreen";
import TeacherArchivePage from "containers/teacher/TeacherArchivePage/TeacherArchivePage";
import TeacherArchiveLandingPage from "containers/teacher/TeacherArchivePage/TeacherArchivePageComponent/TeacherArchiveLandingPage";
import TeacherArchiveTabCategoryLandingPage from "containers/teacher/TeacherArchivePage/TeacherArchivePageComponent/TeacherArchiveTabCategoryLandingPage";

import ManageTeacherCertificatePage from "containers/teacher/TeacherPage/TeacherCertificate/ManageTeacherCertificatePage";
import CreateEditCertificatePage from "containers/teacher/TeacherPage/TeacherCertificate/CreateEditCertificatePage";
import { getNumberOfActiveUser } from "api/school-api";

import MyChildrenOverviewPage from "containers/MyChildrenPage/MyChildrenOverviewPage";
import MyChildrenPage from "containers/MyChildrenPage/MyChildrenPage";
import TeacherCertificateLandingPagev2 from "containers/teacher/TeacherPage/TeacherCertificate/TeacherCertificateLandingPagev2";
import OurStory from "containers/teacher/TeacherPage/TeacherOurStoryPage/OurStory";
import ForgotPasswordPage from "containers/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "containers/ForgotPasswordPage/ResetPasswordPage";
import NoticesPage from "containers/NoticesPage/NoticesPage";
import TabSelectorPage from "containers/NoticesPage/TabSelectorPage";
import ReactGA from "react-ga4";
import HelpPage from "containers/HelpPage/HelpPage";
import MainHelpPage from "containers/HelpPage/MainHelpPage";
import EmailPreferencesPage from "containers/SettingsPage/EmailPreferencesPage";
import LittleEvaluatorRoutes from "routes/LittleEvaluator";
import AssessmentAnalyticsRoutes from "routes/AssessmentAnalytics";
import AttendancePage from "containers/GroupPage/AttendancePage";
import DevelopmentalGoalPortfolio from "containers/ChildrenPortfolioPage/DevelopmentalGoalPortfolio";
import Dashboard from "containers/WelcomeBackPage/components/Dashboard";
import LearningStoriesScreen from "containers/ChildrenPortfolioPage/Screens/LearningStories/LearningStoriesScreen";

const GOOGLE_ANALYTICS_API_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_API_KEY;
if (GOOGLE_ANALYTICS_API_KEY) ReactGA.initialize(GOOGLE_ANALYTICS_API_KEY);

function App() {
  const openSnack = useSnack();
  const {
    profileID,
    setProfileID,
    setProfileDetails,
    profileDetails,
    setChildrenInSession,
    setCurrGroupBackpack,
    setCurrGroupFavourite,
    currLanguage,
    currGroup,
    numberOfActiveUser,
    setNumberOfActiveUser,
    refreshNumberOfActiveUserInfo,
    setRefreshNumberOfActiveUserInfo,
    refreshGroupBackpack,
    setRefreshGroupBackpack,
    refreshGroupFavourite,
    setRefreshGroupFavourite,
    calendarDates,
    setGroupProjectBoxProps,
    refreshProjectBox,
    setRefreshProjectBox,
    setIsLoadingCalendar,
    setCustomerInfo,
    setUnseenChatCount,
    isRefreshAllFundamental,
    setIsRefreshAllFundamental,
    setDevelopmentalGoals,
    setFundamentalDict,
    setFundamentalListToDisplay,
  } = useStore((state) => state);

  useEffect(() => {
    // console.log("Populating unseenchat count!");
    const populateUnseenChatCount = async () => {
      const res = await getUnseenChatCount();
      setUnseenChatCount(res.unseen_chat_count);
    };
    populateUnseenChatCount();
    if (!window.location.href.includes("calendar")) {
      setIsLoadingCalendar(true);
    }
  }, [window.location.href]);

  useEffect(() => {
    const storedProfileID = localStorage.getItem("profile_id");
    storedProfileID && setProfileID(storedProfileID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateProfile = async () => {
      const data: API_Profile = await getAccountProfile(
        profileID,
        localStorage.getItem("access")
      );
      setProfileDetails(data);

      // set copying from the platform for these roles
      setAllowTextCopying(["CE", "WAD", "TTACE"].includes(data.role));

      // payment
      const paymentRecord = await getPaymentRecord({
        school: data.school ? data.school.id : -1,
      });
      let customer_id = "";
      if (typeof paymentRecord === "string") {
        openSnack(paymentRecord, false);
      } else {
        // console.log("paymentRecord: ", paymentRecord);
        if (paymentRecord.length > 0) {
          customer_id = paymentRecord[0].customer_id;
          const customer_res = await getCustomerInfo(customer_id);
          if (typeof customer_res === "string") {
            openSnack(customer_res, false);
          } else {
            // console.log("customer_res: ", customer_res);
            try {
              if (customer_res?.product?.id) {
                setCustomerInfo(customer_res);
                return;
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
        setCustomerInfo({
          ...initialCustomerInfo,
          customer: { ...initialCustomerInfo.customer, id: customer_id },
          product: {
            ...initialCustomerInfo.product,
            id: "",
            name: "No Product found",
          },
        });
      }
    };
    profileID !== "" && updateProfile();
  }, [profileID, numberOfActiveUser]);

  useEffect(() => {
    const fetchAndUpdateChildrenDetails = async () => {
      // const children = await getAllChildProfile();
      let children;
      if (profileDetails.role === "PR") {
        children = await getAllChildProfile();
      } else {
        children = currGroup?.children;
      }
      setChildrenInSession(children);
    };
    // console.log("Update children in session");
    profileDetails.role !== "CH" && fetchAndUpdateChildrenDetails();
  }, [currGroup, profileDetails.role]);

  useEffect(() => {
    // console.log("Populating backpack: ", refreshGroupBackpack);
    const populateBackpack = async () => {
      if (currGroup.id !== -1) {
        const resBackpack = await getBackpackProjectList(
          currLanguage,
          currGroup.id
        );
        if (typeof resBackpack !== "string") {
          setCurrGroupBackpack(resBackpack);
          setRefreshGroupBackpack(false);
        } else {
          openSnack(resBackpack, false);
        }
      }
    };
    if (refreshGroupBackpack) {
      populateBackpack();
    }
  }, [currGroup, refreshGroupBackpack, currLanguage]);

  useEffect(() => {
    const populateFav = async () => {
      if (currGroup.id !== -1) {
        const resFavourite = await getFavProjectList(
          currLanguage,
          currGroup.id
        );
        if (typeof resFavourite !== "string") {
          setCurrGroupFavourite(resFavourite);
          setRefreshGroupFavourite(false);
        } else {
          openSnack(resFavourite, false);
        }
      }
    };
    if (refreshGroupFavourite) {
      populateFav();
    }
  }, [currGroup, refreshGroupFavourite, currLanguage]);

  const populateData = async () => {
    // console.log("Repopulating schedule objects: ");
    const groupsCalendar = await getGroupCalendarList();
    if (typeof groupsCalendar !== "string") {
      const groupProjectBoxPropsTemp: GroupProjectBoxProps = {};
      groupsCalendar.map((group) => {
        let temp_: ProjectBoxProps[] = [];
        group.schedules.map((schedule) => {
          const temp_schedules = getProjectBoxObjects(schedule, calendarDates);
          temp_ = [...temp_, ...temp_schedules];
        });
        groupProjectBoxPropsTemp[group.id] = temp_;
      });
      setGroupProjectBoxProps(groupProjectBoxPropsTemp);
    }
    setRefreshProjectBox(false);
    // setIsLoadingCalendar(true);
  };

  useEffect(() => {
    if (refreshProjectBox) {
      profileDetails.id !== -1 && populateData();
    }
  }, [refreshProjectBox, profileDetails]);

  useEffect(() => {
    if (!refreshProjectBox) {
      setIsLoadingCalendar(true);
    }
  }, [refreshProjectBox]);

  //fundamentalDict
  const populateFundamentals = async () => {
    const res = await getAllFundamentals(currLanguage);
    if (typeof res !== "string") {
      const newFundamentalDictList: {
        [id: string]: API_Fundamental;
      } = {};
      const newFundamentalListToDisplay: API_Fundamental[] = [];
      res.forEach((fundamental) => {
        newFundamentalDictList[fundamental.id.toString()] = fundamental;
        newFundamentalListToDisplay.push(fundamental);
        if (
          fundamental.sub_fundamentals &&
          fundamental.sub_fundamentals.length > 0
        ) {
          fundamental.sub_fundamentals.forEach((subFundamental) => {
            newFundamentalDictList[subFundamental.id.toString()] =
              subFundamental;
            newFundamentalListToDisplay.push(subFundamental);
          });
        }
      });
      newFundamentalDictList["-1"] = {
        id: -1,
        name: "",
        description: "",
        image: "",
        title: "",
        tag: "",
        milestones: [],
        priority: 0,
      };

      setDevelopmentalGoals(res);
      setFundamentalListToDisplay(newFundamentalListToDisplay);
      setFundamentalDict(newFundamentalDictList);
    } else {
      setDevelopmentalGoals([]);
    }
  };

  useEffect(() => {
    populateFundamentals();
  }, [currLanguage]);

  useEffect(() => {
    if (isRefreshAllFundamental) {
      populateFundamentals();
      setIsRefreshAllFundamental(false);
    }
  }, [isRefreshAllFundamental]);

  // Active user update
  const populateNumberOfActiveUser = async (
    schoolId: number,
    isCallStripe?: boolean
  ) => {
    const activeUserRes = await getNumberOfActiveUser(schoolId);
    if (typeof activeUserRes !== "string" && activeUserRes !== -1) {
      profileDetails.role === "AD" && setNumberOfActiveUser(activeUserRes);
      // Call stripe here to update subscription payment record.
      if (isCallStripe) {
        const paymentRecord = await getPaymentRecord({
          school: schoolId,
        });
        let customer_id = "";
        if (typeof paymentRecord === "string") {
          openSnack(paymentRecord, false);
        } else {
          if (paymentRecord.length > 0) {
            customer_id = paymentRecord[0].customer_id;
            const customer_res = await getCustomerInfo(customer_id);

            if (typeof customer_res === "string") {
              openSnack(customer_res, false);
            } else {
              // console.log(
              //   "customer_res.subscription.quantity: ",
              //   customer_res.subscription.quantity
              // );
              // console.log("activeUserRes:", activeUserRes);
              // const subscription_quantity =
              //   customer_res.upcoming_invoice.lines.data.reduce(
              //     (accumulator, data_) => {
              //       return accumulator + data_.quantity;
              //     },
              //     0
              //   );
              if (
                customer_res.subscription.quantity !== -1 &&
                customer_res.subscription.items
              ) {
                await updateSubscriptionUsageRecord(
                  customer_res.subscription.items.data[0].id,
                  activeUserRes
                );
              }
            }
          }
        }
      }
    }

    setRefreshNumberOfActiveUserInfo({
      ...refreshNumberOfActiveUserInfo,
      isRefresh: false,
    });
  };
  useEffect(() => {
    profileDetails.school &&
      populateNumberOfActiveUser(profileDetails.school.id);
  }, [profileDetails.school]);

  useEffect(() => {
    refreshNumberOfActiveUserInfo.isRefresh &&
      populateNumberOfActiveUser(refreshNumberOfActiveUserInfo.schoolId, true);
  }, [refreshNumberOfActiveUserInfo.isRefresh]);

  const [allowTextCopying, setAllowTextCopying] = useState(false); // Set to true or false for copying text on platform
  const [copyWarningOpen, setCopyWarningOpen] = useState(false);

  useEffect(() => {
    const handleCopyAttempt = (event: Event) => {
      if (!allowTextCopying) {
        event.preventDefault();
        setCopyWarningOpen(true);
      }
    };

    const copyListener = (event: Event) => handleCopyAttempt(event);

    if (!allowTextCopying) {
      window.addEventListener("copy", copyListener);
    }

    return () => {
      if (!allowTextCopying) {
        window.removeEventListener("copy", copyListener);
      }
    };
  }, [allowTextCopying]);

  return (
    <>
      {copyWarningOpen && (
        <Popup
          open={copyWarningOpen}
          onClose={() => setCopyWarningOpen(false)}
          title="Copying text is not allowed on this platform."
        />
      )}

      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Routes>
              <Route path={""} element={<Navigate to="/en" />} />
              <Route path="/notfound" element={<NotFoundPage />} />
              <Route path=":languageSlug" element={<Navbar />}>
                <Route path="cannot-access" element={<CannotAccessPage />} />
                <Route path="help" element={<MainHelpPage />}>
                  <Route path=":helpSlug" element={<HelpPage />} />
                </Route>

                <Route path={HOME} element={<LandingPage />} />
                <Route element={<ProtectedRoute isAuth={!!profileID} />}>
                  {/* Base */}
                  {AssessmentAnalyticsRoutes}
                  <Route path={MARKETPLACE} element={<MarketPlacePage />} />
                  <Route
                    path={LITTLE_EVALUATOR}
                    element={<AssessmentsPage />}
                  />
                  <Route path={BACKPACK} element={<BackpackPage />} />
                  <Route path={CALENDAR} element={<CalendarPage />} />
                  <Route
                    path={`${CALENDAR}/:groupSlug`}
                    element={<CalendarPageClassMobile />}
                  />

                  <Route
                    path={TIMETABLE_PAGE(":profileId")}
                    element={<TimetablePage />}
                  />
                  <Route path={NOTICES} element={<NoticesPage />}>
                    <Route
                      path={`${SCHOOLS}/:schoolId/:noticesPageSelectedTab`}
                      element={<TabSelectorPage />}
                    />
                    <Route
                      path={`${CHILDREN}/:childID/:noticesPageSelectedTab`}
                      element={<TabSelectorPage />}
                    />
                  </Route>
                  {/* ---Parent View */}

                  {/* My Children */}
                  <Route path={MY_CHILDREN_PORTFOLIO}>
                    <Route index element={<MyChildrenOverviewPage />} />
                    <Route path=":childID" element={<MyChildrenPage />}>
                      <Route path={PROFILE} element={<ChildProfileScreen />} />
                      <Route
                        path={DEVELOPMENTAL_GOALS}
                        element={
                          <PortfolioDevelopmentalGoalsScreen isParentView />
                        }
                      />
                      <Route
                        path={`${DEVELOPMENTAL_GOALS}/:h3TabSlug`}
                        element={
                          <PortfolioDevelopmentalGoalsScreen isParentView />
                        }
                      />
                      <Route
                        path="progress-report"
                        element={<PortfolioProgressReportScreen isParentView />}
                      />
                      <Route
                        path={slugify(COMPLETED_WORKS)}
                        element={<ChildCompletedWorksScreen isParentView />}
                      />
                      <Route path="photo-album">
                        <Route index element={<PortfolioPhotoAlbumScreen />} />
                        <Route
                          path="all-photos"
                          element={<AllPhotosScreen isParentView />}
                        />
                        <Route
                          path="albums"
                          element={<AlbumsScreen isParentView />}
                        />
                      </Route>
                      <Route
                        path="project-summary"
                        element={<ChildPortfolioProjectSummaryScreen />}
                      />
                    </Route>
                  </Route>
                  {/* Parent View ---*/}

                  <Route
                    path={TEACHER_TRAINING_ACADEMY}
                    element={<TeacherPage />}
                  />
                  <Route
                    path={EVALUATION_QUESTIONNAIRES}
                    element={<EvaluationQuestionnairePage />}
                  />
                  <Route
                    path={PROFILE_PAGE(":profileId")}
                    element={<ProfilePage />}
                  />
                  <Route
                    path={PROFILE_CERTIFICATE_PAGE(
                      ":profileId",
                      ":credentialId"
                    )}
                    element={<TeacherCertificateLandingPagev2 />}
                  />

                  <Route path={WELCOME_BACK_PAGE} element={<Dashboard />} />
                  <Route
                    path={CHANGE_PASSWORD}
                    element={<ChangePasswordPage />}
                  />
                  <Route path={SCHOOLS} element={<SchoolPage />} />
                  <Route
                    path={CREATE_TICKET_PAGE}
                    element={<CreateTicketPage />}
                  />
                  <Route element={<SiteAdminOnlyRoute isAuth={!!profileID} />}>
                    <Route
                      path={TICKETING_SYSTEM}
                      element={<ViewTicketPage />}
                    />
                    <Route
                      path={MANAGE_PROHIBITED_WORD}
                      element={<ViewProhibitedWordPage />}
                    />
                  </Route>
                  {/* Admin only */}
                  <Route element={<AdminOnlyRoute isAuth={!!profileID} />}>
                    {/* Site Admin Page */}
                    <Route path={ADMIN_PAGE} element={<AdminPage />}>
                      {/* <Route index element={<SchoolManagePage />} /> */}
                      <Route path={"school"} element={<SchoolManagePage />} />
                      <Route path={"parent"} element={<ParentManagePage />} />
                      <Route path={"teacher"} element={<TeacherManagePage />} />
                      <Route path={"child"} element={<ChildManagePage />} />

                      {/* <Route path={"access"} element={<ManageSchoolProjectPage/>} /> */}
                      {/* <Route path={"group"} element={<GroupManagePage />} /> */}
                      {/* <Route
                        path={"interest"}
                        element={<InterestManagePage />}
                      /> */}
                      <Route
                        path={"payment-history"}
                        element={<PaymentHistoryComponent />}
                      />
                      <Route
                        element={<SiteAdminOnlyRoute isAuth={!!profileID} />}
                      >
                        <Route
                          path={"site-admin"}
                          element={<SiteAdminManagePage />}
                        />
                        <Route
                          path={"school-admin"}
                          element={<SchoolAdminManagePage />}
                        />
                        <Route
                          path={"trehaus-curriculum-specialist"}
                          element={<TrehausCurriculumSpecialistManagePage />}
                        />
                        <Route
                          path={"content-editor"}
                          element={<ContentEditorManagePage />}
                        />
                        <Route
                          path={"tta-content-editor"}
                          element={<TTAContentEditorManagePage />}
                        />
                        <Route
                          path={"payment-record"}
                          element={<PaymentRecordManagePage />}
                        />

                        {/* <Route path={"ticket"} element={<TicketManagePage />} /> */}
                        {/* <Route
                          path={"pre-dq-question"}
                          element={<PreDQQuestionaManagePage />}
                        /> */}
                      </Route>
                    </Route>
                  </Route>
                  {/* School */}
                  <Route
                    path={SCHOOL_PROFILE}
                    element={<SchoolProfilePage />}
                  />
                  <Route
                    path={SCHOOL_DETAIL(":schoolSlug")}
                    element={<SchoolDetailPage />}
                  />
                  <Route path={CREATE_SCHOOL} element={<CreateSchoolPage />} />
                  <Route
                    path={EDIT_SCHOOL(":schoolSlug")}
                    element={<CreateSchoolPage />}
                  />
                  <Route path={ACCOUNT_PAGE} element={<AccountPage />} />
                  <Route path={SETTINGS_PAGE} element={<SettingsPage />}>
                    <Route path={ACCOUNT_PAGE} element={<AccountPage />} />
                    <Route
                      path={EMAIL_PREFERENCES_PAGE}
                      element={<EmailPreferencesPage />}
                    />
                  </Route>
                  <Route
                    path={SCHOOL_ACCOUNT_PAGE(":schoolSlug")}
                    element={<AccountPage />}
                  />
                  {/* Group */}
                  <Route path={CLASS} element={<GroupsPage />}>
                    <Route path=":groupSlug" element={<GroupPage />}>
                      <Route path="attendance" element={<AttendancePage />} />
                    </Route>
                  </Route>
                  <Route
                    path={CREATE_GROUP_PAGE}
                    element={<CreateGroupPage create={true} />}
                  />
                  <Route
                    path={EDIT_GROUP_PAGE(":groupSlug")}
                    element={<EditGroupPage create={false} />}
                  />
                  <Route
                    path={CHILDREN_PORTFOLIO}
                    element={<ChildrenPortfolioOverviewPage />}
                  >
                    <Route path=":groupSlug" element={<ClassInfoPage />} />
                  </Route>

                  <Route
                    path={CHILDREN_PORTFOLIO_PAGE(":groupSlug", ":childID")}
                    element={<ChildrenPortfolioPage />}
                  >
                    <Route path={PROFILE} element={<ChildProfileScreen />} />
                    <Route
                      path={DEVELOPMENTAL_GOALS}
                      element={<PortfolioDevelopmentalGoalsScreen />}
                    />
                    <Route
                      path={`${DEVELOPMENTAL_GOALS}/:h3TabSlug`}
                      element={<PortfolioDevelopmentalGoalsScreen />}
                    >
                      <Route
                        path=":devGoal"
                        element={<DevelopmentalGoalPortfolio />}
                      />
                    </Route>
                    <Route path="progress-report">
                      <Route
                        index
                        element={<PortfolioProgressReportScreen />}
                      />
                      <Route
                        path="create"
                        element={<CreateChildrenPortfolioPage />}
                      />
                      <Route
                        path="create/:fileId"
                        element={<CreateChildrenPortfolioPage />}
                      />
                    </Route>
                    <Route path={slugify(COMPLETED_WORKS)} element={<ChildCompletedWorksScreen />} />
                    <Route
                        path={slugify(LEARNING_STORIES)}
                        element={<LearningStoriesScreen isParentView/>}
                    />
                    <Route path="photo-album">
                      <Route index element={<PortfolioPhotoAlbumScreen />} />
                      <Route path="all-photos" element={<AllPhotosScreen />} />
                      <Route path="albums" element={<AlbumsScreen />} />
                    </Route>
                    <Route
                      path="project-summary"
                      element={<ChildPortfolioProjectSummaryScreen />}
                    />
                    {/* <Route path="learning-stories" element={<ChildPortfolioLearningStoriesScreen />} /> */}
                  </Route>

                  {/* Teacher's Archive */}
                  <Route
                    path={TEACHER_PORTFOLIO}
                    element={<TeacherArchivePage />}
                  >
                    <Route
                      path=":teacherArchiveTab"
                      element={<TeacherArchiveLandingPage />}
                    >
                      <Route
                        path=":categoryTabType"
                        element={<TeacherArchiveTabCategoryLandingPage />}
                      />
                    </Route>
                  </Route>

                  {/* Project */}
                  <Route element={<EditorOnlyRoute isAuth={!!profileID} />}>
                    <Route
                      path={CREATE_PROJECT_PAGE}
                      element={<CreateProjectPage />}
                    />
                    <Route
                      path={EDIT_PROJECT_PAGE(":projectSlug")}
                      element={<EditProjectPage />}
                    />
                    <Route
                      path={CREATE_PROJECT_OBJECTIVE_PAGE(":projectSlug")}
                      element={<CreateObjectivePage />}
                    />
                    <Route
                      path={EDIT_PROJECT_OBJECTIVE_PAGE(":projectSlug")}
                      element={<EditObjectivePage />}
                    />
                  </Route>
                  <Route
                    element={
                      <EditorOnlyRouteWithoutTrehaus isAuth={!!profileID} />
                    }
                  >
                    <Route
                      path={EDIT_DEVELOPMENTAL_GOAL}
                      element={<EditDevelopmentalGoalPage />}
                    />
                  </Route>
                  <Route
                    path={PROJECT_SUMMARY_PAGE(":projectSlug")}
                    element={<ProjectSummaryPage />}
                  >
                    <Route index element={<OverviewScreen />} />
                    <Route path={"overview"} element={<OverviewScreen />} />
                    <Route
                      path={slugify(UNIT_GUIDE)}
                      element={<ProjectUnitGuide />}
                    />
                    <Route
                      path={"lesson-plans"}
                      element={<LessonPlanScreen teacher={false} />}
                    />
                    <Route
                      path={"lesson/:lessonID"}
                      element={<LessonActivityPage />}
                    >
                      <Route
                        path={"activity/:activityID"}
                        element={<LessonActivityScreen />}
                      />
                    </Route>

                    <Route
                      path={"learning-moments"}
                      element={<LearningMomentScreen />}
                    />
                    <Route
                      path={"teaching-materials"}
                      element={<TeachingMaterialsScreen />}
                    />
                    <Route
                      path={slugify(COMPLETED_WORKS)}
                      element={<CompletedWorksScreen />}
                    />
                    <Route
                      path={"milestone-check"}
                      element={<CheckpointScreen />}
                    />
                    <Route
                      path={"photo-album"}
                      element={<ProjectPhotoAlbumScreen />}
                    />
                    <Route
                      path={slugify(WHAT_PEOPLE_LIKE)}
                      element={<WhatDoPeopleLikeScreen />}
                    />
                    <Route path={"end"} element={<ProjectSummaryScreen />} />
                    <Route path={"report"} element={<ProjectReportScreen />} />
                  </Route>
                  {LittleEvaluatorRoutes}
                  <Route element={<AdminOnlyRoute isAuth={!!profileID} />}>
                    {/* Teacher */}
                    <Route
                      path={CREATE_TEACHER_PAGE}
                      element={<CreateProfilePage role={"TR"} />}
                    />
                    {/* Parent */}
                    <Route
                      path={CREATE_PARENT_PAGE}
                      element={<CreateProfilePage role={"PR"} />}
                    />
                    {/* Child */}
                    <Route
                      path={CREATE_CHILD_PAGE}
                      element={<CreateChildPage />}
                    />
                    <Route
                      path={UPDATE_CHILD_PAGE(":childID")}
                      element={<UpdateChildPage />}
                    />
                  </Route>
                  <Route element={<SiteAdminOnlyRoute isAuth={!!profileID} />}>
                    {/* Site Admin */}
                    <Route
                      path={CREATE_SITE_ADMIN_PAGE}
                      element={<CreateProfilePage role={"WAD"} />}
                    />
                    {/* School Admin */}
                    <Route
                      path={CREATE_SCHOOL_ADMIN_PAGE}
                      element={<CreateProfilePage role={"AD"} />}
                    />
                    {/* Content Editor */}
                    <Route
                      path={CREATE_CONTENT_EDITOR_PAGE}
                      element={<CreateProfilePage role={"CE"} />}
                    />
                  </Route>
                  <Route
                    path={ENROLL_CHILD_PAGE}
                    element={<EnrollChildPage />}
                  />
                  {/* <Route
                    path={`${MY_CLASS}/:childID`}
                    element={<PortfolioPage />}
                  /> */}
                  {/* Project Lesson */}
                  <Route
                    path={EDIT_LESSON_PAGE(":projectSlug", ":lessonID")}
                    element={<EditLessonPage />}
                  />
                  <Route
                    path={EDIT_LESSON_PAGE_2(":projectSlug")}
                    element={<CreateLessonPage />}
                  />
                  <Route
                    path={CREATE_LESSON_PAGE(":projectSlug")}
                    element={<CreateLessonPage />}
                  />
                  {/* Project Activity */}
                  <Route
                    path={CREATE_ACTIVITY_PAGE(":projectSlug", ":lessonID")}
                    element={<EditActivityPage />}
                  />
                  <Route
                    path={EDIT_ACTIVITY_PAGE(
                      ":projectSlug",
                      ":lessonID",
                      ":activityID"
                    )}
                    element={<EditActivityPage />}
                  />
                  {/* TTA start - Teacher Training Academy */}
                  <Route
                    path={TEACHER_MODULE_PAGE(":moduleSlug")} //moduleSlug
                    element={<TeacherModuleSummaryPage />}
                  >
                    {/* <Route index element={<OverviewScreen />} /> */}
                    {/* <Route path={"overview"} element={<OverviewScreen />} /> */}
                    {/* <Route
                      path={"lesson-plans"}
                      element={<LessonPlanScreen teacher={false} />}
                    /> */}
                    {/* <Route path={"teachingMaterials"} element={<TeachingMaterialsScreen />} /> */}
                    <Route
                      path={"discussion"}
                      element={<TeacherModuleDiscussionScreen />}
                    />
                    <Route
                      path={"discussion/:lessonSlug/"}
                      element={<TeacherLessonDiscussionScreen />}
                    />
                    <Route
                      path={":lessonSlug/:activitySlug"}
                      element={<TeacherLessonActivityScreen />}
                    />
                  </Route>
                  <Route
                    path={TTA_PROFILE_PAGE(":profileId")}
                    element={<ProfilePage />}
                  />
                  <Route
                    path={TTA_PROFILE_CERTIFICATE_PAGE(
                      ":profileId",
                      ":credentialId"
                    )}
                    element={<TeacherCertificateLandingPagev2 />}
                  />
                  <Route path={MY_PROGRESS} element={<TeacherProgressPage />} />
                  <Route path={OUR_STORY} element={<OurStory />} />
                  <Route path={COMMUNITY} element={<TeacherCommunityPage />} />
                  <Route
                    path={COMMUNITY_KANBAN_BOARD_PAGE(":kanbanBoardSlug")}
                    element={<TeacherKanbanBoardPage />}
                  />
                  <Route
                    path={CREATE_TEACHER_MODULE_PAGE} //moduleSlug
                    element={<CreateModuleForm />}
                  />
                  <Route
                    path={EDIT_TEACHER_MODULE_PAGE(":moduleSlug")} //moduleSlug
                    element={<EditModulePage />}
                  />
                  <Route
                    path={TEACHER_MODULE_MANAGE_PAGE_WITH_MODE(":pageModeSlug")} //moduleSlug
                    element={<ManageTeacherModulePage />}
                  />
                  <Route
                    path={TEACHER_CERTIFICATE_MANAGE_PAGE} //moduleSlug
                    element={<ManageTeacherCertificatePage />}
                  />
                  <Route
                    path={TEACHER_CERTIFICATE_CREATE_PAGE} //moduleSlug
                    element={<CreateEditCertificatePage />}
                  />
                  <Route
                    path={TEACHER_CERTIFICATE_EDIT_PAGE(":certificateSlug")} //moduleSlug
                    element={<CreateEditCertificatePage />}
                  />

                  {/* TTA end */}
                  {/* Pre-Discovery Questionnaire */}
                  <Route
                    path={PRE_DISCOVERY_QUESTIONNAIRE(":questionnaireSlug")}
                    element={<PreDiscoveryQuestionnaireDetail />}
                  />
                  <Route
                    path={CREATE_INTEREST}
                    element={<CreateInterestPage />}
                  />
                  <Route
                    path={CREATE_PRE_DQ_QUESTION}
                    element={<CreateQuestionPage />}
                  >
                    <Route
                      path={CREATE_PRE_DQ_ANSWER}
                      element={<CreateAnswerPage />}
                    />
                  </Route>
                  {/* Evaluation Questionnaire */}
                  <Route
                    path={EVALUATION_QUESTIONNAIRE_CREATE}
                    element={<EditEvaluationQuestionnaire create={true} />}
                  />
                  <Route
                    path={EVALUATION_QUESTIONNAIRE_EDIT(":questionnaireSlug")}
                    element={<EditEvaluationQuestionnaire />}
                  />
                  <Route
                    path={EVALUATION_QUESTIONNAIRE_QUESTION_CREATE(
                      ":questionnaireSlug"
                    )}
                    element={<EditEvaluationQuestion create={true} />}
                  />
                  <Route
                    path={EVALUATION_QUESTIONNAIRE_QUESTION_EDIT(
                      ":questionnaireSlug",
                      ":questionSlug"
                    )}
                    element={<EditEvaluationQuestion />}
                  />
                  <Route
                    path={EVALUATION_QUESTIONNAIRE_ANSWER_CREATE(
                      ":questionnaireSlug",
                      ":questionSlug"
                    )}
                    element={<EditEvaluationAnswer create={true} />}
                  />
                  <Route
                    path={EVALUATION_QUESTIONNAIRE_ANSWER_EDIT(
                      ":questionnaireSlug",
                      ":questionSlug",
                      ":answerSlug"
                    )}
                    element={<EditEvaluationAnswer />}
                  />
                  {/* Access Control */}
                  {/* <Route
                    path={PROJECT_SCHOOL_MANAGE_PAGE_WITH_SLUG(":schoolSlug")}
                    element={<ManageSchoolProjectPage />}
                  /> */}
                  <Route
                    path={PROJECT_SCHOOL_MANAGE_PAGE_WITH_PAGE_MODE_SLUG(
                      ":schoolSlug",
                      ":pageModeSlug"
                    )}
                    element={<ManageSchoolProjectPage />}
                  />
                  <Route
                    path={PROJECT_SCHOOL_MANAGE_PAGE_WITH_SLUG(":schoolSlug")}
                    element={<ManageSchoolProjectPage />}
                  />
                  <Route
                    path={PROJECT_SCHOOL_MANAGE_PAGE}
                    element={<ManageSchoolProjectPage />}
                  />
                  <Route
                    path={PROJECT_MANAGE_PAGE}
                    element={<ManageSchoolProjectPage />}
                  />
                  {/* Payment */}
                  <Route path={PAYMENT} element={<PaymentPage />} />
                  {/* 
                  <Route
                    path={PAYMENT_STATUS}
                    element={<PaymentStatusPage />}
                  /> */}
                  <Route path={CHATHOME} element={<Chat />} />
                  <Route path={CHAT(":chatID")} element={<Chat />} />
                </Route>
                <Route path={LOGIN} element={<LoginPage />} />
                <Route path={REGISTER} element={<RegisterPage />} />
                <Route
                  path={FORGOTTEN_PASSWORD}
                  element={<ForgotPasswordPage />}
                />
                <Route
                  path={RESET_PASSWORD(":uid", ":token")}
                  element={<ResetPasswordPage />}
                />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Routes>

            <Footer />

            <ScrollToTop />
          </Box>
          <Toast />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
