import React, { useEffect, useState } from "react";
import {
  Switch,
  Typography,
  Box,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import useStore from "store/store";
import Image from "components/Image/Image";

import {
  createChildAchievedMilestoneTracker,
  getChildAchievedMilestoneTracker,
  getChildPortfolioObservation,
  updateChildAchievedMilestoneTracker,
} from "api/portfolio-api";

import { useLocation, useParams } from "react-router-dom";
import { API_Milestone } from "types/project";
import { API_Child } from "types/portfolio";
import { getAllFundamentals } from "api/fundamental-api";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";
import useSnack from "hooks/useSnack";
// import MilestonePhotoGalleryComponent from "./Screens/DevelopmentalGoalsScreenComponents/MilestonePhotoGalleryComponent";
import CreateObservationModal from "./Screens/DevelopmentalGoalsScreenComponents/CreateObservationModal";
import MilestonePhotoGalleryComponent from "./Screens/DevelopmentalGoalsScreenComponents/MilestonePhotoGalleryComponent";

const DevelopmentalGoalPortfolio = () => {
  const { groupSlug, childID, h3TabSlug } = useParams();
  const { pathname } = useLocation();
  const openSnack = useSnack();
  const headerDisplay = (header: string) => {
    const noHyphens = header.replace(/-/g, " ");
    const words = noHyphens.split(" ");
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  };

  const {
    currLanguage,
    setIsRefreshLatestMilestone,
    isRefreshPortfolioObservation,
    setOpen,
  } = useStore((state) => ({
    open: state.isPortfolioMiniDrawerOpen,
    setOpen: state.setIsPortfolioMiniDrawerOpen,
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    isRefreshLatestMilestone: state.isRefreshLatestMilestone,
    setIsRefreshLatestMilestone: state.setIsRefreshLatestMilestone,
    isRefreshPortfolioObservation: state.isRefreshPortfolioObservation,
    setIsRefreshPortfolioObservation: state.setIsRefreshPortfolioObservation,
    isCompactView: state.isCompactView,
    setIsCompactView: state.setIsCompactView,
  }));

  const [childPortfolioObservationList, setChildPortfolioObservationList] =
    useState<API_Child.PortfolioObservation[]>([]);
  const [childAchievedMilestoneTracker, setChildAchievedMilestoneTracker] =
    useState<API_Child.AchievedMilestoneTracker[]>([]);
  const [subFundamentals, setSubFundamentals] = useState<any>();
  const [relatedMilestones, setRelatedMilestones] = useState<API_Milestone[]>(
    []
  );
  const [developmentalGoal, setDevelopmentalGoal] = useState<any>();
  const [isCreateObservationModalOpen, setIsCreateObservationModalOpen] =
    useState<boolean>(false);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState<number>(-1);
  const handleCreateObservationModalClose = () => {
    setIsCreateObservationModalOpen(false);
  };
  const [openAccordion, setOpenAccordion] = useState(null);
  const [selectedSubFundamental, setSelectedSubFundamental] = useState(null);

  const devGoal = pathname.split("/").pop();

  const populateFundamentals = async () => {
    const res = await getAllFundamentals(
      currLanguage,
      h3TabSlug!,
      Number(childID)
    );
    res.sort((a, b) => b.priority - a.priority);
    if (typeof res !== "string") {
      if (devGoal) {
        const foundObject = res.find(
          (obj) => obj.name === headerDisplay(devGoal)
        );
        if (foundObject && foundObject.name != "Self Regulation") {
          if (
            Array.isArray(foundObject?.sub_fundamentals) &&
            foundObject.sub_fundamentals.length > 0
          ) {
            setOpen(false);
            if (
              devGoal == "english-language-&-literacy" ||
              devGoal == "numeracy-&-math-concepts"
            ) {
              const groupedByFirstWord = foundObject?.sub_fundamentals
                .sort((a, b) => a.priority - b.priority)
                .reduce((acc: any, obj: any) => {
                  const splitChar =
                    devGoal === "numeracy-&-math-concepts" ? "-" : " ";
                  const firstWord = obj.name.split(splitChar)[0];

                  if (!acc[firstWord]) {
                    acc[firstWord] = [];
                  }

                  obj.name = obj.name
                    .split(splitChar)
                    .slice(1)
                    .join(" ")
                    .replace(/-/g, "");
                  acc[firstWord].push(obj);

                  return acc;
                }, {});

              console.log(groupedByFirstWord);
              setSubFundamentals(groupedByFirstWord);
              setDevelopmentalGoal(
                (Object.values(groupedByFirstWord)[0] as any)[0]
              );
              setRelatedMilestones(
                (Object.values(groupedByFirstWord)[0] as any)[0].milestones
              );
            } else {
              const subFundamentals = foundObject.sub_fundamentals?.sort(
                (a: any, b: any) => a.priority - b.priority
              );
              setSubFundamentals(subFundamentals);
              setDevelopmentalGoal(subFundamentals[0]);
              setRelatedMilestones(subFundamentals[0].milestones);
            }
          } else {
            setOpen(true);
            setDevelopmentalGoal(foundObject);
            setRelatedMilestones(foundObject.milestones);
          }
        } else {
          if (headerDisplay(devGoal) == "Self Regulation") {
            let selfRegulation = res.find(
              (obj) => obj.name == "Self-Regulation"
            );
            if (selfRegulation) {
              setDevelopmentalGoal(selfRegulation);
              setRelatedMilestones(selfRegulation.milestones);
            }
          } else if (
            headerDisplay(devGoal) ==
            "Steam (science, Technology, Engineering, Art, Math)"
          ) {
            let steam = res.find(
              (obj) =>
                obj.name ==
                "STEAM (Science, Technology, Engineering, Art, Math)"
            );
            if (steam) {
              setDevelopmentalGoal(steam);
              setRelatedMilestones(steam.milestones);
            }
          } else if (devGoal.includes("mandarin")) {
            const mandarinDevGoal = res.find((obj) =>
              obj.name.includes("Mandarin")
            );
            if (mandarinDevGoal && mandarinDevGoal.sub_fundamentals) {
              const groupedByFirstWord =
                mandarinDevGoal?.sub_fundamentals?.reduce(
                  (acc: any, obj: any) => {
                    const splitChar = "-";
                    const firstWord = obj.name.split(splitChar)[0];

                    if (!acc[firstWord]) {
                      acc[firstWord] = [];
                    }

                    // Remove the hyphen and join the remaining part of the name
                    obj.name = obj.name.split(splitChar).slice(1).join(" ");

                    acc[firstWord].push(obj);
                    return acc;
                  },
                  {}
                );
              setSubFundamentals(groupedByFirstWord);
              setDevelopmentalGoal(mandarinDevGoal.sub_fundamentals[0]);
              setRelatedMilestones(
                mandarinDevGoal.sub_fundamentals[0].milestones
              );
            }
          }
        }
      }
    } else {
      setRelatedMilestones([]);
    }
    setIsRefreshLatestMilestone(false);
  };

  // DEVELOPMENTALGOAL NOT DEFINED OLIVER
  const populateChildAchievedMilestone = async () => {
    if (developmentalGoal) {
      const res = await getChildAchievedMilestoneTracker(
        currLanguage,
        Number(childID),
        developmentalGoal?.id
      );

      if (typeof res !== "string") {
        setChildAchievedMilestoneTracker(res);
      }
    }
  };

  const populateChildPortfolioObservation = async () => {
    if (developmentalGoal) {
      const observationRes = await getChildPortfolioObservation({
        language: currLanguage,
        childId: Number(childID),
        developmentalGoalTag: h3TabSlug!,
        developmentalGoalId: developmentalGoal.id,
        isShowAll: true,
      });

      if (typeof observationRes !== "string") {
        setChildPortfolioObservationList(observationRes);
      } else {
        setChildPortfolioObservationList([]);
      }
    }
  };

  const handleMilestoneTrackerChange = async (
    tracker: API_Child.AchievedMilestoneTracker | null,
    milestoneId: number
  ) => {
    if (tracker) {
      const updatedTracker: API_Child.AchievedMilestoneTracker = JSON.parse(
        JSON.stringify(tracker)
      );
      updatedTracker.status = !updatedTracker.status;
      const res = await updateChildAchievedMilestoneTracker(updatedTracker);

      if (typeof res !== "string") {
        const trackerCopy = JSON.parse(
          JSON.stringify(childAchievedMilestoneTracker)
        );
        const index = childAchievedMilestoneTracker.findIndex((tracker_) => {
          return tracker_.id === res.id;
        });
        trackerCopy[index].status = res.status;
        setChildAchievedMilestoneTracker(trackerCopy);
        if (res.status) {
          openSnack("Attained!", true);
        } else {
          openSnack("Un-attained!", true);
        }
      }
    } else {
      const res = await createChildAchievedMilestoneTracker(
        Number(childID),
        milestoneId,
        null
      );
      if (typeof res !== "string") {
        setChildAchievedMilestoneTracker((prev) => [...prev, res]);
        openSnack("Attained!", true);
      }
    }

    setIsRefreshLatestMilestone(true);
  };


  useEffect(() => {
    populateFundamentals();
  }, []);
  useEffect(() => {
    populateChildAchievedMilestone();
    populateChildPortfolioObservation();
  }, [childID, h3TabSlug, currLanguage, developmentalGoal]);

  useEffect(() => {
    isRefreshPortfolioObservation && populateChildPortfolioObservation();
  }, [isRefreshPortfolioObservation]);

  return (
    <>
      <CreateObservationModal
        open={isCreateObservationModalOpen}
        handleClose={handleCreateObservationModalClose}
        milestoneId={selectedMilestoneId}
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        {subFundamentals &&
          typeof subFundamentals === "object" &&
          (Array.isArray(subFundamentals)
            ? subFundamentals.length > 0
            : Object.keys(subFundamentals).length > 0) && (
            <Box
              sx={{ width: "20%", border: "1px solid #91C6C5", height: "100%" }}
              onClick={() => {}}
            >
              {/* Drawer */}
              {devGoal == "english-language-&-literacy" ||
              devGoal?.includes("mandarin") ||
              devGoal == "numeracy-&-math-concepts"
                ? Object.keys(subFundamentals).map((key: any) => {
                    if (key in subFundamentals) {
                      if (
                        key == "Patterns" ||
                        key == "偏旁部首" ||
                        key == "汉语拼音" ||
                        key == "笔画"
                      ) {
                        return (
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              padding: 2,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenAccordion(null);
                              setDevelopmentalGoal(subFundamentals[key][0]);
                              setRelatedMilestones(
                                subFundamentals[key][0].milestones
                              );
                            }}
                          >
                            {key}
                          </Typography>
                        );
                      } else {
                        return (
                          <Accordion
                            disableGutters
                            expanded={openAccordion === key}
                            onChange={() =>
                              setOpenAccordion(
                                openAccordion === key ? null : key
                              )
                            }
                            sx={{
                              borderBottom: "none !important",
                              "&:before": {
                                display: "none",
                              }, // This removes the divider line
                            }}
                            key={key}
                          >
                            <AccordionSummary
                              sx={{
                                borderBottom: "none !important", 
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center", 
                                "& .MuiAccordionSummary-content": {
                                  margin: 0,
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  flex: 1,
                                }}
                              >
                                {key}
                              </Typography>
                              {openAccordion === key ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {subFundamentals[key].map(
                                  (subFundamental: any) => {
                                    return (
                                      <Box
                                        sx={{
                                          border: "1px solid #C4E4DF",
                                          borderRadius: "4px",
                                          p: "16px",
                                          backgroundColor:
                                            selectedSubFundamental ===
                                            subFundamental
                                              ? "#C4E4DF"
                                              : "white",
                                          "&:hover": {
                                            backgroundColor: "#C4E4DF ",
                                          },
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: "450",
                                          }}
                                          onClick={() => {
                                            setDevelopmentalGoal(
                                              subFundamental
                                            );
                                            setRelatedMilestones(
                                              subFundamental.milestones
                                            );
                                            setSelectedSubFundamental(
                                              subFundamental
                                            );
                                          }}
                                        >
                                          {subFundamental.name}
                                        </Typography>
                                      </Box>
                                    );
                                  }
                                )}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        );
                      }

                      // END PATTERNS
                    }
                  })
                : Array.isArray(subFundamentals) &&
                  subFundamentals.map((subFundamental: any) => {
                    return (
                      <Box
                        sx={{
                          borderBottom: "1px solid black",
                          backgroundColor:
                            selectedSubFundamental === subFundamental
                              ? "#C4E4DF"
                              : "white",
                          "&:hover": { backgroundColor: "#C4E4DF " },
                        }}
                        onClick={() => {
                          setRelatedMilestones(subFundamental.milestones);
                          setDevelopmentalGoal(subFundamental);
                          setSelectedSubFundamental(subFundamental);
                        }}
                      >
                        <Typography
                          sx={{
                            p: 2,
                            fontSize: "16px",
                            fontWeight: "700",
                          }}
                        >
                          {subFundamental.name}
                        </Typography>
                      </Box>
                    );
                  })}
            </Box>
          )}

        <Box
          sx={{
            border: "1px solid #91C6C5",
            height: "100%",
            borderRadius: "8px",
            flex: 1,
            py: "16px",
            px: "32px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "20%" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                Milestone Level
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, minWidth: "72%" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                Description
              </Typography>
            </Box>
            <Box
              sx={{ width: "15%", display: "flex", justifyContent: "center" }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                Attained?
              </Typography>
            </Box>
          </Box>
          <Box sx={{}}>
            {relatedMilestones.map((milestone, id) => {
              const tracker = childAchievedMilestoneTracker.find((tracker) => {
                return tracker.milestone === milestone.id;
              });
              const observations = childPortfolioObservationList.filter(
                (observation) => observation.milestone.id === milestone.id
              );
              return (
                <DevelopmentalGoalMilestone
                  key={id}
                  milestone={milestone}
                  developmentalGoal={developmentalGoal}
                  handleMilestoneTrackerChange={handleMilestoneTrackerChange}
                  tracker={tracker}
                  setSelectedMilestoneId={setSelectedMilestoneId}
                  setIsCreateObservationModalOpen={
                    setIsCreateObservationModalOpen
                  }
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DevelopmentalGoalPortfolio;

const DevelopmentalGoalMilestone = ({
  milestone,
  developmentalGoal,
  tracker,
  setSelectedMilestoneId,
  setIsCreateObservationModalOpen,
  handleMilestoneTrackerChange,
}: {
  milestone: any;
  developmentalGoal: any;
  tracker: any;
  setSelectedMilestoneId: React.Dispatch<React.SetStateAction<number>>;
  setIsCreateObservationModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleMilestoneTrackerChange: (
    tracker: API_Child.AchievedMilestoneTracker | null,
    milestoneId: number
  ) => Promise<void>;
}) => {

 

  return (
    <Box sx={{ display: "flex", mb: 2 }} onClick={() => {}}>
      <Divider />
      <Box
        sx={{ width: "20%", display: "flex", gap: 1, alignItems: "flex-start" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "32px",
              height: "32px",
              overflow: "hidden",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                milestone.age == "Sprouts"
                  ? "#F3EBE4"
                  : milestone.age == "Buds"
                  ? "#91C6C5"
                  : "#F9DF60",
            }}
          >
            <Image
              style={{ width: "20px", height: "20px" }}
              src={
                milestone.age == "Sprouts"
                  ? SproutsIcon
                  : milestone.age == "Buds"
                  ? BudsIcon
                  : BlossomsIcon
              }
              alt="Level"
            />
          </Box>
          <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
            {milestone.age} level
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minWidth: "72%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
          {milestone.name}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <MilestonePhotoGalleryComponent
            developmentalGoal={developmentalGoal}
            milestone={milestone}
            setIsCreateObservationModalOpen={setIsCreateObservationModalOpen}
            setSelectedMilestoneId={setSelectedMilestoneId}
          />
          {/* MODAL HERE OLIVER */}
        </Box>
      </Box>
      <Box sx={{ width: "15%", display: "flex", justifyContent: "center" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
          <Switch
            inputProps={{ "aria-label": "controlled" }}
            checked={tracker ? tracker.status : false}
            onChange={() => {
              handleMilestoneTrackerChange(
                tracker ? tracker : null,
                Number(milestone.id)
              );
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};
