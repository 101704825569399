import { Box, Stack, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import useSnack from "../../../../../../hooks/useSnack";
import useStore from "../../../../../../store/store";

import Button from "../../../../../../components/Button/Button";

import { translation } from "../../../../../../constants/translation";

import useCallbackPrompt from "../../../../../../hooks/useCallbackPrompt";

import {
  NameField,
  PublishField,
  DescriptionField,
  ActivityTypeField,
  McqField,
  ActivityInstructionField,
} from "../../TeacherFormComponents/ActivityFormComponents";
import BlockLeavingDialog from "../../../../../../components/Dialog/BlockLeavingDialog";
import {
  API_Edit_Teacher_Activity,
  API_Mcq_Option,
  API_Mcq_Question,
  API_Teacher_Mcq_Activity,
} from "../../../../../../types/teacher";
import { editTeacherActivity } from "../../../../../../api/teacher-api";

import { DeleteActivityButton } from "./CommonActivityFormComponents";

const EditMcqActivityForm = ({
  activityId,
  lessonId,
  handleClose,
}: {
  activityId: number;
  lessonId: number;
  handleClose: () => void;
}) => {
  // Better to split this into different types of activities to prevent complexity.
  const { currLanguage, teacherCurrentActivity, setIsRefreshTeacherModule } =
    useStore((state) => ({
      currLanguage: state.currLanguage,
      teacherCurrentActivity:
        state.teacherCurrentActivity as API_Teacher_Mcq_Activity,
      setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
    }));
  const openSnack = useSnack();

  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [isCreatingActivity, setIsCreatingActivity] = useState<boolean>(false);
  const [mcqQuestions, setMcqQuestions] = useState<API_Mcq_Question[]>(
    teacherCurrentActivity.mcq_questions
      ? teacherCurrentActivity.mcq_questions
      : []
  );

  const [updatedActivity, setUpdatedActivity] =
    useState<API_Edit_Teacher_Activity>({
      id: activityId,
      lesson: lessonId,
      resourcetype: teacherCurrentActivity.resourcetype,
      name: teacherCurrentActivity.name,
      instruction: teacherCurrentActivity.instruction,
      description: teacherCurrentActivity.description,
      weight: teacherCurrentActivity.weight,
      is_published: teacherCurrentActivity.is_published,
    });

  const activityRef = useRef(updatedActivity);
  activityRef.current = updatedActivity;

  const submitEditForm = async () => {
    //Check
    if (updatedActivity.name === "" || mcqQuestions.length === 0) {
      //not filled
      openSnack("The Name and MCQ Questions are required!", false);
      return;
    }

    const isAllMcqQuestionFilled = mcqQuestions.every((mcqQuestion) => {
      return mcqQuestion.name !== "";
    });

    if (!isAllMcqQuestionFilled) {
      openSnack("All questions must be filled!", false);
      return;
    }

    const isAllMcqOptionFilled = mcqQuestions.every((mcqQuestion) => {
      return mcqQuestion.mcq_options.every((mcqOption) => {
        return mcqOption.name !== "";
      });
    });

    if (!isAllMcqOptionFilled) {
      openSnack("All options must be filled!", false);
      return;
    }

    let editFailed = false;
    const activityData: API_Edit_Teacher_Activity = {
      id: activityId,
      lesson: lessonId,
      resourcetype: teacherCurrentActivity.resourcetype,
    };

    if (updatedActivity.name !== teacherCurrentActivity.name)
      activityData.name = updatedActivity.name;
    if (updatedActivity.description !== teacherCurrentActivity.description)
      activityData.description = updatedActivity.description;
    if (updatedActivity.instruction !== teacherCurrentActivity.instruction)
      activityData.instruction = updatedActivity.instruction;
    if (updatedActivity.is_published !== teacherCurrentActivity.is_published)
      activityData.is_published = updatedActivity.is_published;

    activityData.mcq_questions = mcqQuestions;
    // activityData.back_text = updatedActivity.back_text;
    // activityData.front_text = updatedActivity.front_text;

    if (Object.keys(activityData).length > 0) {
      const res = await editTeacherActivity(
        currLanguage,
        activityData,
        setIsCreatingActivity,
        setProgressPercentage,
        localStorage.getItem("access")!
      );

      if (typeof res === "string") {
        // console.log("res: ", res);
        openSnack(`${translation.failUpdateActivity}: ${res}`, false);
        editFailed = true;
      }
    }

    if (!editFailed) {
      setIsRefreshTeacherModule(true);
      openSnack("Updated activity successfully!", true);
      handleClose();
    }
  };

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, showChanges, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const dialogBody = {
    showDialog: showPrompt,
    confirmNavigation,
    cancelNavigation,
    handleAction: submitEditForm,
  };
  // console.log("teacherCurrentActivity: ", teacherCurrentActivity);
  return (
    <>
      <BlockLeavingDialog {...dialogBody} />

      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <ActivityTypeField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          isDisabled
        />
        <PublishField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />
        <NameField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        <ActivityInstructionField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        <McqField
          activityId={activityId}
          mcqQuestions={mcqQuestions}
          setMcqQuestions={setMcqQuestions}
        />

        <Divider />

        <DeleteActivityButton
          activityId={activityId}
          handleClose={handleClose}
        />

        <Box
          sx={{
            display: "flex",
            gap: "24px",
            position: "fixed",
            bottom: 24,
            right: 34,
            zIndex: 10,
          }}
        >
          <Button
            buttonText={
              isCreatingActivity
                ? progressPercentage !== 100
                  ? `${progressPercentage}%`
                  : translation.uploadFinalizing
                : translation.update
            }
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
              height: "auto",
            }}
            onClick={() => {
              submitEditForm();
            }}
            disabled={isCreatingActivity}
          />
        </Box>
      </Stack>
    </>
  );
};

export default EditMcqActivityForm;
