import { Box, Typography } from "@mui/material";
import { API_Fundamental, API_Milestone } from "types/project";
import { API_Child } from "types/portfolio";
import MileStoneSection from "./MilestoneSection";
import IndividualObservation from "./IndividualObservation";
import Title from "./Title";
import { translation } from "constants/translation";

interface DevelopmentalGoalProps {
  devGoal: API_Fundamental & { next?: boolean };
  previousDevGoal?: API_Fundamental;
  observationList?: API_Child.PortfolioObservation[];
  scale?: number;
  milestonesAchieved: any[];
}

const DevelopmentalGoal = ({
  devGoal,
  previousDevGoal,
  observationList,
  scale = 1,
}: DevelopmentalGoalProps) => {
  const showTitle = devGoal && devGoal.id !== previousDevGoal?.id;
  const showCompletedMileStone = devGoal.milestones?.length > 0;
  const showNextMileStone = devGoal?.next_milestone && devGoal.next !== true;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: `${12 * scale}px`,
        alignItems: "flex-start",
        mb: `${24 * scale}px`,
        p: `${4 * scale}px`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ flex: 1 }}>
            {showTitle ? (
              <>
                <Title
                  image={devGoal.image}
                  name={devGoal.name}
                  scale={scale}
                  sx={{
                    mb: `${6 * scale}px`,
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {showCompletedMileStone && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "FuturaMediumBT",
                          fontWeight: 400,
                          fontSize: 12 * scale,
                          flexShrink: 0,
                          lineHeight: "16px",
                          mt: `${12 * scale}px`,
                          color: "#5C9391",
                        }}
                      >
                        {translation.milestoneCompleted}
                      </Typography>

                      {devGoal.milestones.map((milestone: API_Milestone) => (
                        <MileStoneSection
                          key={milestone.id}
                          descriptions={milestone.name}
                          scale={scale}
                        />
                      ))}
                    </Box>
                  )}
                  {/* {showNextMileStone && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "FuturaMediumBT",
                          fontWeight: 400,
                          fontSize: 12 * scale,
                          flexShrink: 0,
                          lineHeight: "16px",
                          color: "#5C9391",
                        }}
                      >
                        {translation.nextMilestone}
                      </Typography>
                      <MileStoneSection
                        descriptions={
                          devGoal?.next_milestone &&
                          typeof devGoal?.next_milestone !== "number"
                            ? devGoal?.next_milestone.name
                            : translation.milestoneCompletedCongratulation
                        }
                        scale={scale}
                      />
                    </Box>
                  )} */}
                </Box>
              </>
            ) : (
              <>
                <Title
                  image={devGoal?.image}
                  name={devGoal?.name}
                  scale={scale}
                />
                {showCompletedMileStone && (
                  <>
                    <Typography
                      sx={{
                        fontFamily: "FuturaMediumBT",
                        fontWeight: 400,
                        fontSize: 12 * scale,
                        flexShrink: 0,
                        lineHeight: "16px",
                        color: "#5C9391",
                        mt: `${12 * scale}px`,
                      }}
                    >
                      {translation.milestoneCompleted}
                    </Typography>
                    {devGoal.milestones.map((milestone: API_Milestone) => (
                      <MileStoneSection
                        key={milestone.id}
                        descriptions={milestone.name}
                        scale={scale}
                      />
                    ))}
                  </>
                )}
                {/* {showNextMileStone && (
                  <>
                    <Typography
                      sx={{
                        fontFamily: "FuturaMediumBT",
                        fontWeight: 400,
                        fontSize: 12 * scale,
                        flexShrink: 0,
                        lineHeight: "16px",
                        color: "#5C9391",
                        mt: `${12 * scale}px`,
                      }}
                    >
                      {translation.nextMilestone}
                    </Typography>
                    <MileStoneSection
                      descriptions={
                        devGoal?.next_milestone &&
                        typeof devGoal?.next_milestone !== "number"
                          ? devGoal?.next_milestone.name
                          : translation.milestoneCompletedCongratulation
                      }
                      scale={scale}
                    />
                  </>
                )} */}
              </>
            )}
          </Box>
          <Box
            sx={{
              marginLeft: "auto",
              flexShrink: 0,
              width: `${160 * scale}px`,
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            {observationList && observationList.length > 0 ? (
              <IndividualObservation
                observation={observationList[0]}
                scale={scale}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DevelopmentalGoal;
