import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  API_Project,
  API_Project_Fundamental,
  API_Project_Lesson,
} from "types/project";
import ModuleTag from "components/ModuleTag/ModuleTag";
import { translation } from "constants/translation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Image from "components/Image/Image";
import {
  Title,
  Duration,
  Description,
  CustomImage,
  CustomTabs,
  NoContent,
  LessonDetailCard,
} from "./CommonComponents";
import useStore from "store/store";
import { getAllFundamentals } from "api/fundamental-api";
// Project Preview View
const ProjectView = ({
  project,
  lessons,
  isParentView = false,
}: {
  project: API_Project;
  lessons: API_Project_Lesson[];
  isParentView?: boolean;
}) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleTabChange = (event: React.SyntheticEvent, index: number) =>
    setTabIndex(index);


  const {
    fundamentalDict,
    currGroup,
    currGroupBackpack,
    currLanguage,
    groupProjectBoxProps,
  } = useStore((state) => state);


  const isInBag =
    currGroupBackpack.projects.filter(
      (backpackProject) => backpackProject.id === project.id
    ).length !== 0;

  const isInSchedule =
    groupProjectBoxProps[currGroup.id] &&
    groupProjectBoxProps[currGroup.id].filter(
      (_project) => _project.projectId === project.id
    ).length !== 0;

  let projectCards: any = [];

  const flattenStructure = (items: any[]): any[] => {
    const seenItems = new Set();
    return items.reduce((acc, item) => {
      const shouldAddItem = !seenItems.has(item.name) && (!item.subFundamentals || !seenItems.has(item.subFundamentals.some((sub: any) => sub.name)));

      if (shouldAddItem) {
        acc.push(item);
        seenItems.add(item.name);
        if (item.subFundamentals) {
          item.subFundamentals.forEach((sub: any) => seenItems.add(sub.name));
        }
      }
      return acc;
    }, []);
  };

  const sortByPriority = (items: any[], comparator?: (a: any, b: any) => number): any[] => {
    return items.sort(comparator || ((a: any, b: any) => a.priority - b.priority));
  };
  const heart: any = [];
  const head: any = [];
  const hands: any = [];
  let targetArray: any[];
  project.objective_fundamentals.forEach((devGoal: any) => {
    const filteredMilestones = fundamentalDict[
      devGoal.fundamental
    ].milestones.filter((milestone) => milestone.id === devGoal.milestone);
    const fundamentalCard = fundamentalDict[filteredMilestones[0].fundamental]
    const objectiveFundamentalMilestone = filteredMilestones[0]
    const objectiveFundamentalMilestoneName =
      filteredMilestones.length > 0 ? filteredMilestones[0].name : "";
    if (fundamentalCard.main_fundamental == null) {

      switch (fundamentalCard.tag) {
        case 'heart':
          targetArray = heart;
          break;
        case "head":
          targetArray = head;
          break;
        case "hands":
          targetArray = hands;
          break;
        default:
          console.error("Unknown tag:", fundamentalCard.tag);
          return;
      }

      const projectCard = targetArray.find((project: any) => project.name == fundamentalCard?.name)

      if (!projectCard) {
        targetArray.push({
          name: fundamentalCard?.name,
          image: fundamentalCard?.image,
          objectives: {
            [objectiveFundamentalMilestone.name]: objectiveFundamentalMilestone.weight
          },
          priority: fundamentalCard?.priority,
          tag: fundamentalCard?.tag
        });
      }
      else if (
        !projectCard.objectives.hasOwnProperty(objectiveFundamentalMilestone.name)
      ) {
        projectCard.objectives[objectiveFundamentalMilestone.name] = objectiveFundamentalMilestone.weight;

      }
    } else if (fundamentalCard.main_fundamental) {

      if (fundamentalCard.main_fundamental) {
        const mainFundamentalCard = fundamentalDict[fundamentalCard.main_fundamental]
        switch (fundamentalCard.tag) {
          case 'heart':
            targetArray = heart;
            break;
          case "head":
            targetArray = head;
            break;
          case "hands":
            targetArray = hands;
            break;
          default:
            console.error("Unknown tag:", fundamentalCard?.tag);
            return; // Exit the loop if an unknown tag is encountered
        }
        const projectCard = targetArray.find((project: any) => project.name == mainFundamentalCard?.name)
        if (!projectCard) {
          targetArray.push({
            name: mainFundamentalCard?.name,
            image: mainFundamentalCard?.image,
            priority: mainFundamentalCard?.priority,
            tag: mainFundamentalCard?.tag,

            subFundamentals: [
              {
                name: devGoal.name,
                objectives: {
                  [objectiveFundamentalMilestone.name]: objectiveFundamentalMilestone.weight
                },
                priority: fundamentalDict[devGoal.fundamental].priority
              },
            ],
          });
        } else if (
          !projectCard.subFundamentals.find(
            (sub: any) => sub.name == devGoal.name
          )
        ) {
          projectCard.subFundamentals.push({
            name: devGoal.name,
            objectives: {
              [objectiveFundamentalMilestone.name]: objectiveFundamentalMilestone.weight
            },
            priority: fundamentalDict[devGoal.fundamental].priority

          });
        }
        else if (
          !projectCard.subFundamentals.find((sub: any) =>
            sub.objectives.hasOwnProperty(objectiveFundamentalMilestone.name)
          )
        ) {
          projectCard.subFundamentals
            .find((sub: any) => sub.name == devGoal.name)
            .objectives[objectiveFundamentalMilestone.name] = objectiveFundamentalMilestone.weight;
        }
      }
    }
  });
  const sortedHeart = sortByPriority(flattenStructure(heart));
  const sortedHead = sortByPriority(flattenStructure(head));
  const sortedHands = sortByPriority(flattenStructure(hands));

  projectCards = [
    ...sortedHeart.map(card => ({ ...card, subFundamentals: card.subFundamentals ? sortByPriority(card.subFundamentals) : [] })),
    ...sortedHead.map(card => ({ ...card, subFundamentals: card.subFundamentals ? sortByPriority(card.subFundamentals) : [] })),
    ...sortedHands.map(card => ({ ...card, subFundamentals: card.subFundamentals ? sortByPriority(card.subFundamentals) : [] }))
  ].flat();
  const tabs = isParentView
    ? project.guiding_question
      ? [
        {
          name: translation.developmentalGoals,
          content: (
            <DevelopmentalGoals
              objectiveFundamentals={project.objective_fundamentals}
              projectCards={projectCards}
              project={project}
            />
          ),
        },
        {
          name: translation.guidingQuestions,
          content: (
            <div
              style={{ fontSize: 16, lineHeight: "1.5" }}
              dangerouslySetInnerHTML={{
                __html: project.guiding_question,
              }}
            />
          ),
        },
      ]
      : [
        {
          name: translation.developmentalGoals,
          content: (
            <DevelopmentalGoals
              objectiveFundamentals={project.objective_fundamentals}
              projectCards={projectCards}
              project={project}
            />
          ),
        },
      ]
    : [
      {
        name: translation.developmentalGoals,
        content: (
          <DevelopmentalGoals
            objectiveFundamentals={project.objective_fundamentals}
            projectCards={projectCards}
            project={project}
          />
        ),
      },
      {
        name: project.assessment
          ? translation.assessmentPlans
          : translation.lessons,
        content: (
          <Lessons
            assessment={project.assessment}
            lessons={lessons}
            isInBag={isInBag}
            isInSchedule={isInSchedule}
          />
        ),
      },
    ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <ModuleTag
        projModule={project.module}
        styles={{
          mb: 0,
          alignSelf: "flex-start",
        }}
      />
      <Title title={project.name} />
      <Duration
        number={lessons.length}
        unit={lessons.length > 1 ? translation.lessons : translation.lesson}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "center",
          gap: 2.5,
          mt: 0.5,
        }}
      >
        {project.images.length > 0 && (
          <CustomImage src={project.images[0].file} name={project.name} />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Description description={project.description} />
        </Box>
      </Box>
      <CustomTabs
        tabIndex={tabIndex}
        handleTabChange={handleTabChange}
        tabs={tabs}
        isLonger={lessons.length > 4 || project.fundamentals.length > 4}
      />
    </Box>
  );
};

const TabContainer = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      gap: 2,
      justifyContent: "center",
      alignItems: "stretch",
    }}
  >
    {children}
  </Box>
);

const DevelopmentalGoals = ({
  objectiveFundamentals,
  projectCards,
  project,
}: {
  objectiveFundamentals: API_Project_Fundamental[];
  projectCards: any[];
  project: any;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return objectiveFundamentals.length > 0 ? (
    <TabContainer>
      {projectCards.map((projectCard: any, index: number) => {
        const developmentalGoalName = projectCard.name;
        const imgSrc = projectCard.image;
        const subFundamentals = projectCard?.subFundamentals;
        const objectives = projectCard?.objectives;
        return isXs ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Accordion
              sx={{
                backgroundColor: "#C4E4DF",
                "&.Edited-Accordion": {
                  border: "2px solid var(--primary-light)",
                  boxShadow: "none",
                },

                width: "100%",
              }}
              onChange={() => { }}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  paddingLeft: 1,
                  paddingRight: 1.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: {
                        xs: 2,
                      },
                    }}
                  >
                    <Image
                      style={{
                        height: 50,
                        width: 50,
                      }}
                      src={imgSrc}
                      alt={developmentalGoalName}
                    />

                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: {
                          xs: 16,
                          sm: 20,
                        },
                      }}
                    >
                      {developmentalGoalName}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "white",
                  position: "relative",
                }}
              >
                <Box>
                  {subFundamentals && subFundamentals.map(
                    (subFundamental: any, index: number) => {
                      // totalLines++;
                      return (
                        <Box
                          key={index}
                          sx={{
                            color: "#5C9391",
                            pl: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 450,
                            }}
                          >
                            {subFundamental.name}
                            {/* {totalLines == 5
                                  ? subFundamental.name + "..."
                                  : totalLines > 5
                                  ? null
                                  : subFundamental.name} */}
                          </Typography>

                          {subFundamental.objectives && Object.keys(subFundamental.objectives).map((objective, objectiveIndex) => {
                            // totalLines++;
                            return (
                              <Box sx={{ display: "flex" }} key={index}>
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      textAlign: "center",
                                      color: "black",
                                    }}
                                  >
                                    •
                                  </Typography>
                                  <Typography
                                    key={objectiveIndex}
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    {objective}
                                  </Typography>
                                </>
                              </Box>
                            );
                          }
                          )}
                        </Box>
                      );
                    }
                  )}
                  {!subFundamentals || (subFundamentals?.length == 0 && null)}

                  {objectives &&
                    (
                      <Box>
                        {Object.keys(objectives).map((objective, index) => {
                          return (
                            <Box sx={{ display: "flex" }} key={index}>
                              <Typography
                                sx={{ fontSize: "14px", textAlign: "center" }}
                              >
                                •
                              </Typography>
                              <Typography
                                key={index}
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  // marginLeft: 1,
                                }}
                              >
                                {objective}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  {!objectives ||
                    objectives?.length == 0 ||
                    (objectives[0] == "" && null)}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              border: "1px solid #91C6C5",
              borderRadius: "8px",
              // p: 2,
              pl: 2,
              pt: 2,
              height: "100%",
            }}
            key={index}
          >
            {/* img and developmentalGoalname */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  width: 66,
                  height: 66,
                  backgroundColor: "#C4E4DF",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <Image src={imgSrc} alt={developmentalGoalName} />
                </Box>
              </Box>

              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  ml: 2,
                  color: "#5C9391",
                  padding: "4px 12px",
                  borderRadius: "100px",
                  border: "1px solid #91C6C5",
                  backgroundColor: "#EBF8F8",
                  textAlign: "center",
                }}
              >
                {developmentalGoalName}
              </Typography>
            </Box>
            {/* end of image and dev name */}

            {/* if objectives or subFundamentals */}

            {/* subfundamentals */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  justifyContent: "flex-start",
                  pb: 2,
                }}
              >
                {subFundamentals &&
                  subFundamentals.map((subFundamental: any, index: number) => {
                    // totalLines++;
                    return (
                      <Box
                        key={index}
                        sx={{
                          color: "#5C9391",
                          pl: isXs ? 2 : 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 450,
                          }}
                        >
                          {subFundamental.name}
                        </Typography>
                        {subFundamental.objectives && Object.keys(subFundamental.objectives).map((key, index) => {

                          return (
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  fontSize: isXs ? "14px " : "16px",
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                •
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: isXs ? "14px " : "16px",
                                  fontWeight: 400,
                                  color: "black",
                                }}
                              >
                                {key}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  })}

                {!subFundamentals || (subFundamentals?.length == 0 && null)}

                {objectives &&
                  (
                    <Box>
                      {Object.keys(objectives).map((key, index) => {

                        return (
                          <Box sx={{ display: "flex" }} key={index}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                textAlign: "center",
                                color: "black",
                              }}
                            >
                              •
                            </Typography>
                            <Typography
                              key={index}
                              sx={{
                                fontSize: "16px",
                                fontWeight: 400,
                                color: "black",
                              }}
                            >
                              {key}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                {!objectives ||
                  objectives?.length == 0 ||
                  (objectives[0] == "" && null)}
              </Box>
            </Box>
          </Box>
        );
      })}
    </TabContainer>
  ) : (
    <NoContent title={translation.noFundamental} />
  );
};

const Lessons = ({
  assessment = false,
  lessons,
  isInBag,
  isInSchedule,
}: {
  assessment?: boolean;
  lessons: API_Project_Lesson[];
  isInBag: boolean;
  isInSchedule: boolean;
}) =>
  lessons.length > 0 ? (
    <TabContainer>
      {lessons.map((lesson, index) => (
        <LessonDetailCard
          key={index}
          lesson={lesson}
          index={index}
          isInBag={isInBag}
          isInSchedule={isInSchedule}
        />
      ))}
    </TabContainer>
  ) : (
    <NoContent title={assessment ? "No Assessment" : translation.noLesson} />
  );

export default ProjectView;
