import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";

import useStore from "store/store";
import useInitialiseCurrModule from "hooks/useInitialiseCurrModule";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { RESOURCE_TYPE_MCQ } from "./teacherPageConstants";
import ModuleSummarySideMenu from "./TeacherPageSideMenuComponents/ModuleSummarySideMenu";
import FloatingNoteDiscussionComponent from "./TeacherPageSideMenuScreens/FloatingNoteDiscussionComponent";
import { API_Teacher_Text_Activity } from "../../../types/teacher";

const TeacherModuleSummaryPage = () => {
  useInitialiseCurrModule();

  const { teacherCurrentActivity, isTeacherModuleLoading } = useStore((state) => state);
  const hasFlipBook = (activity: any): activity is API_Teacher_Text_Activity => {
    return "flip_book" in activity;
  };

  if (isTeacherModuleLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          mx: "20px",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flex: 1, mr: "20px", gap: 3 }}>
      <Box
        sx={{
          maxWidth: "300px",
          height: "calc(100vh - 64px)",
          borderRight: "1px solid #C9C9C9",
          position: "sticky",
          top: "64px",
          py: 3,
          flexShrink: 0,
          width: "300px",
        }}
      >
        <ModuleSummarySideMenu />
      </Box>
      <Container
        sx={{
          display: "flex",
          borderRadius: "16px",
          flex: 1,
          my: 3,
          p: "0 !important",
        }}
        maxWidth={false}
      >
        <Outlet />
      </Container>
      <Box sx={{ my: 3 }}>
        {teacherCurrentActivity.resourcetype !== RESOURCE_TYPE_MCQ &&
          hasFlipBook(teacherCurrentActivity) &&
          (!teacherCurrentActivity.flip_book || teacherCurrentActivity.flip_book.length === 0) && (
            <FloatingNoteDiscussionComponent />
          )}
      </Box>
    </Box>
  );
};

export default TeacherModuleSummaryPage;
